import { FC, Fragment, useState } from 'react'
import { IdentityUpdateData, User, UserFilter, UserUpdateData } from '../core/api/types'
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Collapse,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import { AccountCircle, Key, KeyboardArrowDown, KeyboardArrowUp, Save, Settings } from '@mui/icons-material'
import { useFormik } from 'formik'
import { userDetailsValidationSchema, updateIdentityValidationSchema } from '../core/validations'
import { UpdateUserAction } from '../core/actions/usersActionTypes'
import { UpdateIdentityAction } from '../core/actions/identityActionTypes'
import { availableGroups } from '../core/validations/consts-and-functions'
import { RoleAccessProvider } from '../providers'
import { Roles } from '../constants/roles'
import dayjs from 'dayjs'

interface Props {
  user: User
  filter?: UserFilter
  updateUser: (userDetails: UserUpdateData, filter?: UserFilter) => UpdateUserAction
  updateIdentity: (accessControlDetails: IdentityUpdateData, filter?: UserFilter) => UpdateIdentityAction
  refreshPage: () => void
}

function generatePassword(length: number) {
  let result = ''
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

const UsersTableRowComponent: FC<Props> = ({ user, filter, updateUser, updateIdentity }) => {
  const [open, setOpen] = useState(false)
  const updateUserDetailsFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: user.id,
      chineseName: user.chineseName,
      englishName: user.englishName,
      position: user.position,
      phoneNumber: user.phoneNumber,
    },
    validationSchema: userDetailsValidationSchema,
    onSubmit: (userDetails: UserUpdateData) => {
      console.log(userDetails)
      updateUser(userDetails, filter)
    },
  })
  const updateAccessControlFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: user.identity.id,
      username: user.identity.username,
      group: user.identity.group,
      isPasswordOneTime: user.identity.isPasswordOneTime,
      isActive: user.identity.isActive,
    },
    validationSchema: updateIdentityValidationSchema,
    onSubmit: (accessControlData: IdentityUpdateData) => {
      updateIdentity(accessControlData, filter)
    },
  })


  return (
    <Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} hover key={user.id}>
        <TableCell style={{ width: '5%' }}>
          <RoleAccessProvider required={[Roles.MANAGE_BRANDS]}>
            <IconButton
              disabled={user.identity.username === 'epamdeveloper'}
              aria-label='expand row'
              size='small'
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </RoleAccessProvider>
        </TableCell>

        <TableCell style={{ width: '30%', textAlign: 'center' }}>{user.identity.username}</TableCell>
        <TableCell style={{ width: '15%', textAlign: 'center' }}>{user.chineseName}</TableCell>
        <TableCell style={{ width: '20%', textAlign: 'center' }}>
          {dayjs(user.identity.dateRegistered).tz('Asia/Hong_Kong').format('DD-MM-YYYY')}
        </TableCell>
        <TableCell style={{ width: '15%', textAlign: 'center' }}>{user.identity.group}</TableCell>

        <TableCell style={{ width: '15%', textAlign: 'center' }}>
          <RoleAccessProvider required={[Roles.MANAGE_USERS]}>
            {user.identity.isActive ? (
              <Button
                disabled={user.identity.username === 'epamdeveloper'}
                onClick={() => {
                  updateIdentity({
                    id: user.identity.id,
                    isActive: false,
                  })
                }}
              >
                Deactivate
              </Button>
            ) : (
              <Button
                disabled={user.identity.username === 'epamdeveloper'}
                onClick={() => {
                  updateIdentity({
                    id: user.identity.id,
                    isActive: true,
                  })
                }}
                color={'success'}
              >
                Activate
              </Button>
            )}
          </RoleAccessProvider>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout={'auto'} unmountOnExit>
            <Grid container spacing={2} sx={{ marginTop: 1, marginBottom: 2 }}>
              <Grid item xs={6}>
                <Card variant={'outlined'} sx={{ flex: 1 }}>
                  <form onSubmit={updateUserDetailsFormik.handleSubmit}>
                    <CardContent>
                      <Box mb={1} sx={{ display: 'flex', alignItems: 'center' }}>
                        <AccountCircle />
                        <Typography ml={2} variant={'h5'}>
                          <b>User Details</b>
                        </Typography>
                      </Box>
                      <Typography variant={'subtitle2'}>Manage personal user details and view list of recently done actions</Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <TextField
                            id='chineseName'
                            label='Chinese Name'
                            margin='normal'
                            type='text'
                            inputProps={{ maxLength: 30 }}
                            fullWidth
                            variant='filled'
                            value={updateUserDetailsFormik.values.chineseName}
                            error={updateUserDetailsFormik.touched.chineseName && Boolean(updateUserDetailsFormik.errors.chineseName)}
                            onChange={updateUserDetailsFormik.handleChange}
                            helperText={updateUserDetailsFormik.touched.chineseName && updateUserDetailsFormik.errors.chineseName}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            id='englishName'
                            label='English Name'
                            margin='normal'
                            type='text'
                            inputProps={{ maxLength: 30 }}
                            fullWidth
                            variant='filled'
                            value={updateUserDetailsFormik.values.englishName}
                            error={updateUserDetailsFormik.touched.englishName && Boolean(updateUserDetailsFormik.errors.englishName)}
                            onChange={updateUserDetailsFormik.handleChange}
                            helperText={updateUserDetailsFormik.touched.englishName && updateUserDetailsFormik.errors.englishName}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <TextField
                            id='position'
                            label='Position'
                            margin='normal'
                            type='text'
                            inputProps={{ maxLength: 30 }}
                            fullWidth
                            variant='filled'
                            value={updateUserDetailsFormik.values.position}
                            error={updateUserDetailsFormik.touched.position && Boolean(updateUserDetailsFormik.errors.position)}
                            onChange={updateUserDetailsFormik.handleChange}
                            helperText={updateUserDetailsFormik.touched.position && updateUserDetailsFormik.errors.position}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            id='phoneNumber'
                            label='Phone Number'
                            margin='normal'
                            type='text'
                            inputProps={{ maxLength: 8 }}
                            fullWidth
                            variant='filled'
                            value={updateUserDetailsFormik.values.phoneNumber}
                            error={updateUserDetailsFormik.touched.phoneNumber && Boolean(updateUserDetailsFormik.errors.phoneNumber)}
                            onChange={updateUserDetailsFormik.handleChange}
                            helperText={updateUserDetailsFormik.touched.phoneNumber && updateUserDetailsFormik.errors.phoneNumber}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                    <CardActions sx={{ backgroundColor: '#f1f1f1' }}>
                      <Button type={'submit'} sx={{ margin: 1 }} startIcon={<Save />} variant={'contained'} color='primary'>
                        Save
                      </Button>
                    </CardActions>
                  </form>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <form onSubmit={updateAccessControlFormik.handleSubmit}>
                  <Card variant={'outlined'} sx={{ flex: 1 }}>
                    <CardContent>
                      <Box mb={1} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Key />
                        <Typography ml={2} variant={'h5'}>
                          <b>Access Control</b>
                        </Typography>
                      </Box>
                      <Typography variant={'subtitle2'}>Update credentials and roles. Leaving password empty won't change it.</Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <TextField
                            disabled
                            id='username'
                            label='Username'
                            margin='normal'
                            type='text'
                            fullWidth
                            variant='filled'
                            value={updateAccessControlFormik.values.username}
                            error={updateAccessControlFormik.touched.username && Boolean(updateAccessControlFormik.errors.username)}
                            onChange={updateAccessControlFormik.handleChange}
                            helperText={updateAccessControlFormik.touched.username && updateAccessControlFormik.errors.username}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <FormControl sx={{ marginTop: 2 }} variant={'filled'} fullWidth>
                            <InputLabel id={'group-label'}>Group</InputLabel>
                            <Select
                              labelId='group-label'
                              id='group'
                              name='group'
                              value={updateAccessControlFormik.values.group}
                              label='Group'
                              onChange={updateAccessControlFormik.handleChange}
                            >
                              {availableGroups.map((group, index) => (
                                <MenuItem key={index} value={group}>
                                  {group}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <TextField
                        id='password'
                        label='New Password'
                        margin='normal'
                        type='text'
                        fullWidth
                        variant='filled'
                        value={updateAccessControlFormik.values.password}
                        error={updateAccessControlFormik.touched.password && Boolean(updateAccessControlFormik.errors.password)}
                        onChange={updateAccessControlFormik.handleChange}
                        helperText={updateAccessControlFormik.touched.password && updateAccessControlFormik.errors.password}
                      />
                    </CardContent>
                    <CardActions sx={{ backgroundColor: '#f1f1f1' }}>
                      <Button type={'submit'} sx={{ margin: 1 }} startIcon={<Save />} variant={'contained'} color='primary'>
                        Save
                      </Button>
                      <Button
                        onClick={() => {
                          updateAccessControlFormik.setFieldValue('password', generatePassword(12))
                        }}
                        sx={{ margin: 1 }}
                        startIcon={<Settings />}
                        variant={'contained'}
                        color='primary'
                      >
                        Generate password
                      </Button>
                    </CardActions>
                  </Card>
                </form>
              </Grid>
            </Grid>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  )
}

export default UsersTableRowComponent
