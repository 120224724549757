import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AppBarContainer } from "../components";
import { TokenPair } from "../core/api/types";

interface Props {
  tokens: TokenPair | null;
  logOut: () => void;
}

export const DashboardPageComponent: FC<Props> = ({ tokens, logOut }) => {
  const navigate = useNavigate();

  /**
   * If the tokens are not present, redirect back to the login page.
   */
  useEffect(() => {
    if (tokens == null) {
      navigate("/login");
    }
  }, [tokens, navigate]);

  return (
    <div>
      <AppBarContainer />
    </div>
  );
};
