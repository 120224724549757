import { Roles } from './../../constants/roles';
import { GetAllPharmaciesAction, GetRegionsAction } from './../actions/pharmacyActionTypes'
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { ActionTypes } from '../actions/types'
import { pharmacyActionCreators, programActionCreators } from '../actions/creators'
import * as API from '../api/pharmacies'
import { AxiosError } from 'axios'
import { GetPharmacyAction, GetPharmacyDataAction, SavePharmacyAction, UpdatePharmacyAction } from '../actions/pharmacyActionTypes'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import POSITION = toast.POSITION
import { getAllPharmacies, getRegions } from '../actions/creators/pharmacyActionCreators'
import { store } from '../store'

function* onGetAllPharmacies(getAllPharmaciesAction: GetAllPharmaciesAction) {
  yield put(pharmacyActionCreators.getAllPharmaciesRequest())

  try {
    const { data } = yield call(API.getAllPharmacies);
    yield put(pharmacyActionCreators.getAllPharmaciesSuccess(data))
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      yield put(pharmacyActionCreators.getAllPharmaciesFailure(error.response.data.errors[0]))
    }
  }
}

function* onListPharmacies(getPharmacyAction: GetPharmacyAction) {
  yield put(pharmacyActionCreators.getPharmaciesRequest())

  try {
    const { data } = yield call(API.getPharmacies, getPharmacyAction.payload, getPharmacyAction.filter);
    yield put(pharmacyActionCreators.getPharmaciesSuccess(data))
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      yield put(pharmacyActionCreators.getPharmaciesFailure(error.response.data.errors[0]))
    }
  }
}
function* onGetPharmacyData(getPharmacyData: GetPharmacyDataAction) {
  yield put(pharmacyActionCreators.getPharmacyDataRequest())

  try {
    const { data } = yield call(API.getPharmacyData, getPharmacyData.payload)
    yield put(pharmacyActionCreators.getPharmacyDataSuccess(data))
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      yield put(pharmacyActionCreators.getPharmacyDataFailure(error.response.data.errors[0]))
    }
  }
}

function* onSavePharmacy(createPharmacyAction: SavePharmacyAction) {
  console.log(createPharmacyAction)
  yield put(pharmacyActionCreators.savePharmacyRequest())

  try {
    yield call(API.createPharmacy, createPharmacyAction.payload)
    yield put(pharmacyActionCreators.savePharmacySuccess())
    yield put(pharmacyActionCreators.getPharmacies({ 
      page: store.getState().pharmacies.page, 
      size: store.getState().pharmacies.size 
    }, createPharmacyAction.filter))
    
    yield put(getAllPharmacies());
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      console.log(error.response)
      yield put(pharmacyActionCreators.savePharmacyFailure(error.response.data.errors[0]))
    }
  }
}

function* onUpdatePharmacy(updatePharmacyAction: UpdatePharmacyAction) {
  console.log(updatePharmacyAction)
  yield put(pharmacyActionCreators.updatePharmacyRequest())

  try {
    yield call(API.updatePharmacy, updatePharmacyAction.payload)
    yield put(pharmacyActionCreators.updatePharmacySuccess())
    yield put(pharmacyActionCreators.getPharmacies({ 
      page: store.getState().pharmacies.page, 
      size: store.getState().pharmacies.size 
    }))
    yield put(pharmacyActionCreators.getPharmacyData(updatePharmacyAction.payload.id))
    window.location.reload();
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      console.log()
      toast.error(error.response.data.message, {
        position: POSITION.BOTTOM_CENTER,
      })
      yield put(pharmacyActionCreators.updatePharmacyFailure(error.response.data.errors[0]))
    }
  }
}

function* onGetRegions(event: GetRegionsAction) {
  yield put(pharmacyActionCreators.getRegionsRequest())

  try {
    const { data } = yield call(API.getRegions)
    yield put(pharmacyActionCreators.getRegionsSuccess(data))
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      yield put(pharmacyActionCreators.getRegionsFailure(error.response.data.errors[0]))
    }
  }
}
function* watchPharmacyActions() {
  yield takeEvery(ActionTypes.GET_PHARMACY, onListPharmacies)
  yield takeEvery(ActionTypes.GET_PHARMACY_DATA, onGetPharmacyData)
  yield takeEvery(ActionTypes.SAVE_PHARMACY, onSavePharmacy)
  yield takeEvery(ActionTypes.UPDATE_PHARMACY, onUpdatePharmacy)
  yield takeEvery(ActionTypes.GET_REGIONS, onGetRegions)
  yield takeEvery(ActionTypes.GET_ALL_PHARMACIES, onGetAllPharmacies);
}

export default function* saga() {
  yield all([fork(watchPharmacyActions)])
  yield put(programActionCreators.getProgramNames());
  
  
}
