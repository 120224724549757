import { Dispatch } from '@reduxjs/toolkit'
import { Action } from '../core/actions'
import { State } from '../core/reducers'
import { connect } from 'react-redux'
import { BrandPageComponent } from './BrandPageComponent'
import { getBrands } from '../core/actions/creators/brandActionCreators'
import { PageRequest } from "../core/api/types";

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    getBrands: (pageRequest : PageRequest) => dispatch(getBrands(pageRequest)),
})

const mapStateToProps = (state: State) => ({
    brands: state.brands,
})

export default connect(mapStateToProps, mapDispatchToProps)(BrandPageComponent)
