import { PageRequest, Paged, Patient, RegisteredUserFilter } from '../../api/types'
import {
  DeactivatePatientAction,
  DeactivatePatientFailureAction,
  DeactivatePatientRequest,
  DeactivatePatientSuccessAction,
} from '../patientActionTypes'
import {
  ActivatePatientAction,
  ActivatePatientRequest,
  ActivatePatientSuccessAction,
  ActivatePatientFailureAction,
  GetPatientsRequestAction,
  GetPatientsSuccessAction,
  GetPatientsFailureAction,
  GetPatientsDataAction,
  VerifyPatientAction,
  VerifyPatientRequest,
  VerifyPatientSuccessAction,
  VerifyPatientFailureAction,
} from '../patientActionTypes'
import { ActionTypes } from '../types'

export function getPatients(pageRequest: PageRequest, filter?: RegisteredUserFilter): GetPatientsDataAction {
  return {
    type: ActionTypes.GET_PATIENT_DATA,
    payload: pageRequest,
    filter,
  }
}

export function getPatientsRequest(): GetPatientsRequestAction {
  return {
    type: ActionTypes.GET_PATIENT_DATA_REQUEST,
  }
}

export function getPatientsSuccess(brands: Paged<Patient>): GetPatientsSuccessAction {
  return {
    type: ActionTypes.GET_PATIENT_DATA_SUCCESS,
    payload: brands,
  }
}

export function getPatientsFailure(error: Error | string): GetPatientsFailureAction {
  return {
    type: ActionTypes.GET_PATIENT_DATA_FAILURE,
    error,
  }
}

export function activatePatient(id: string): ActivatePatientAction {
  return {
    type: ActionTypes.ACTIVATE_PATIENT,
    payload: id,
  }
}

export function activatePatientRequest(): ActivatePatientRequest {
  return {
    type: ActionTypes.ACTIVATE_PATIENT_REQUEST,
  }
}

export function activatePatientSuccess(): ActivatePatientSuccessAction {
  return {
    type: ActionTypes.ACTIVATE_PATIENT_SUCCESS,
  }
}

export function activatePatientFailure(error: Error | string): ActivatePatientFailureAction {
  return {
    type: ActionTypes.ACTIVATE_PATIENT_FAILURE,
    error,
  }
}

export function deactivatePatient(id: string): DeactivatePatientAction {
  return {
    type: ActionTypes.DEACTIVATE_PATIENT,
    payload: id,
  }
}

export function deactivatePatientRequest(): DeactivatePatientRequest {
  return {
    type: ActionTypes.DEACTIVATE_PATIENT_REQUEST,
  }
}

export function deactivatePatientSuccess(): DeactivatePatientSuccessAction {
  return {
    type: ActionTypes.DEACTIVATE_PATIENT_SUCCESS,
  }
}

export function deactivatePatientFailure(error: Error | string): DeactivatePatientFailureAction {
  return {
    type: ActionTypes.DEACTIVATE_PATIENT_FAILURE,
    error,
  }
}

export function verifyPatient(id: string): VerifyPatientAction {
  return {
    type: ActionTypes.VERIFY_PATIENT,
    payload: id,
  }
}

export function verifyPatientRequest(): VerifyPatientRequest {
  return {
    type: ActionTypes.VERIFY_PATIENT_REQUEST,
  }
}

export function verifyPatientSuccess(): VerifyPatientSuccessAction {
  return {
    type: ActionTypes.VERIFY_PATIENT_SUCCESS,
  }
}

export function verifyPatientFailure(error: Error | string): VerifyPatientFailureAction {
  return {
    type: ActionTypes.VERIFY_PATIENT_FAILURE,
    error,
  }
}
