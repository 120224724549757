import { Dispatch } from '@reduxjs/toolkit'
import { Action } from '../core/actions'
import { State } from '../core/reducers'
import { connect } from 'react-redux'
import EnrollmentsTableRowComponent from './EnrollmentsTableRowComponent'
import { EnrollmentStatus, OrderUpdate, PatientFilter, PatientUpdate } from '../core/api/types'
import {
  updateEnrollmentData,
  updateEnrollmentStatus,
  updatePatientData,
} from '../core/actions/creators/enrollmentActionCreators'

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  updateEnrollmentStatus: (enrollment: EnrollmentStatus, filter?: PatientFilter) => dispatch(updateEnrollmentStatus(enrollment, filter)),
  updatePatientData: (patientUpdate: PatientUpdate, filter?: PatientFilter) => dispatch(updatePatientData(patientUpdate, filter)),
  updateEnrollmentData: (orderUpdate: OrderUpdate) => dispatch(updateEnrollmentData(orderUpdate)),
})

const mapStateToProps = (state: State) => ({
  pharmaciesUnpaged: state.pharmaciesUnpaged,
  roles: state.roles
})

export default connect(mapStateToProps, mapDispatchToProps)(EnrollmentsTableRowComponent)
