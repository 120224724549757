export enum ActionTypes {
  LOG_IN = 'auth/log_in_init',
  LOG_IN_REQUEST = 'auth/log_in_request',
  LOG_IN_SUCCESS = 'auth/log_in_success',
  LOG_IN_FAILURE = 'auth/log_in_failure',
  LOG_IN_RESTORE = 'auth/log_in_restore',
  LOG_OUT = 'auth/log_out',
  GET_USERS = 'users/get_users',
  GET_USERS_REQUEST = 'users/get_users_request',
  GET_USERS_SUCCESS = 'users/get_users_success',
  GET_USERS_FAILURE = 'users/get_users_failure',
  CREATE_USER = 'users/create_user',
  CREATE_USER_REQUEST = 'users/create_user_request',
  CREATE_USER_SUCCESS = 'users/create_user_success',
  CREATE_USER_FAILURE = 'users/create_user_failure',
  UPDATE_USER = 'users/update_user',
  UPDATE_USER_REQUEST = 'users/update_user_request',
  UPDATE_USER_SUCCESS = 'users/update_user_success',
  UPDATE_USER_FAILURE = 'users/update_user_failure',
  UPDATE_IDENTITY = 'users/update_identity',
  UPDATE_IDENTITY_REQUEST = 'users/update_identity_request',
  UPDATE_IDENTITY_SUCCESS = 'users/update_identity_success',
  UPDATE_IDENTITY_FAILURE = 'users/update_identity_failure',
  CHANGE_USER_PASSWORD = 'users/change_user_password',
  CHANGE_USER_PASSWORD_REQUEST = 'users/change_user_password_request',
  CHANGE_USER_PASSWORD_SUCCESS = 'users/change_user_password_success',
  CHANGE_USER_PASSWORD_FAILURE = 'users/change_user_password_failure',
  GET_PHARMACY = 'pharmacies/get_pharmacies',
  GET_PHARMACY_REQUEST = 'pharmacies/get_pharmacies_request',
  GET_PHARMACY_SUCCESS = 'pharmacies/get_pharmacies_success',
  GET_PHARMACY_FAILURE = 'pharmacies/get_pharmacies_failure',
  GET_PHARMACY_DATA = 'pharmacies/get_pharmacy_data',
  GET_PHARMACY_DATA_REQUEST = 'pharmacies/get_pharmacy_data_request',
  GET_PHARMACY_DATA_SUCCESS = 'pharmacies/get_pharmacy_data_success',
  GET_PHARMACY_DATA_FAILURE = 'pharmacies/get_pharmacy_data_failure',
  SAVE_PHARMACY = 'pharmacies/save_pharmacy',
  SAVE_PHARMACY_REQUEST = 'pharmacies/save_pharmacy_request',
  SAVE_PHARMACY_SUCCESS = 'pharmacies/save_pharmacy_success',
  SAVE_PHARMACY_FAILURE = 'pharmacies/save_pharmacy_failure',
  UPDATE_PHARMACY = 'pharmacies/update_pharmacy',
  UPDATE_PHARMACY_REQUEST = 'pharmacies/update_pharmacy_request',
  UPDATE_PHARMACY_SUCCESS = 'pharmacies/update_pharmacy_success',
  UPDATE_PHARMACY_FAILURE = 'pharmacies/update_pharmacy_failure',
  GET_BRAND = 'brands/get_brands',
  GET_BRAND_REQUEST = 'brands/get_brands_request',
  GET_BRAND_SUCCESS = 'brands/get_brands_success',
  GET_BRAND_FAILURE = 'brands/get_brands_failure',
  GET_BRAND_DATA = 'brands/get_brand_data',
  GET_BRAND_DATA_REQUEST = 'brands/get_brand_data_request',
  GET_BRAND_DATA_SUCCESS = 'brands/get_brand_data_success',
  GET_BRAND_DATA_FAILURE = 'brands/get_brand_data_failure',
  DEACTIVATE_BRAND = 'brands/deactivate_brand',
  DEACTIVATE_BRAND_REQUEST = 'brands/deactivate_brand_request',
  DEACTIVATE_BRAND_SUCCESS = 'brands/deactivate_brand_success',
  DEACTIVATE_BRAND_FAILURE = 'brands/deactivate_brand_failure',
  SAVE_BRAND = 'brands/save_brand',
  SAVE_BRAND_REQUEST = 'brands/save_brand_request',
  SAVE_BRAND_SUCCESS = 'brands/save_brand_success',
  SAVE_BRAND_FAILURE = 'brands/save_brand_failure',
  UPDATE_BRAND = 'brands/update_brand',
  UPDATE_BRAND_REQUEST = 'brands/update_brand_request',
  UPDATE_BRAND_SUCCESS = 'brands/update_brand_success',
  UPDATE_BRAND_FAILURE = 'brands/update_brand_failure',
  GET_PROGRAM = 'programs/get_programs',
  GET_PROGRAM_REQUEST = 'programs/get_programs_request',
  GET_PROGRAM_SUCCESS = 'programs/get_programs_success',
  GET_PROGRAM_FAILURE = 'programs/get_programs_failure',
  GET_PROGRAM_DATA = 'programs/get_program_data',
  GET_PROGRAM_DATA_REQUEST = 'programs/get_program_data_request',
  GET_PROGRAM_DATA_SUCCESS = 'programs/get_program_data_success',
  GET_PROGRAM_DATA_FAILURE = 'programs/get_program_data_failure',
  SAVE_PROGRAM = 'programs/save_program',
  SAVE_PROGRAM_REQUEST = 'programs/save_program_request',
  SAVE_PROGRAM_SUCCESS = 'programs/save_program_success',
  SAVE_PROGRAM_FAILURE = 'programs/save_program_failure',
  UPDATE_PROGRAM = 'programs/update_program',
  UPDATE_PROGRAM_REQUEST = 'programs/update_program_request',
  UPDATE_PROGRAM_SUCCESS = 'programs/update_program_success',
  UPDATE_PROGRAM_FAILURE = 'programs/update_program_failure',
  GET_STORAGE_FILES = 'storage/get_files',
  GET_STORAGE_FILES_REQUEST = 'storage/get_files_request',
  GET_STORAGE_FILES_SUCCESS = 'storage/get_files_success',
  GET_STORAGE_FILES_FAILURE = 'storage/get_files_failure',
  UPLOAD_IMAGE = 'storage/upload_image',
  UPLOAD_IMAGE_REQUEST = 'storage/upload_image_request',
  UPLOAD_IMAGE_SUCCESS = 'storage/upload_image_success',
  UPLOAD_IMAGE_FAILURE = 'storage/upload_image_failure',
  GET_ENROLLMENT = 'enrollments/get_enrollments',
  GET_ENROLLMENT_REQUEST = 'enrollments/get_enrollments_request_refreshless',
  GET_ENROLLMENT_SUCCESS = 'enrollments/get_enrollments_success',
  GET_ENROLLMENT_FAILURE = 'enrollments/get_Enrollments_failure',
  UPDATE_ENROLLMENT_STATUS = 'enrollments/update_enrollment',
  UPDATE_ENROLLMENT_STATUS_REQUEST = 'enrollments/update_enrollment_request',
  UPDATE_ENROLLMENT_STATUS_SUCCESS = 'enrollments/update_enrollment_success',
  UPDATE_ENROLLMENT_STATUS_FAILURE = 'enrollments/update_enrollment_failure',
  APPROVE_SUBMISSION = 'submissions/approve_submission',
  APPROVE_SUBMISSION_REQUEST = 'submissions/approve_submission_request',
  APPROVE_SUBMISSION_SUCCESS = 'submissions/approve_submission_success',
  APPROVE_SUBMISSION_FAILURE = 'submissions/approve_submission_failure',
  DECLINE_SUBMISSION = 'submissions/decline_submission',
  DECLINE_SUBMISSION_REQUEST = 'submissions/decline_submission_request',
  DECLINE_SUBMISSION_SUCCESS = 'submissions/decline_submission_success',
  DECLINE_SUBMISSION_FAILURE = 'submissions/decline_submission_failure',
  GET_DISCOUNT = 'discounts/get_discounts',
  GET_DISCOUNT_REQUEST = 'discounts/get_discounts_request',
  GET_DISCOUNT_SUCCESS = 'discounts/get_discounts_success',
  GET_DISCOUNT_FAILURE = 'discounts/get_discounts_failure',
  GET_DISCOUNT_BY_PHARMACY = 'discounts/get_discount_by_pharmacy',
  GET_DISCOUNT_BY_PHARMACY_REQUEST = 'discounts/get_discount_by_pharmacy_request',
  GET_DISCOUNT_BY_PHARMACY_SUCCESS = 'discounts/get_discount_by_pharmacy_success',
  GET_DISCOUNT_BY_PHARMACY_FAILURE = 'discounts/get_discount_by_pharmacy_failure',
  GET_HA_SFI_BY_PHARMACY = 'discounts/get_ha_sfi_by_pharmacy',
  GET_HA_SFI_BY_PHARMACY_REQUEST = 'discounts/get_ha_sfi_by_pharmacy_request',
  GET_HA_SFI_BY_PHARMACY_SUCCESS = 'discounts/get_ha_sfi_by_pharmacy_success',
  GET_HA_SFI_BY_PHARMACY_FAILURE = 'discounts/get_ha_sfi_by_pharmacy_failure',
  GET_PROGRAM_NAMES = 'programs/get_program_names',
  GET_PROGRAM_NAMES_REQUEST = 'programs/get_program_names_request',
  GET_PROGRAM_NAMES_SUCCESS = 'programs/get_program_names_success',
  GET_PROGRAM_NAMES_FAILURE = 'programs/get_program_names_failure',
  GET_REGIONS = 'pharmacies/get_regions',
  GET_REGIONS_REQUEST = 'pharmacies/get_regions_request',
  GET_REGIONS_SUCCESS = 'pharmacies/get_regions_success',
  GET_REGIONS_FAILURE = 'pharmacies/get_regions_failure',
  GET_STAMPS_PENDING = 'pharmacies/get_stamps_pending',
  GET_STAMPS_PENDING_REQUEST = 'pharmacies/get_stamps_pending_request',
  GET_STAMPS_PENDING_SUCCESS = 'pharmacies/get_stamps_pending_success',
  GET_STAMPS_PENDING_FAILURE = 'pharmacies/get_stamps_pending_failure',
  GET_STAMPS_PICKED_UP = 'pharmacies/get_stamps_picked_up',
  GET_STAMPS_PICKED_UP_REQUEST = 'pharmacies/get_stamps_picked_up_request',
  GET_STAMPS_PICKED_UP_SUCCESS = 'pharmacies/get_stamps_picked_up_success',
  GET_STAMPS_PICKED_UP_FAILURE = 'pharmacies/get_stamps_picked_up_failure',
  GET_IDENTITY_ROLES = 'identities/get_identity_roles',
  GET_IDENTITY_ROLES_REQUEST = 'identities/get_identity_roles_request',
  GET_IDENTITY_ROLES_SUCCESS = 'identities/get_identity_roles_success',
  GET_IDENTITY_ROLES_FAILURE = 'identities/get_identity_roles_failure',
  SET_IS_LOADING = 'dashboard/set_is_loading',
  OPEN_MODAL = 'modal/open_modal',
  GET_PATIENT_DATA = 'patients/get_patient_data',
  GET_PATIENT_DATA_REQUEST = 'patients/get_patient_request',
  GET_PATIENT_DATA_SUCCESS = 'patients/get_patient_success',
  GET_PATIENT_DATA_FAILURE = 'patients/get_patient_failure',
  UPDATE_PATIENT_DATA = 'enrollments/update_patient_data',
  UPDATE_PATIENT_DATA_REQUEST = 'enrollments/update_patient_request_refreshless',
  UPDATE_PATIENT_DATA_SUCCESS = 'enrollments/update_patient_success',
  UPDATE_PATIENT_DATA_FAILURE = 'enrollments/update_patient_failure',
  VERIFY_PATIENT = 'patients/verify_patient',
  VERIFY_PATIENT_REQUEST = 'patients/verify_patient_request',
  VERIFY_PATIENT_SUCCESS = 'patients/verify_patient_success',
  VERIFY_PATIENT_FAILURE = 'patients/verify_patient_failure',
  ACTIVATE_PATIENT = 'patients/activate_patient',
  ACTIVATE_PATIENT_REQUEST = 'patients/activate_patient_request',
  ACTIVATE_PATIENT_SUCCESS = 'patients/activate_patient_success',
  ACTIVATE_PATIENT_FAILURE = 'patients/activate_patient_failure',
  DEACTIVATE_PATIENT = 'patients/deactivate_patient',
  DEACTIVATE_PATIENT_REQUEST = 'patients/deactivate_patient_request',
  DEACTIVATE_PATIENT_SUCCESS = 'patients/deactivate_patient_success',
  DEACTIVATE_PATIENT_FAILURE = 'patients/deactivate_patient_failure',
  GET_ALL_PHARMACIES = 'pharmacies/get_all_pharmacies',
  GET_ALL_PHARMACIES_REQUEST = 'pharmacies/get_all_pharmacies_request',
  GET_ALL_PHARMACIES_SUCCESS = 'pharmacies/get_all_pharmacies_success',
  GET_ALL_PHARMACIES_FAILURE = 'pharmacies/get_all_pharmacies_failure',
  UPDATE_ENROLLMENT_DATA = 'enrollments/update_enrollment_data',
  UPDATE_ENROLLMENT_DATA_REQUEST = 'enrollments/update_enrollment_data_request_refreshless',
  UPDATE_ENROLLMENT_DATA_SUCCESS = 'enrollments/update_enrollment_data_success',
  UPDATE_ENROLLMENT_DATA_FAILURE = 'enrollments/update_enrollment_data_failure',
  RESET_REDEMPTION_BALANCE = 'enrollments/reset_redemption_balance',
  RESET_REDEMPTION_BALANCE_REQUEST = 'enrollments/reset_redemption_balance_request_refreshless',
  RESET_REDEMPTION_BALANCE_SUCCESS = 'enrollments/reset_redemption_balance_success',
  RESET_REDEMPTION_BALANCE_FAILURE = 'enrollments/reset_redemption_balance_failure',
  GET_BRAND_NAMES = 'brands/get_brand_names',
  GET_BRAND_NAMES_REQUEST = 'brands/get_brand_names_request',
  GET_BRAND_NAMES_SUCCESS = 'brands/get_brand_names_success',
  GET_BRAND_NAMES_FAILURE = 'brands/get_brand_names_failure',
  SET_VERIFFIED_USER_TRIGGER = 'registereduserstriggers/set_verified_user_trigger',
  SET_ACTIVATED_USER_TRIGGER = 'registereduserstriggers/set_activated_user_trigger',
  SET_BLOCKED_USER_TRIGGER = 'registereduserstriggers/set_blocked_user_trigger',
}
