import { ProgramFilter } from './../../api/types/index';
import {
  GetProgramNamesAction,
  GetProgramNamesFailureAction,
  GetProgramNamesRequestAction,
  GetProgramNamesSuccessAction,
} from './../programActionTypes'
import { Paged, PageRequest, Program } from '../../api/types'
import {
  GetProgramAction,
  GetProgramFailureAction,
  GetProgramRequestAction,
  GetProgramSuccessAction,
  GetProgramDataAction,
  GetProgramDataFailureAction,
  GetProgramDataRequestAction,
  GetProgramDataSuccessAction,
  SaveProgramAction,
  SaveProgramFailureAction,
  SaveProgramRequestAction,
  SaveProgramSuccessAction,
  UpdateProgramAction,
  UpdateProgramRequestAction,
  UpdateProgramSuccessAction,
  UpdateProgramFailureAction,
} from '../programActionTypes'
import { ActionTypes } from '../types'

export function getPrograms(pageRequest: PageRequest, filter?: ProgramFilter): GetProgramAction {
  console.log("FILTER ACTION", filter);
  
  return {
    type: ActionTypes.GET_PROGRAM,
    payload: pageRequest,
    filter: filter ?? <ProgramFilter>{}
  }
}

export function getProgramsRequest(): GetProgramRequestAction {
  return {
    type: ActionTypes.GET_PROGRAM_REQUEST,
  }
}

export function getProgramsSuccess(programs: Paged<Program>): GetProgramSuccessAction {
  return {
    type: ActionTypes.GET_PROGRAM_SUCCESS,
    payload: programs,
  }
}

export function getProgramsFailure(error: Error | string): GetProgramFailureAction {
  return {
    type: ActionTypes.GET_PROGRAM_FAILURE,
    error,
  }
}

export function getProgramData(id: string): GetProgramDataAction {
  return {
    type: ActionTypes.GET_PROGRAM_DATA,
    payload: id,
  }
}

export function getProgramDataRequest(): GetProgramDataRequestAction {
  return {
    type: ActionTypes.GET_PROGRAM_DATA_REQUEST,
  }
}

export function getProgramDataSuccess(program: Program): GetProgramDataSuccessAction {
  return {
    type: ActionTypes.GET_PROGRAM_DATA_SUCCESS,
    payload: program,
  }
}

export function getProgramDataFailure(error: Error | string): GetProgramDataFailureAction {
  return {
    type: ActionTypes.GET_PROGRAM_DATA_FAILURE,
    error,
  }
}

export function saveProgram(program: Program, filter?: ProgramFilter): SaveProgramAction {
  return {
    type: ActionTypes.SAVE_PROGRAM,
    payload: program,
    filter
  }
}

export function saveProgramRequest(): SaveProgramRequestAction {
  return {
    type: ActionTypes.SAVE_PROGRAM_REQUEST,
  }
}

export function saveProgramSuccess(): SaveProgramSuccessAction {
  return {
    type: ActionTypes.SAVE_PROGRAM_SUCCESS,
  }
}

export function saveProgramFailure(error: Error | string): SaveProgramFailureAction {
  return {
    type: ActionTypes.SAVE_PROGRAM_FAILURE,
    error,
  }
}

export function updateProgram(program: Program, filter?: ProgramFilter): UpdateProgramAction {
  return {
    type: ActionTypes.UPDATE_PROGRAM,
    payload: program,
    filter
  }
}

export function updateProgramRequest(): UpdateProgramRequestAction {
  return {
    type: ActionTypes.UPDATE_PROGRAM_REQUEST,
  }
}

export function updateProgramSuccess(): UpdateProgramSuccessAction {
  return {
    type: ActionTypes.UPDATE_PROGRAM_SUCCESS,
  }
}

export function updateProgramFailure(error: Error | string): UpdateProgramFailureAction {
  return {
    type: ActionTypes.UPDATE_PROGRAM_FAILURE,
    error,
  }
}

export function getProgramNames(): GetProgramNamesAction {
  return {
    type: ActionTypes.GET_PROGRAM_NAMES,
  }
}

export function getProgramNamesRequest(): GetProgramNamesRequestAction {
  return {
    type: ActionTypes.GET_PROGRAM_NAMES_REQUEST,
  }
}

export function getProgramNamesSuccess(programNames: string[]): GetProgramNamesSuccessAction {
  return {
    type: ActionTypes.GET_PROGRAM_NAMES_SUCCESS,
    payload: programNames,
  }
}

export function getProgramNamesFailure(error: Error | string): GetProgramNamesFailureAction {
  return {
    type: ActionTypes.GET_PROGRAM_NAMES_FAILURE,
    error,
  }
}
