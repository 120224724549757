import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { ActionTypes } from '../actions/types'
import { discountActionCreators } from '../actions/creators'
import * as API from '../api/discounts'
import { AxiosError } from 'axios'
import { GetDiscountAction, GetDiscountByPharmacyAction, GetHaSfiByPharmacyAction } from '../actions/discountActionTypes'
import 'react-toastify/dist/ReactToastify.css'

function* onListDiscounts(getDiscountAction: GetDiscountAction) {
  yield put(discountActionCreators.getDiscountsRequest())
  try {
    const { data } = yield call(API.getDiscounts, getDiscountAction.payload, getDiscountAction.filter)
    yield put(discountActionCreators.getDiscountsSuccess(data))
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      yield put(discountActionCreators.getDiscountsFailure(error.response.data.errors[0]))
    }
  }
}

function* onGetDiscountByPharmacy(getDiscountByPharmacy: GetDiscountByPharmacyAction) {
  yield put(discountActionCreators.getDiscountByPharmacyRequest())
  console.log("FIL", getDiscountByPharmacy.filter);
  
  try {
    const { data } = yield call(
      API.getDiscountTransactionsByPharmacy,
      getDiscountByPharmacy.payload.id,
      getDiscountByPharmacy.payload.pageRequest,
      getDiscountByPharmacy.filter
    )
    console.log()
    yield put(discountActionCreators.getDiscountByPharmacySuccess(data))
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      yield put(discountActionCreators.getDiscountByPharmacyFailure(error.response.data.errors[0]))
    }
  }
}

function* onGetHaSfiByPharmacy(getHaSfiByPharmacy: GetHaSfiByPharmacyAction) {
  yield put(discountActionCreators.getHaSfiByPharmacyRequest())
  try {
    const { data } = yield call(API.getHaSfiTransactionsByPharmacy, getHaSfiByPharmacy.payload.id, getHaSfiByPharmacy.payload.pageRequest, getHaSfiByPharmacy.filter)
    yield put(discountActionCreators.getHaSfiByPharmacySuccess(data))
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      yield put(discountActionCreators.getHaSfiByPharmacyFailure(error.response.data.errors[0]))
    }
  }
}

function* watchDiscountActions() {
  yield takeEvery(ActionTypes.GET_DISCOUNT, onListDiscounts)
  yield takeEvery(ActionTypes.GET_DISCOUNT_BY_PHARMACY, onGetDiscountByPharmacy)
  yield takeEvery(ActionTypes.GET_HA_SFI_BY_PHARMACY, onGetHaSfiByPharmacy)
}

export default function* saga() {
  yield all([fork(watchDiscountActions)])
}
