import { GetIdentityRolesAction, GetIdentityRolesSuccessAction, GetIdentityRolesRequestAction, GetIdentityRolesFailureAction } from './../identityActionTypes';
import { IdentityUpdateData, UserFilter } from "../../api/types";
import { ActionTypes } from "../types";
import {
  UpdateIdentityAction,
  UpdateIdentityFailureAction,
  UpdateIdentityRequestAction,
  UpdateIdentitySuccessAction,
} from "../identityActionTypes";

export function updateIdentity(
  identity: IdentityUpdateData,
  filter?: UserFilter
): UpdateIdentityAction {
  return {
    type: ActionTypes.UPDATE_IDENTITY,
    payload: identity,
    filter
  };
}

export function updateIdentityRequest(): UpdateIdentityRequestAction {
  return {
    type: ActionTypes.UPDATE_IDENTITY_REQUEST,
  };
}

export function updateIdentitySuccess(): UpdateIdentitySuccessAction {
  return {
    type: ActionTypes.UPDATE_IDENTITY_SUCCESS,
  };
}

export function updateIdentityFailure(
  error: Error | string
): UpdateIdentityFailureAction {
  return {
    type: ActionTypes.UPDATE_IDENTITY_FAILURE,
    error,
  };
}

export function getIdentityRoles() : GetIdentityRolesAction {
  return {
    type: ActionTypes.GET_IDENTITY_ROLES,
  };
}

export function getIdentityRolesRequest() : GetIdentityRolesRequestAction {
  return {
    type: ActionTypes.GET_IDENTITY_ROLES_REQUEST,
  };
}

export function getIdentityRolesSuccess(
  roles: string[]
) : GetIdentityRolesSuccessAction {
  return {
    type: ActionTypes.GET_IDENTITY_ROLES_SUCCESS,
    payload: roles,
  };
}

export function getIdentityRolesFailure(
  error: Error | string
) : GetIdentityRolesFailureAction {
  return {
    type: ActionTypes.GET_IDENTITY_ROLES_FAILURE,
    error,
  };
}