import { all, fork } from 'redux-saga/effects'
import authSaga from './authSaga'
import brandSaga from './brandSaga'
import discountSaga from './discountSaga'
import enrollmentSaga from './enrollmentSaga'
import patientSaga from './patientSaga'
import identitySaga from './identitySaga'
import pharmacySaga from './pharmacySaga'
import programSaga from './programSaga'
import usersSaga from './userSaga'
import storageSaga from './storageSaga'
import stampSaga from './stampSaga'
import submissionSaga from './submissionSaga'

export default function* saga() {
  yield all([
    fork(authSaga),
    fork(brandSaga),
    fork(discountSaga),
    fork(enrollmentSaga),
    fork(patientSaga),
    fork(identitySaga),
    fork(pharmacySaga),
    fork(programSaga),
    fork(usersSaga),
    fork(storageSaga),
    fork(stampSaga),
    fork(submissionSaga),
  ])
}
