import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { ActionTypes } from '../actions/types'
import { GetStorageFilesAction, UploadImageAction } from '../actions/storageActionTypes'
import * as API from '../api/storage'
import { storageActionCreators } from '../actions/creators'
import { AxiosError } from 'axios'
import { store } from '../store'

function* onGetStorageFiles(getStorageFiles: GetStorageFilesAction) {
  yield put(storageActionCreators.getStorageFilesRequest())
  try {
    const { data } = yield call(API.getStorageFiles, getStorageFiles.payload)
    yield put(storageActionCreators.getStorageFilesSuccess(data))
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      yield put(storageActionCreators.getStorageFilesFailure(error.response.data.errors[0]))
    }
  }
}

function* onUploadImage(uploadImage: UploadImageAction) {
  yield put(storageActionCreators.uploadImageRequest())

  try {
    yield call(API.uploadImageFile, uploadImage.payload)
    yield put(storageActionCreators.uploadImageSuccess())
    yield put(
      storageActionCreators.getStorageFiles({
        page: store.getState().storage.page,
        size: store.getState().storage.size,
      }),
    )
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      yield put(storageActionCreators.uploadImageFailure(error.response.data.errors[0]))
    }
  }
}

function* watchStorageActions() {
  yield takeEvery(ActionTypes.GET_STORAGE_FILES, onGetStorageFiles)
  yield takeEvery(ActionTypes.UPLOAD_IMAGE, onUploadImage)
}

export default function* saga() {
  yield all([fork(watchStorageActions)])
  yield put(
    storageActionCreators.getStorageFiles({
      page: store.getState().storage.page,
      size: store.getState().storage.size,
    }),
  )
}
