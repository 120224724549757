import { TableCell, TableRow } from '@mui/material'
import type { FC } from 'react'
import { Discount } from '../core/api/types'
import { Roles } from '../constants/roles'
import dayjs from 'dayjs'

interface Props {
  discount: Discount,
  roles: string[]
}

const remove852fromPhoneNumber = (phone: string) => {
  if (phone.length === 11 && phone.startsWith('852'))
    return phone.slice(3);
  return phone;
}

const DiscountsTableRowComponent: FC<Props> = ({ discount, roles }) => {
  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} hover key={discount.id}>
        <TableCell style={{ textAlign: 'center' }}>{dayjs(discount.purchaseDate).tz('Asia/Hong_Kong').format('DD-MM-YYYY')}</TableCell>
        <TableCell style={{ textAlign: 'center' }}>{discount.id}</TableCell>
        <TableCell style={{ textAlign: 'center' }}>{roles.includes(Roles.MANAGE_PATIENT) && !roles.includes(Roles.VIEW_USERS) ? remove852fromPhoneNumber(discount.phoneNumber) : "-"}</TableCell>

        <TableCell style={{ textAlign: 'center' }}>{
          roles.includes(Roles.MANAGE_PATIENT) && !roles.includes(Roles.VIEW_USERS) ?
            (<>{discount.patientName}</>)
            : '-'
        }</TableCell>
        <TableCell style={{ textAlign: 'center' }}>{discount.patientId}</TableCell>
        <TableCell style={{ textAlign: 'center' }}>{discount.brandName}</TableCell>
        <TableCell style={{ textAlign: 'center' }}>{discount.programName}</TableCell>
        <TableCell style={{ textAlign: 'center' }}>{discount.numberOfBoxes}</TableCell>
        <TableCell style={{ textAlign: 'center' }}>{discount.numberOfBoxes}</TableCell>

        <TableCell style={{ textAlign: 'center' }}>{discount.discountAmount}</TableCell>
        <TableCell style={{ textAlign: 'center' }}>{discount.pharmacyId}</TableCell>
        <TableCell style={{ textAlign: 'center' }}>{discount.pharmacyName}</TableCell>
        <TableCell style={{ textAlign: 'center' }}>{discount.pharmacyNet}</TableCell>
        <TableCell style={{ textAlign: 'center' }}>{discount.region}</TableCell>
        <TableCell style={{ textAlign: 'center' }}>{discount.zuelligId}</TableCell>
      </TableRow>
    </>
  )
}

export default DiscountsTableRowComponent
