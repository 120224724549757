import { ProgramAction } from "../actions/programActionTypes";
import { ActionTypes } from "../actions/types";
import "react-toastify/dist/ReactToastify.css";

const reducer = (
  state : string[] = [],
  action: ProgramAction
): string[] => {
  switch (action.type) {
    case ActionTypes.GET_PROGRAM_NAMES_SUCCESS:
      return action.payload.map(name => name.split(" | ").pop() ?? "");
    default:
      return state;
  }
};

export default reducer;
