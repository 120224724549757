import { AuthAction } from "../core/actions/authActionTypes";
import { Dispatch } from "@reduxjs/toolkit";
import { LogInCredentials } from "../core/api/types";
import { logIn } from "../core/actions/creators/authActionCreators";
import { connect } from "react-redux";
import { LoginPageComponent } from "./LoginPageComponent";
import { State } from "../core/reducers";

const mapStateToProps = (state: State) => {
  return {
    tokens: state.auth,
  };
};
const mapDispatchToProps = (dispatch: Dispatch<AuthAction>) => ({
  logIn: (credentials: LogInCredentials) => dispatch(logIn(credentials)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPageComponent);
