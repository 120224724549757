import { Dispatch } from '@reduxjs/toolkit'
import { Action } from '../core/actions'
import { State } from '../core/reducers'
import { connect } from 'react-redux'
import { getPharmacyData, getRegions, updatePharmacy } from '../core/actions/creators/pharmacyActionCreators'
import PharmacyDataPageComponent from './PharmacyDataPageComponent'
import { IdentityUpdateData, PageRequest, PharmacyUpdate, TransactionFilter } from '../core/api/types'
import { updateIdentity } from '../core/actions/creators/identityActionCreators'
import { getProgramNames } from '../core/actions/creators/programActionCreators'
import { getDiscountByPharmacy, getHaSfiByPharmacy } from '../core/actions/creators/discountActionCreators'
import { getPendingByPharmacy, getPickedUpByPharmacy } from '../core/actions/creators/stampActionCreators'

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getPharmacyData: (id: string) => dispatch(getPharmacyData(id)),
  updateIdentity: (accessControlDetails: IdentityUpdateData) => dispatch(updateIdentity(accessControlDetails)),
  updatePharmacy: (updatePharmacyData: PharmacyUpdate) => dispatch(updatePharmacy(updatePharmacyData)),
  getProgramNames: () => dispatch(getProgramNames()),
  getRegions: () => dispatch(getRegions()),
  getDiscountByPharmacy: (id: string, pageRequest: PageRequest, filter? : TransactionFilter) => dispatch(getDiscountByPharmacy(id, pageRequest, filter)),
  getHaSfiByPharmacy: (id: string, pageRequest: PageRequest, filter? : TransactionFilter) => dispatch(getHaSfiByPharmacy(id, pageRequest, filter)),
  getPendingByPharmacy: (id: string, pageRequest: PageRequest, filter? : TransactionFilter) => dispatch(getPendingByPharmacy(id, pageRequest, filter)),
  getPickedUpByPharmacy: (id: string, pageRequest: PageRequest, filter? : TransactionFilter) => dispatch(getPickedUpByPharmacy(id, pageRequest, filter)),
})

const mapStateToProps = (state: State) => ({
  pharmacy: state.pharmacyData,
  programNames: state.programNames,
  programNamesWithBrand: state.programNamesWithBrands,
  brandNames: state.brandNames,
  regions: state.regions,
  discounts: state.discounts,
  hasfis: state.hasfis,
  stampsPending: state.stampsPending,
  stampsPickedUp: state.stampsPickedUp,
  roles: state.roles
})

export default connect(mapStateToProps, mapDispatchToProps)(PharmacyDataPageComponent)
