import { Dispatch } from "@reduxjs/toolkit";
import { Action } from "../core/actions";
import { State } from "../core/reducers";
import { connect } from "react-redux";
import UserCreationPageComponent from "./UserCreationPageComponent";
import { UserCreationData, UserFilter } from "../core/api/types";
import { createUser } from "../core/actions/creators/usersActionCreators";

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  createUser: (user: UserCreationData, filter?: UserFilter) => dispatch(createUser(user, filter)),
});

const mapStateToProps = (state: State) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserCreationPageComponent);
