import { toast } from 'react-toastify'
import { PagedTracker } from '../api/types/index'
import { ActionTypes } from '../actions/types'
import type { Patient } from '../api/types/index'
import type { PatientAction } from '../actions/patientActionTypes'
import 'react-toastify/dist/ReactToastify.css'

const initialState: PagedTracker<Patient> = {
  page: 0,
  size: 5,
  data: {
    content: [],
    pages: 0,
    totalItems: 0,
  },
}

const reducer = (state: PagedTracker<Patient> = initialState, action: PatientAction): PagedTracker<Patient> => {
  switch (action.type) {
    case ActionTypes.GET_PATIENT_DATA:
      return { ...state, page: action.payload.page, size: action.payload.size }
    case ActionTypes.GET_PATIENT_DATA_REQUEST:
      return state
    case ActionTypes.GET_PATIENT_DATA_SUCCESS:
      return { page: state.page, size: state.size, data: action.payload }
    case ActionTypes.GET_PATIENT_DATA_FAILURE:
      toast.error('There was an error loading Patients', {
        position: 'bottom-center',
      })
      return state
    default:
      return state
  }
}

export default reducer
