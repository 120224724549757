import { PatientUpdate, OrderUpdate, PatientFilter, RegisteredUserFilter } from './types/index'
import * as request from './helpers/request'
import { PageRequest, EnrollmentStatus } from './types'

export async function getEnrollments(pageRequest: PageRequest, filter?: PatientFilter) {
  if (filter?.name === '') {
    filter.name = null
  }
  if (filter?.phone === '') {
    filter.phone = null
  }
  return await request.call({
    url: `${process.env.REACT_APP_HOST_BASE_URL}/api/enrolls/filter?page=${pageRequest.page}&size=${pageRequest.size}`,
    method: 'POST',
    data: filter,
  })
}

export async function changeStatusEnrollment(enrollment: EnrollmentStatus) {
  return await request.call({
    url: `${process.env.REACT_APP_HOST_BASE_URL}/api/enrolls/status`,
    method: 'PUT',
    data: enrollment,
  })
}

export async function getPatientsData(pageRequest: PageRequest, filter?: RegisteredUserFilter) {
  if (filter?.phoneNumber === '') {
    filter.phoneNumber = null
  }
  if (filter?.hongKongId === '') {
    filter.hongKongId = null
  }

  if (filter?.id === '') {
    filter.id = null
  }
  return await request.call({
    url: `${process.env.REACT_APP_HOST_BASE_URL}/api/patients/filter?page=${pageRequest.page}&size=${pageRequest.size}`,
    method: 'POST',
    data: filter,
  })
}

export async function updatePatientData(patientData: PatientUpdate) {
  return await request.call({
    url: `${process.env.REACT_APP_HOST_BASE_URL}/api/patients/${patientData.id}`,
    method: 'PUT',
    data: patientData,
  })
}

export async function updateEnrollmentData(orderUpdate: OrderUpdate) {
  return await request.call({
    url: `${process.env.REACT_APP_HOST_BASE_URL}/api/enrolls/update/${orderUpdate.patientId}`,
    method: 'PUT',
    data: orderUpdate,
  })
}

export async function resetRedemptionBalance() {
  return await request.call({
    url: `${process.env.REACT_APP_HOST_BASE_URL}/api/enrolls`,
    method: 'DELETE',
  })
}
