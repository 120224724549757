import type { SetVerifiedUserTrigger, SetActivatedUserTrigger, SetBlockedUserTrigger } from '../registeredUsersTriggersAction'
import { ActionTypes } from '../types'

export function setVefifiedUserTrigger(): SetVerifiedUserTrigger {
  return {
    type: ActionTypes.SET_VERIFFIED_USER_TRIGGER,
  }
}

export function setActivatedUserTrigger(): SetActivatedUserTrigger {
  return {
    type: ActionTypes.SET_ACTIVATED_USER_TRIGGER,
  }
}
export function setBlockedUserTrigger(): SetBlockedUserTrigger {
  return {
    type: ActionTypes.SET_BLOCKED_USER_TRIGGER,
  }
}
