import { Dispatch } from "@reduxjs/toolkit";
import { Action } from "../core/actions";
import { State } from "../core/reducers";
import { connect } from "react-redux";
import BrandCreationPageComponent from "./BrandCreationPageComponent";
import { BrandCreation } from "../core/api/types";
import { saveBrand } from "../core/actions/creators/brandActionCreators";

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  createBrand: (brand: BrandCreation) => dispatch(saveBrand(brand)),
});

const mapStateToProps = (state: State) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BrandCreationPageComponent);
