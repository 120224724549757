import { PagedTracker } from './../api/types/index'
import { Pharmacy } from '../api/types'
import { PharmacyAction } from '../actions/pharmacyActionTypes'
import { ActionTypes } from '../actions/types'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import POSITION = toast.POSITION

const initialState: PagedTracker<Pharmacy> = {
  page: 0,
  size: 0,
  data: {
    content: [],
    pages: 0,
    totalItems: 0,
  },
}

const reducer = (state: PagedTracker<Pharmacy> = initialState, action: PharmacyAction): PagedTracker<Pharmacy> => {
  switch (action.type) {
    case ActionTypes.GET_PHARMACY:
      return { ...state, page: action.payload.page, size: action.payload.size }
    case ActionTypes.GET_PHARMACY_REQUEST:
      return state
    case ActionTypes.GET_PHARMACY_SUCCESS:
      return { page: state.page, size: state.size, data: action.payload }
    case ActionTypes.GET_PHARMACY_FAILURE:
      toast.error('There was an error loading pharmacies', {
        position: POSITION.BOTTOM_CENTER,
      })
      return state
    case ActionTypes.SAVE_PHARMACY_REQUEST:
      toast.dismiss()
      return state
    case ActionTypes.SAVE_PHARMACY_SUCCESS:
      toast.dismiss()
      toast.success('Pharmacy saved successfully', {
        position: POSITION.BOTTOM_CENTER,
      })
      return state
    case ActionTypes.SAVE_PHARMACY_FAILURE:
      toast.dismiss()
      console.log(action.error)
      toast.error(action.error.toString(), {
        position: POSITION.BOTTOM_CENTER,
      })
      return state
    case ActionTypes.UPDATE_PHARMACY_REQUEST:
      toast.dismiss()
      return state
    case ActionTypes.UPDATE_PHARMACY_SUCCESS:
      toast.dismiss()
      toast.success('Pharmacy updated successfully', {
        position: POSITION.BOTTOM_CENTER,
      })
      return state
    case ActionTypes.UPDATE_PHARMACY_FAILURE:
      toast.dismiss()
      toast.error(action.error.toString(), {
        position: POSITION.BOTTOM_CENTER,
      })
      return state
    default:
      return state
  }
}

export default reducer
