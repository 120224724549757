import { FC } from 'react'
import { Button, Card, CardActions, CardContent, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { userDetailsValidationSchema } from '../core/validations'
import { Add, CancelOutlined } from '@mui/icons-material'
import { useFormik } from 'formik'
import { useNavigate, useLocation } from 'react-router-dom'
import { IdentityCreationData, UserCreationData, UserFilter } from '../core/api/types'
import { CreateUserAction } from '../core/actions/usersActionTypes'
import { availableGroups } from '../core/validations/consts-and-functions'
import { Roles } from '../constants/roles'
import { RoleAccessProvider } from '../providers'
import { useSelector } from 'react-redux'
import { State } from '../core/reducers'
import * as request from '../core/api/helpers/request'
import { toast } from 'react-toastify'
import POSITION = toast.POSITION

interface Props {
  createUser: (user: UserCreationData, filter?: UserFilter) => CreateUserAction
}

function generatePassword(length: number) {
  let result = ''
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

const UserCreationPageComponent: FC<Props> = ({ createUser }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const goBackToUsersPage = () => {
    navigate('/users', { state: { from: location.pathname, filter: location.state?.filter } })
  }
  const createUserError = useSelector<State>((state) => state.users.error)

  const { values, errors, touched, handleSubmit, handleChange } = useFormik({
    initialValues: {
      isPasswordOneTime: false,
      isActive: true,
      dateRegistered: new Date(),
      chineseName: '',
      englishName: '',
      position: '',
      password: generatePassword(16),
      group: availableGroups[1],
      phoneNumber: '',
      username: '',
    },
    validationSchema: userDetailsValidationSchema,
    onSubmit: (user: any) => {
      let identityCreation: IdentityCreationData = {
        username: user.username,
        password: generatePassword(16),
        isPasswordOneTime: user.isPasswordOneTime,
        isActive: user.isActive,
        group: user.group,
      }

      let userCreation: UserCreationData = {
        englishName: user.englishName,
        chineseName: user.chineseName,
        position: user.position,
        phoneNumber: user.phoneNumber,
        identity: identityCreation,
      }

      request
        .call({
          url: `${process.env.REACT_APP_HOST_BASE_URL}/api/users/`,
          method: 'POST',
          data: userCreation,
        })
        .then((resp) => {
          goBackToUsersPage()
        })
        .catch((err) => {
          toast.dismiss()
          if (err.response.status === 409) {
            toast.error(err.response.data.error as string, {
              position: POSITION.BOTTOM_CENTER,
            })
          }else{
            toast.error(err.response.data.errors[0] as string, {
              position: POSITION.BOTTOM_CENTER,
            })
          }
        })

      // createUser(userCreation, location.state?.filter)
      // if(!createUserError){
      //   goBackToUsersPage()
      // }
    },
  })

  return (
    <RoleAccessProvider required={[Roles.MANAGE_USERS]}>
      <div>
        <Grid container spacing={2} sx={{ marginTop: 1, marginBottom: 2 }}>
          <Grid item xs={12}>
            <Card sx={{ flex: 1 }}>
              <form onSubmit={handleSubmit}>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        id='chineseName'
                        label='Chinese Name'
                        margin='normal'
                        type='text'
                        inputProps={{ maxLength: 30 }}
                        fullWidth
                        variant='filled'
                        value={values.chineseName}
                        error={touched.chineseName && Boolean(errors.chineseName)}
                        onChange={handleChange}
                        helperText={touched.chineseName && errors.chineseName}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id='englishName'
                        label='English Name'
                        margin='normal'
                        type='text'
                        inputProps={{ maxLength: 30 }}
                        fullWidth
                        variant='filled'
                        value={values.englishName}
                        error={touched.englishName && Boolean(errors.englishName)}
                        onChange={handleChange}
                        helperText={touched.englishName && errors.englishName}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        id='position'
                        label='Position'
                        margin='normal'
                        type='text'
                        inputProps={{ maxLength: 30 }}
                        fullWidth
                        variant='filled'
                        value={values.position}
                        error={touched.position && Boolean(errors.position)}
                        onChange={handleChange}
                        helperText={touched.position && errors.position}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl sx={{ marginTop: 2 }} variant={'filled'} fullWidth>
                        <InputLabel id={'group-label'}>Group</InputLabel>
                        <Select labelId='group-label' id='group' name='group' value={values.group} label='Group' onChange={handleChange}>
                          {availableGroups.map((group, index) => (
                            <MenuItem key={index} value={group}>
                              {group}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        id='username'
                        label='Username'
                        margin='normal'
                        type='text'
                        inputProps={{ maxLength: 30 }}
                        fullWidth
                        variant='filled'
                        value={values.username}
                        error={touched.username && Boolean(errors.username)}
                        onChange={handleChange}
                        helperText={touched.username && errors.username}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id='phoneNumber'
                        label='Phone Number'
                        margin='normal'
                        type='text'
                        inputProps={{ maxLength: 8 }}
                        fullWidth
                        variant='filled'
                        value={values.phoneNumber}
                        error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                        onChange={handleChange}
                        helperText={touched.phoneNumber && errors.phoneNumber}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions sx={{ backgroundColor: '#f1f1f1' }}>
                  <Button type={'submit'} sx={{ margin: 1 }} startIcon={<Add />} variant={'contained'} color='primary'>
                    Create
                  </Button>
                  <Button
                    onClick={goBackToUsersPage}
                    sx={{ margin: 1 }}
                    startIcon={<CancelOutlined />}
                    variant={'contained'}
                    color='primary'
                  >
                    Cancel
                  </Button>
                </CardActions>
              </form>
            </Card>
          </Grid>
        </Grid>
      </div>
    </RoleAccessProvider>
  )
}

export default UserCreationPageComponent
