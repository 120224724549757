import { useEffect, useState } from 'react'
import type { FC } from 'react'
import { useFormik } from 'formik'
import csvDownload from 'json-to-csv-export'
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material'
import { Check, Close, Download } from '@mui/icons-material'
import RegisteredUsersTableRowContainer from '../components/RegisteredUsersTableRowContainer'
import type { GetPatientsDataAction } from '../core/actions/patientActionTypes'
import type { PageRequest, PagedTracker, Patient, RegisteredUserFilter, RegisteredUsersTriggers } from '../core/api/types'

type Props = {
  getPatients: (PageRequest: PageRequest, filter?: RegisteredUserFilter) => GetPatientsDataAction
  patients: PagedTracker<Patient>
  registeredUsersTriggers: RegisteredUsersTriggers
}

const tableHeaders: readonly string[] = [
  'Chinese Name',
  'English Name',
  'Phone Number',
  'HKID',
  'Patient ID',
  'Address',
  'Email',
  'Status',
  'Enable/Disable',
  'Activate / Deactivate',
]

export const RegisteredUsersPageComponent: FC<Props> = ({ getPatients, patients, registeredUsersTriggers }) => {
  const [page, setPage] = useState<number>(0)
  const [size, setSize] = useState<number>(5)
  const [filters, setFilters] = useState<RegisteredUserFilter>({isBlocked: 'ENABLED'} as RegisteredUserFilter)

  const filterFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      phoneNumber: '',
      hongKongId: '',
      id: '',
      isBlocked: 'ENABLED'
    },
    onSubmit: (filters: RegisteredUserFilter) => {
      setPage(0)
      setFilters(filters)
    },
  })

  const exportRegisteredUsersData = () => {
    csvDownload({
      delimiter: ',', data: patients.data.content.map((patient) => {
        return {
          'Chinese Name': patient.chineseName,
          'English Name': patient.englishName,
          'Phone Number': patient.identity.username,
          'HKID': patient.hongKongId,
          'Patient ID': patient.id,
          'Address': patient.address,
          'Email': patient.email,
        };
      })
    })
  }

  const resetFilters = () => {
    setPage(0)
    setSize(5)
    setFilters({isBlocked: 'ENABLED'} as RegisteredUserFilter)
    filterFormik.resetForm({
      values: {
        phoneNumber: '',
        hongKongId: '',
        id: '',
        isBlocked: 'ENABLED'
      },
    })
  }

  useEffect(() => {
    getPatients({ page, size }, {...filters, isBlocked: filters.isBlocked === 'ENABLED' ? false : true})
  }, [getPatients, page, size, filters, registeredUsersTriggers])

  return (
    <>
     <Grid container justifyContent='flex-end' style={{ marginBottom: 24 }}>
        <Button
          onClick={exportRegisteredUsersData}
          style={{ marginRight: 12 }}
          startIcon={<Download />}
          variant={'contained'}
        >
          Export
        </Button>
        </Grid>
      <Grid container justifyContent='flex-end' style={{ marginBottom: 24 }}>
        <form onSubmit={filterFormik.handleSubmit} style={{ display: 'flex' }}>
          <FormControl style={{ marginLeft: 8 }} variant={'filled'} fullWidth>
            <TextField
              style={{ marginLeft: 8 }}
              id='phoneNumber'
              label='Phone Number'
              name='phoneNumber'
              type='text'
              fullWidth
              variant='filled'
              value={filterFormik.values.phoneNumber?.trim()}
              onChange={filterFormik.handleChange}
            />
          </FormControl>
          <FormControl style={{ marginLeft: 8 }} variant={'filled'} fullWidth>
            <TextField
              style={{ marginLeft: 8 }}
              id='id'
              label='Patient Id'
              name='id'
              type='text'
              fullWidth
              variant='filled'
              value={filterFormik.values.id?.trim()}
              onChange={filterFormik.handleChange}
            />
          </FormControl>
          <FormControl style={{ marginLeft: 8 }} variant={'filled'} fullWidth>
            <TextField
              style={{ marginLeft: 8 }}
              id='hongKongId'
              label='Hong Kong Id'
              name='hongKongId'
              type='text'
              fullWidth
              variant='filled'
              value={filterFormik.values.hongKongId?.trim()}
              onChange={filterFormik.handleChange}
            />
          </FormControl>
          <FormControl style={{ marginLeft: 8 }} variant={'filled'} fullWidth>
            <InputLabel id={'group-label'}>Enabled/Disabled</InputLabel>
            <Select
              style={{ width: 200, marginLeft: 8 }}
              labelId={'group-label'}
              id='isBlocked'
              name='isBlocked'
              value={filterFormik.values.isBlocked}
              label='Enabled/Disabled'
              onChange={(val) => {
                filterFormik.setFieldValue('isBlocked', val.target.value)
              }}
            >
              {[
                'ENABLED',
                'DISABLED',
              ].map((status, index) => (
                <MenuItem key={index} value={status}>
                  {status}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </form>
        <IconButton style={{ paddingLeft: 20, paddingRight: 20, marginLeft: 8 }} onClick={filterFormik.submitForm}>
          <Check />
        </IconButton>
        <IconButton type='reset' style={{ paddingLeft: 20, paddingRight: 20 }} onClick={resetFilters}>
          <Close />
        </IconButton>
      </Grid>
      <Paper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {tableHeaders.map((columnHeader, idx) => (
                  <TableCell key={idx} align={'center'}>
                    {columnHeader}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {patients.data.content.map((patient, index) => (
                <RegisteredUsersTableRowContainer key={patient.id} patient={patient} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[
            { label: '5', value: 5 },
            { label: '10', value: 10 },
            { label: '15', value: 15 },
            { label: '20', value: 20 },
            { label: '50', value: 50 },
            { label: '100', value: 100 },
            { label: '500', value: 500 },
            { label: '1000', value: 1000 },
            { label: 'All', value: patients.data.totalItems },
          ]}
          component='div'
          count={patients.data.totalItems}
          rowsPerPage={patients.size}
          page={patients.page}
          onPageChange={(_, currentPage) => setPage(currentPage)}
          onRowsPerPageChange={(e) => {
            setSize(Number(e.target.value))
            setPage(0)
          }}
        />
      </Paper>
    </>
  )
}
