import { useRoutes } from 'react-router-dom'
import { DashboardPageContainer, LoginPageContainer, UsersPageContainer } from '../pages'
import UserCreationPageContainer from '../pages/UserCreationPageContainer'
import PharmacyPageContainer from '../pages/PharmacyPageContainer'
import PharmacySelfDataPageContainer from '../pages/PharmacySelfDataPageContainer'
import PharmacyDataPageContainer from '../pages/PharmacyDataPageContainer'
import PharmacyCreationPageContainer from '../pages/PharmacyCreationPageContainer'
import BrandPageContainer from '../pages/BrandPageContainer'
import BrandDataPageContainer from '../pages/BrandDataPageContainer'
import BrandCreationPageContainer from '../pages/BrandCreationPageContainer'
import ProgramPageContainer from '../pages/ProgramPageContainer'
import ProgramDataPageContainer from '../pages/ProgramDataPageContainer'
import ProgramCreationPageContainer from '../pages/ProgramCreationPageContainer'
import EnrollmentPageContainter from '../pages/EnrollmentPageContainter'
import DiscountPageContainer from '../pages/DiscountPageContainer'
import RegisteredUsersPageContainer from '../pages/RegisteredUsersPageContainer'
import ProtectedRoute from './ProtectedRoute'

const AllRoutes = () => {
  let routes = useRoutes([
    {
      path: '/login',
      element: <LoginPageContainer />,
    },
    {
      path: '/',
      element: <DashboardPageContainer />,
      children: [
        {
          path: '/users',
          element: (
            <ProtectedRoute role='admin'>
              <UsersPageContainer />
            </ProtectedRoute>
          ),
        },
        {
          path: '/registered-users',
          element: (
            <ProtectedRoute role='fingerbox'>
              <RegisteredUsersPageContainer />
            </ProtectedRoute>
          ),
        },
        {
          path: '/create-user',
          element: (
            <ProtectedRoute role='admin'>
              <UserCreationPageContainer />
            </ProtectedRoute>
          ),
        },
        {
          path: '/pharmacies',
          element: <PharmacyPageContainer />,
        },
        {
          path: '/pharmacy/:pharmacyId',
          element: <PharmacySelfDataPageContainer />,
        },
        {
          path: '/pharmacies/:pharmacyId',
          element: <PharmacyDataPageContainer />,
        },
        {
          path: '/pharmacies/create',
          element: <PharmacyCreationPageContainer />,
        },
        {
          path: '/brands',
          element: (
            <ProtectedRoute role='admin'>
              <BrandPageContainer />
            </ProtectedRoute>
          ),
        },
        {
          path: '/brands/:brandId',
          element: (
            <ProtectedRoute role='admin'>
              <BrandDataPageContainer />
            </ProtectedRoute>
          ),
        },
        {
          path: '/brands/create',
          element: (
            <ProtectedRoute role='admin'>
              <BrandCreationPageContainer />
            </ProtectedRoute>
          ),
        },
        {
          path: '/programs',
          element: (
            <ProtectedRoute role='admin'>
              <ProgramPageContainer />
            </ProtectedRoute>
          ),
        },
        {
          path: '/programs/:programId',
          element: (
            <ProtectedRoute role='admin'>
              <ProgramDataPageContainer />
            </ProtectedRoute>
          ),
        },
        {
          path: '/programs/create',
          element: (
            <ProtectedRoute role='admin'>
              <ProgramCreationPageContainer />
            </ProtectedRoute>
          ),
        },
        {
          path: '/patients',
          element: (
            <ProtectedRoute role='fingerbox'>
              <EnrollmentPageContainter />
            </ProtectedRoute>
          ),
        },
        {
          path: '/discounts',
          element: <DiscountPageContainer />,
        },
      ],
    },
  ])

  return routes
}

export default AllRoutes
