import { Dispatch } from "@reduxjs/toolkit";
import { Action } from "../core/actions";
import { State } from "../core/reducers";
import { DashboardPageComponent } from "./DashboardPageComponent";
import { connect } from "react-redux";
import { logOut } from "../core/actions/creators/authActionCreators";

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  logOut: () => dispatch(logOut()),
});

const mapStateToProps = (state: State) => ({
  tokens: state.auth,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardPageComponent);
