export enum Roles {
    VIEW_USERS = "ROLE_VIEW_USERS",
    MANAGE_USERS = "ROLE_MANAGE_USERS",
    VIEW_BRANDS = "ROLE_VIEW_BRANDS",
    MANAGE_BRANDS = "ROLE_MANAGE_BRANDS",
    VIEW_PROGRAMS = "ROLE_VIEW_PROGRAMS",
    MANAGE_PROGRAMS = "ROLE_MANAGE_PROGRAMS",
    VIEW_PHARMACIES = "ROLE_VIEW_PHARMACIES",
    MANAGE_PHARMACIES = "ROLE_MANAGE_PHARMACIES",
    VIEW_PATIENT_STAMP_COLLECTION_ENROLLMENTS = "ROLE_VIEW_PATIENT_STAMP_COLLECTION_ENROLLMENTS",
    MANAGE_PATIENT_STAMP_COLLECITON_ENROLLMENTS = "ROLE_MANAGE_PATIENT_STAMP_COLLECTION_ENROLLMENTS",
    VIEW_PATIENT_INSTANT_DISCOUNT_ENROLLMENTS = "ROLE_VIEW_PATIENT_INSTANT_DISCOUNT_ENROLLMENTS",
    MANAGE_PATIENT_INSTANT_DISCOUNT_ENROLLMENTS = "ROLE_MANAGE_PATIENT_INSTANT_DISCOUNT_ENROLLMENTS",
    VIEW_PHARMACY_SELF = "ROLE_VIEW_PHARMACY_SELF",
    VIEW_PATIENT_STAMP_COLLECTION_ENROLLMENTS_SELF = "ROLE_VIEW_PATIENT_STAMP_COLLECTION_ENROLLMENTS_SELF",
    VIEW_PATIENT_INSTANT_DISCOUNT_ENROLLMENTS_SELF = "ROLE_VIEW_PATIENT_INSTANT_DISCOUNT_ENROLLMENTS_SELF",
    MANAGE_PHARMACY_SELF = "ROLE_MANAGE_PHARMACY_SELF",
    MANAGE_PATIENT_STAMP_COLLECTION_ENROLLMENTS_SELF = "ROLE_MANAGE_PATIENT_STAMP_COLLECTION_ENROLLMENTS_SELF",
    MANAGE_PATIENT_INSTANT_DISCOUNT_ENROLLMENTS_SELF = "ROLE_MANAGE_PATIENT_INSTANT_DISCOUNT_ENROLLMENTS_SELF",
    MANAGE_PATIENT = "ROLE_MANAGE_PATIENT",
    MANAGE_PATIENT_SELF = "ROLE_MANAGE_PATIENT_SELF",
    VIEW_PATIENT_SELF = "ROLE_VIEW_PATIENT_SELF",
    VIEW_PHARMACY_STAMP_COLLECTION_TRANSACTIONS = "ROLE_VIEW_PHARMACY_STAMP_COLLECTION_TRANSACTIONS",
    VIEW_PHARMACY_INSTANT_DISCOUNT_TRANSACTIONS = "ROLE_VIEW_PHARMACY_INSTANT_DISCOUNT_TRANSACTIONS",
    MANAGE_PATIENT_ACTIVATION = "ROLE_MANAGE_PATIENT_ACTIVATION"
}