import { UserFilter } from './../../api/types/index';
import { ActionTypes } from "../types";
import {
  Paged,
  PageRequest,
  User,
  UserCreationData,
  UserPasswordPatch,
  UserUpdateData,
} from "../../api/types";
import {
  ChangeUserPasswordAction,
  ChangeUserPasswordFailureAction,
  ChangeUserPasswordRequestAction,
  ChangeUserPasswordSuccessAction,
  CreateUserAction,
  CreateUserFailureAction,
  CreateUserRequestAction,
  CreateUserSuccessAction,
  GetUsersAction,
  GetUsersFailureAction,
  GetUsersRequestAction,
  GetUsersSuccessAction,
  UpdateUserAction,
  UpdateUserFailureAction,
  UpdateUserRequestAction,
  UpdateUserSuccessAction,
} from "../usersActionTypes";

export function getUsers(pageRequest: PageRequest, filter?: UserFilter): GetUsersAction {
  return {
    type: ActionTypes.GET_USERS,
    payload: pageRequest,
    filter: filter ?? <UserFilter>{}
  };
}

export function getUsersRequest(): GetUsersRequestAction {
  return {
    type: ActionTypes.GET_USERS_REQUEST,
  };
}

export function getUsersSuccess(users: Paged<User>): GetUsersSuccessAction {
  return {
    type: ActionTypes.GET_USERS_SUCCESS,
    payload: users,
  };
}

export function getUsersFailure(error: Error | string): GetUsersFailureAction {
  return {
    type: ActionTypes.GET_USERS_FAILURE,
    error,
  };
}

export function createUser(user: UserCreationData, filter?: UserFilter): CreateUserAction {
  return {
    type: ActionTypes.CREATE_USER,
    payload: user,
    filter
  };
}

export function createUserRequest(): CreateUserRequestAction {
  return {
    type: ActionTypes.CREATE_USER_REQUEST,
  };
}

export function createUserSuccess(): CreateUserSuccessAction {
  return {
    type: ActionTypes.CREATE_USER_SUCCESS,
  };
}

export function createUserFailure(
  error: Error | string
): CreateUserFailureAction {
  return {
    type: ActionTypes.CREATE_USER_FAILURE,
    error,
  };
}

export function updateUser(user: UserUpdateData, filter?: UserFilter): UpdateUserAction {
  return {
    type: ActionTypes.UPDATE_USER,
    payload: user,
    filter
  };
}

export function updateUserRequest(): UpdateUserRequestAction {
  return {
    type: ActionTypes.UPDATE_USER_REQUEST,
  };
}

export function updateUserSuccess(): UpdateUserSuccessAction {
  return {
    type: ActionTypes.UPDATE_USER_SUCCESS,
  };
}

export function updateUserFailure(
  error: Error | string
): UpdateUserFailureAction {
  return {
    type: ActionTypes.UPDATE_USER_FAILURE,
    error,
  };
}

export function changeUserPassword(
  userPasswordPatch: UserPasswordPatch
): ChangeUserPasswordAction {
  return {
    type: ActionTypes.CHANGE_USER_PASSWORD,
    payload: userPasswordPatch,
  };
}

export function changeUserPasswordRequest(): ChangeUserPasswordRequestAction {
  return {
    type: ActionTypes.CHANGE_USER_PASSWORD_REQUEST,
  };
}

export function changeUserPasswordSuccess(): ChangeUserPasswordSuccessAction {
  return {
    type: ActionTypes.CHANGE_USER_PASSWORD_SUCCESS,
  };
}

export function changeUserPasswordFailure(
  error: Error | string
): ChangeUserPasswordFailureAction {
  return {
    type: ActionTypes.CHANGE_USER_PASSWORD_FAILURE,
    error,
  };
}
