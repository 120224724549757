import { UpdateEnrollmentDataAction, UpdatePatientDataAction } from './../actions/enrollmentActionTypes'
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { ActionTypes } from '../actions/types'
import { enrollmentActionCreators, patientActionCreators, registeredUsersTriggersActionCreators } from '../actions/creators'
import * as API from '../api/enrollments'
import { AxiosError } from 'axios'
import { GetEnrollmentAction, UpdateEnrollmentStatusAction } from '../actions/enrollmentActionTypes'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import POSITION = toast.POSITION

import { store } from '../store'
function* onListEnrollments(getEnrollmentAction: GetEnrollmentAction) {
  yield put(enrollmentActionCreators.getEnrollmentsRequest())

  try {
    const { data } = yield call(API.getEnrollments, getEnrollmentAction.payload, getEnrollmentAction.filter)
    yield put(enrollmentActionCreators.getEnrollmentsSuccess(data))
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      yield put(enrollmentActionCreators.getEnrollmentsFailure(error.response.data.errors[0]))
    }
  }
}

function* onUpdateEnrollmentData(updateEnrollmentAction: UpdateEnrollmentDataAction) {
  console.log(updateEnrollmentAction)
  yield put(enrollmentActionCreators.updateEnrollmentStatusRequest())

  try {
    yield call(API.updateEnrollmentData, updateEnrollmentAction.payload)
    yield put(enrollmentActionCreators.updateEnrollmentDataSuccess())
    yield put(
      enrollmentActionCreators.getEnrollments({
        page: store.getState().enrollments.page,
        size: store.getState().enrollments.size,
      }),
    )
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      console.log()
      toast.error(error.response.data.message, {
        position: POSITION.BOTTOM_CENTER,
      })
      yield put(enrollmentActionCreators.updateEnrollmentDataFailure(error.response.data.errors[0]))
    }
  }
}

function* onUpdateEnrollment(updateEnrollmentAction: UpdateEnrollmentStatusAction) {
  console.log(updateEnrollmentAction)
  yield put(enrollmentActionCreators.updateEnrollmentStatusRequest())

  try {
    yield call(API.changeStatusEnrollment, updateEnrollmentAction.payload)
    yield put(enrollmentActionCreators.updateEnrollmentStatusSuccess())
    yield put(
      enrollmentActionCreators.getEnrollments(
        {
          page: store.getState().enrollments.page,
          size: store.getState().enrollments.size,
        },
        updateEnrollmentAction.filter,
      ),
    )
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      console.log()
      toast.error(error.response.data.message, {
        position: POSITION.BOTTOM_CENTER,
      })
      yield put(enrollmentActionCreators.updateEnrollmentStatusFailure(error.response.data.errors[0]))
    }
  }
}

function* onUpdatePatient(event: UpdatePatientDataAction) {
  console.log(event)
  yield put(enrollmentActionCreators.updatePatientDataRequest())

  try {
    yield call(API.updatePatientData, event.payload)
    yield put(enrollmentActionCreators.updatePatientDataSuccess())
    yield put(registeredUsersTriggersActionCreators.setBlockedUserTrigger())
    if (event.filter)
      yield put(
        enrollmentActionCreators.getEnrollments(
          {
            page: store.getState().enrollments.page,
            size: store.getState().enrollments.size,
          },
          event.filter,
        ),
      )
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      yield put(enrollmentActionCreators.updatePatientDataFailure(error.response.data.errors[0]))
    }
  }
}

function* onResetRedemptionBalance() {
  yield put(enrollmentActionCreators.resetRedemptionBalanceRequest())

  try {
    yield call(API.resetRedemptionBalance)
    yield put(enrollmentActionCreators.resetRedemptionBalanceSuccess())
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      toast.error(error.response.data.errors[0], {
        position: POSITION.BOTTOM_CENTER,
      })
      yield put(enrollmentActionCreators.resetRedemptionBalanceFailure(error.response.data.errors[0]))
    }
  }
}

function* watchEnrollmentActions() {
  yield takeEvery(ActionTypes.GET_ENROLLMENT, onListEnrollments)
  yield takeEvery(ActionTypes.UPDATE_ENROLLMENT_STATUS, onUpdateEnrollment)
  yield takeEvery(ActionTypes.UPDATE_PATIENT_DATA, onUpdatePatient)
  yield takeEvery(ActionTypes.UPDATE_ENROLLMENT_DATA, onUpdateEnrollmentData)
  yield takeEvery(ActionTypes.RESET_REDEMPTION_BALANCE, onResetRedemptionBalance)
}

export default function* saga() {
  yield all([fork(watchEnrollmentActions)])
}
