import * as request from './helpers/request'
import { Submission } from './types'

export async function approveSubmission(submission : Submission) {
  return await request.call({
      url: `${process.env.REACT_APP_HOST_BASE_URL}/api/subs/approve/${submission.id}`,
      method: "PUT",
  });
}

export async function declineSubmission(submission : Submission) {
    return await request.call({
        url: `${process.env.REACT_APP_HOST_BASE_URL}/api/subs/decline/${submission.id}`,
        method: "PUT",
    });
  }