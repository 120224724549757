import { InstantDiscountFilter, TransactionFilter } from './../../api/types/index';
import { Paged, PageRequest, Discount } from '../../api/types'
import {
  GetDiscountAction,
  GetDiscountByPharmacyAction,
  GetDiscountByPharmacyRequestAction,
  GetDiscountByPharmacySuccessAction,
  GetDiscountByPharmacyFailureAction,
  GetDiscountFailureAction,
  GetDiscountRequestAction,
  GetDiscountSuccessAction,
  GetHaSfiByPharmacyAction,
  GetHaSfiByPharmacyRequestAction,
  GetHaSfiByPharmacySuccessAction,
  GetHaSfiByPharmacyFailureAction,
} from '../discountActionTypes'
import { ActionTypes } from '../types'

export function getDiscounts(pageRequest: PageRequest, filter? : InstantDiscountFilter): GetDiscountAction {
  return {
    type: ActionTypes.GET_DISCOUNT,
    payload: pageRequest,
    filter: filter ?? <InstantDiscountFilter>{}
  }
}

export function getDiscountsRequest(): GetDiscountRequestAction {
  return {
    type: ActionTypes.GET_DISCOUNT_REQUEST,
  }
}

export function getDiscountsSuccess(discounts: Paged<Discount>): GetDiscountSuccessAction {
  return {
    type: ActionTypes.GET_DISCOUNT_SUCCESS,
    payload: discounts,
  }
}

export function getDiscountsFailure(error: Error | string): GetDiscountFailureAction {
  return {
    type: ActionTypes.GET_DISCOUNT_FAILURE,
    error,
  }
}

export function getDiscountByPharmacy(id: string, pageRequest: PageRequest, filter?: TransactionFilter): GetDiscountByPharmacyAction {
  return {
    type: ActionTypes.GET_DISCOUNT_BY_PHARMACY,
    payload: { id, pageRequest },
    filter: filter?? <TransactionFilter>{}
  }
}

export function getDiscountByPharmacyRequest(): GetDiscountByPharmacyRequestAction {
  return {
    type: ActionTypes.GET_DISCOUNT_BY_PHARMACY_REQUEST,
  }
}

export function getDiscountByPharmacySuccess(discounts: Paged<Discount>): GetDiscountByPharmacySuccessAction {
  return {
    type: ActionTypes.GET_DISCOUNT_BY_PHARMACY_SUCCESS,
    payload: discounts,
  }
}

export function getDiscountByPharmacyFailure(error: Error | string): GetDiscountByPharmacyFailureAction {
  return {
    type: ActionTypes.GET_DISCOUNT_BY_PHARMACY_FAILURE,
    error,
  }
}

export function getHaSfiByPharmacy(id: string, pageRequest: PageRequest, filter?: TransactionFilter): GetHaSfiByPharmacyAction {
  return {
    type: ActionTypes.GET_HA_SFI_BY_PHARMACY,
    payload: { id, pageRequest },
    filter: filter?? <TransactionFilter>{}
  }
}

export function getHaSfiByPharmacyRequest(): GetHaSfiByPharmacyRequestAction {
  return {
    type: ActionTypes.GET_HA_SFI_BY_PHARMACY_REQUEST,
  }
}

export function getHaSfiByPharmacySuccess(discounts: Paged<Discount>): GetHaSfiByPharmacySuccessAction {
  return {
    type: ActionTypes.GET_HA_SFI_BY_PHARMACY_SUCCESS,
    payload: discounts,
  }
}

export function getHaSfiByPharmacyFailure(error: Error | string): GetHaSfiByPharmacyFailureAction {
  return {
    type: ActionTypes.GET_HA_SFI_BY_PHARMACY_FAILURE,
    error,
  }
}
