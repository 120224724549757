import axios from "axios";
import { useCallback, useEffect, useState } from "react";

const useLocalization = () => {
    const [fields, setFields] = useState<any>(null);
    
    const getLocalizationJson = useCallback(async () => {
        const response = await axios.get("https://cdn.simplelocalize.io/2982cec1df3e40ac9fa78935f02ed61c/_latest/zh_HK");
        setFields(response.data);
    }, []);

    useEffect(() => {
        getLocalizationJson();
    }, []);

    return [fields];
}

export { useLocalization };