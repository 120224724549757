import { PagedTracker, StorageFile } from '../api/types'
import { ActionTypes } from '../actions/types'
import 'react-toastify/dist/ReactToastify.css'
import { StorageAction } from '../actions/storageActionTypes'
import { toast } from 'react-toastify'
import POSITION = toast.POSITION

const initialState: PagedTracker<StorageFile> = {
  page: 0,
  size: 9,
  data: {
    totalItems: 0,
    pages: 0,
    content: [],
  },
}

const reducer = (state: PagedTracker<StorageFile> = initialState, action: StorageAction): PagedTracker<StorageFile> => {
  switch (action.type) {
    case ActionTypes.UPLOAD_IMAGE_SUCCESS:
      toast.dismiss()
      toast.success('Item saved successfully', {
        position: POSITION.BOTTOM_CENTER,
      })
      return state
    case ActionTypes.UPLOAD_IMAGE_FAILURE:
      console.log(action.error)
      return state
    case ActionTypes.GET_STORAGE_FILES:
      state.page = action.payload.page
      state.size = action.payload.size
      return { ...state }
    case ActionTypes.GET_STORAGE_FILES_SUCCESS:
      state.data = action.payload
      return { ...state }
    default:
      return state
  }
}

export default reducer
