import { ReactElement } from "react";
import { Roles } from "../constants/roles";

interface Props {
    roles: string[];
    required: Roles[];
    children: ReactElement
}

const RoleAccessProviderComponent : React.FunctionComponent<Props> = ({ roles, children, required }) => {
    if (roles.length == 0 || required.some(required => !roles.includes(required))) {
        return <></>;
    }
    return (
        children
    );
}

export default RoleAccessProviderComponent;
