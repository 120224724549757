import axios from "axios";
import { LogInCredentials, TokenPair } from "./types";

export async function login(credentials: LogInCredentials): Promise<TokenPair> {
  let data = new FormData();
  data.append("username", credentials.username);
  data.append("password", credentials.password);
  return await axios.post(
    `${process.env.REACT_APP_HOST_BASE_URL}/api/login`,
    data
  );
}

export async function refreshTokens(refreshToken: string): Promise<TokenPair> {
  const { data } = await axios.post(
    `${process.env.REACT_APP_HOST_BASE_URL}/api/identity/refresh-token`,
    {},
    {
      headers: {
        Authorization: `Bearer ${refreshToken}`,
      },
    }
  );
  return data;
}
