import { TokenPair } from '../api/types'
import { AuthAction } from '../actions/authActionTypes'
import { ActionTypes } from '../actions/types'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import POSITION = toast.POSITION

const reducer = (state: TokenPair | null = null, action: AuthAction): TokenPair | null => {
  switch (action.type) {
    case ActionTypes.LOG_OUT:
      localStorage.clear()
      return null
    case ActionTypes.LOG_IN_REQUEST:
      return state
    case ActionTypes.LOG_IN_RESTORE:
      let tokens = localStorage.getItem('auth.tokens')
      if (tokens) {
        console.log('Session restored', JSON.parse(tokens))

        return JSON.parse(tokens)
      }
      return null
    case ActionTypes.LOG_IN_SUCCESS:
      localStorage.setItem('auth.tokens', JSON.stringify(action.payload))
      return action.payload
    case ActionTypes.LOG_IN_FAILURE:
      toast.error('Invalid e-mail or password', {
        position: POSITION.BOTTOM_CENTER,
      })
      return state
    default:
      return state
  }
}

export default reducer
