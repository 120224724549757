import { Check, Close, Download, Key, Refresh, Save, Settings } from '@mui/icons-material'
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from '@mui/material'
import { useFormik } from 'formik'
import { FC, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import PharmacyDiscountTableRowContainer from '../components/PharmacyDiscountTableRowContainer'
import PharmacyPendingTableRowContainer from '../components/PharmacyPendingTableRowContainer'
import PharmacyPickedUpTableRowContainer from '../components/PharmacyPickedUpTableRowContainer'
import { GetDiscountByPharmacyAction, GetHaSfiByPharmacyAction } from '../core/actions/discountActionTypes'
import { GetStampPendingAction, GetStampPickedUpAction } from '../core/actions/stampActionTypes'
import { UpdateIdentityAction } from '../core/actions/identityActionTypes'
import { GetPharmacyDataAction, UpdatePharmacyAction } from '../core/actions/pharmacyActionTypes'
import { GetProgramNamesAction } from '../core/actions/programActionTypes'
import { Discount, IdentityUpdateData, Paged, PageRequest, Pharmacy, PharmacyUpdate, Region, Stamp, TransactionFilter } from '../core/api/types'
import { updateIdentityValidationSchema } from '../core/validations'
import { Roles } from '../constants/roles'
import PharmacyHaSfiTableRowContainer from '../components/PharmacyHaSfiTableRowContainer'
import { LocalizationProvider, DatePicker, DateTimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { useLocalization } from '../hooks/useLocalization'
import csvDownload from 'json-to-csv-export'
import { isRole } from '../helpers/roleChecker'
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

interface Props {
  pharmacy: Pharmacy | null
  getPharmacyData: (id: string) => GetPharmacyDataAction
  getDiscountByPharmacy: (id: string, pageRequest: PageRequest, filter?: TransactionFilter) => GetDiscountByPharmacyAction
  getHaSfiByPharmacy: (id: string, pageRequest: PageRequest, filter?: TransactionFilter) => GetHaSfiByPharmacyAction
  getPendingByPharmacy: (id: string, pageRequest: PageRequest, filter?: TransactionFilter) => GetStampPendingAction
  getPickedUpByPharmacy: (id: string, pageRequest: PageRequest, filter?: TransactionFilter) => GetStampPickedUpAction
  discounts: Paged<Discount>
  hasfis: Paged<Discount>
  stampsPending: Paged<Stamp>
  stampsPickedUp: Paged<Stamp>
  getProgramNames: () => GetProgramNamesAction
  regions: Region[]
  programNames: string[],
  brandNames: string[],
  roles: string[],
  updateIdentity: (accessControlDetails: IdentityUpdateData) => UpdateIdentityAction
  updatePharmacy: (updatePharmacyData: PharmacyUpdate) => UpdatePharmacyAction
}

function generatePassword(length: number) {
  let result = ''
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div role='tabpanel' hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

const PharmacyDataPageComponent: FC<Props> = ({
  pharmacy,
  getPharmacyData,
  updateIdentity,
  updatePharmacy,
  getProgramNames,
  getDiscountByPharmacy,
  getHaSfiByPharmacy,
  getPendingByPharmacy,
  getPickedUpByPharmacy,
  discounts,
  hasfis,
  stampsPending,
  stampsPickedUp,
  programNames,
  brandNames,
  regions,
  roles,
}) => {
  const [locale] = useLocalization();
  const { pharmacyId } = useParams()
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)
  const [filters, setFilters] = useState<TransactionFilter>();

  const pendingHeaders = useMemo(() => ['transaction-id', 'patient-id', 'brand', 'dosage', 'number-of-boxes'], [])

  const pickedUpHeaders = useMemo(() => ['transaction-id', 'patient-id', 'brand', 'dosage', 'number-of-boxes', 'picked-up-date'], [])

  const instantHeaders = useMemo(
    () => ['purchase-id', 'patient-id', 'brand', 'program', 'number-of-boxes', 'discount-amount', 'purchase-date'],
    [],
  )

  const haSfiHeaders = useMemo(() => ['purchase-id', 'patient-id', 'brand', 'number-of-boxes', 'purchase-date'], [])

  const [programs, setPrograms] = useState<string[]>([])
  const [tab, setTab] = useState(0)

  const filterFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      dateFrom: null,
      dateTo: null,
      brand: null,
      program: null,
    },
    onSubmit: (filters: TransactionFilter) => {

      setFilters(filters);
    },
  })

  const resetFilters = () => {
    setPage(0)
    setSize(10)
    setPrograms([])
    setTab(0)
    setFilters({} as TransactionFilter)
    filterFormik.resetForm({
      values: {
        dateFrom: null,
        dateTo: null,
        brand: null,
        program: null,
      }
    })
  }

  useEffect(() => {
    getProgramNames()
    if (pharmacyId) {
      getPharmacyData(pharmacyId)
    }
  }, [])
  useEffect(() => {
    if (pharmacyId) {
      getPharmacyData(pharmacyId)
    }
  }, [pharmacyId, getPharmacyData])

  useEffect(() => {
    if (pharmacyId) {
      getDiscountByPharmacy(pharmacyId, { page, size }, filters)
    }
  }, [pharmacyId, getDiscountByPharmacy, page, size])

  useEffect(() => {
    if (pharmacyId) {
      getHaSfiByPharmacy(pharmacyId, { page, size }, filters)
    }
  }, [pharmacyId, getHaSfiByPharmacy, page, size])

  useEffect(() => {
    if (pharmacyId) {
      getPendingByPharmacy(pharmacyId, { page, size }, filters)
    }
  }, [pharmacyId, getPendingByPharmacy, page, size])

  useEffect(() => {
    if (pharmacyId) {
      getPickedUpByPharmacy(pharmacyId, { page, size }, filters)
    }
  }, [pharmacyId, getPickedUpByPharmacy, page, size])

  useEffect(() => {
    if (pharmacyId) {
      getDiscountByPharmacy(pharmacyId, { page, size }, filters)
      getHaSfiByPharmacy(pharmacyId, { page, size }, filters)
      getPendingByPharmacy(pharmacyId, { page, size }, filters)
      getPickedUpByPharmacy(pharmacyId, { page, size }, filters)
    }
  }, [filters]);


  const updateAccessControlFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: pharmacy?.identity.id,
      username: pharmacy?.identity.username,
      group: pharmacy?.identity.group,
      password: '',
      isPasswordOneTime: pharmacy?.identity.isPasswordOneTime,
      isActive: pharmacy?.identity.isActive,
    },
    validationSchema: updateIdentityValidationSchema,
    onSubmit: (accessControlData: IdentityUpdateData) => {
      updateIdentity(accessControlData)
    },
  })

  if (!pharmacy) {
    return <div></div>
  }
  if (!locale) {
    return <div></div>
  }

  return (
    <div>
      <Grid container justifyContent='flex-end' style={{ marginBottom: 24 }}>
        <Button
          onClick={
            () => {
              switch (tab) {
                case 0:
                  csvDownload({
                    filename: "stamps-pending", delimiter: ',', data: stampsPending.content.map((enrollment => {
                      return {
                        "Brand Name": enrollment.brandName,
                        "Dosage": enrollment.dosage,
                        "Number of Boxes": enrollment.numberOfBoxes,
                        "Patient Number ID": enrollment.patientNumberId,
                        "Pharmacy ID": enrollment.pharmacyId
                      }
                    }))
                  });
                  csvDownload({
                    filename: "stamps-picked-up", delimiter: ',', data: stampsPickedUp.content.map((enrollment => {
                      return {
                        "Brand Name": enrollment.brandName,
                        "Dosage": enrollment.dosage,
                        "Number of Boxes": enrollment.numberOfBoxes,
                        "Patient Number ID": enrollment.patientNumberId,
                        "Pharmacy ID": enrollment.pharmacyId,
                        "Picked Up Date": enrollment.pickUpDate,
                        "Transaction ID": enrollment.transactionId
                      }
                    }))
                  });
                  break;
                case 1:
                  csvDownload({
                    filename: "instant-discounts", delimiter: ',', data: discounts.content.map((discount) => {
                      return {
                        "Purchase Date": discount.purchaseDate,
                        "Purchase ID": discount.id,
                        "Patient ID": discount.patientId,
                        ...isRole('fingerbox') && {
                          "Patient Address": discount.patient.address,
                          "Patient Email Address": discount.patient.email,
                          "Patient Phone Number": discount.phoneNumber,
                          "Patient Name": discount.patientName,
                        },
                        "Brand": discount.brandName,
                        "Program": discount.programName,
                        "Number of Boxes": discount.numberOfBoxes,
                        "Discount Amount": discount.discountAmount,
                        "Pharmacy ID": discount.pharmacyId,
                        "Pharmacy Name": discount.pharmacyName,
                        "Pharmacy Address": pharmacy.addressChinese,
                        "Pharmacy Net": discount.pharmacyNet,
                        "HK City District": discount.region,
                        "Zuellig ID": discount.zuelligId,
                      };
                    })
                  });
                  break;
                case 2:
                  csvDownload({
                    filename: "ha-sfi", delimiter: ',', data: hasfis.content.map((discount) => {
                      return {
                        "Purchase Date": discount.purchaseDate,
                        "Purchase ID": discount.id,
                        "Patient ID": discount.patientId,
                        ...isRole('fingerbox') && {
                          "Patient Phone Number": discount.phoneNumber,
                          "Patient Name": discount.patientName,
                        },
                        "Brand": discount.brandName,
                        "Program": discount.programName,
                        "Number of Boxes": discount.numberOfBoxes,
                        "Discount Amount": discount.discountAmount,
                        "Pharmacy ID": discount.pharmacyId,
                        "Pharmacy Name": discount.pharmacyName,
                        "Pharmacy Net": discount.pharmacyNet,
                        "HK City District": discount.region,
                        "Zuellig ID": discount.zuelligId,
                      };
                    })
                  });
                  break;
              }
            }}
          style={{ marginRight: 12 }}
          startIcon={<Download />}
          variant={'contained'}
        >
          {locale["export"] ?? "export"}
        </Button>
        <Button startIcon={<Refresh />} onClick={() => window.location.reload()} variant={'contained'}>
          {locale["refresh"] ?? "refresh"}
        </Button>
      </Grid>
      <Grid container xs={12}>
        <Grid item xs={3}>

          <Card>
            <CardHeader title={<b>{pharmacy.nameChinese}</b>} subheader={pharmacy.addressChinese} />
            <CardContent>
              <Typography>
                <b>{locale['pharmacy-id'] || 'pharmacy-id'}</b> - {pharmacy.id}
              </Typography>
              <br />
              <Typography>
                <b>{locale['zuellig-id'] || 'zuellig-id'}</b> - {pharmacy.identity.username}
              </Typography>
              <br />
              <Typography>
                <b>{locale['pharmacy-region'] || 'pharmacy-region'}</b> - {pharmacy.region ?? (locale['not-specified'] || 'not-specified')}
              </Typography>
              <br />
              <Typography>
                <b>{locale['e-mail'] || 'e-mail'}</b> - {pharmacy.email ?? (locale['not-specified'] || 'not-specified')}
              </Typography>
              <br />
              <Typography>
                <b>{locale['pharmacy-phone-number'] || 'pharmacy-phone-number'}</b> - {pharmacy.phoneNumber ? pharmacy.phoneNumber : (locale['not-specified'] || 'not-specified')}
              </Typography>
              <img
                style={{ width: '100%', marginTop: 24 }}
                src={`https://api.qrserver.com/v1/create-qr-code/?size=500x500&data=${pharmacy.id}`}
              />
            </CardContent>
          </Card>
          <Card style={{ marginTop: 12 }}>
            <CardHeader title={<b>{locale['medicines'] || 'medicines'}</b>} />
            <CardContent>
              {pharmacy.brands.map((brand, index) => (
                <Chip label={brand} />
              ))}
            </CardContent>
          </Card>
          <Card style={{ marginTop: 12 }}>
            <CardHeader title={<b>{locale['stamp-collection-programs'] || 'stamp-collection-programs'}</b>} />
            <CardContent>
              {pharmacy.stampCollectionPrograms.map((program, index) => (
                <Chip label={program.name} />
              ))}
            </CardContent>
          </Card>
          <Card style={{ marginTop: 12 }}>
            <CardHeader title={<b>{locale['instant-discounts'] || 'instant-discounts'}</b>} />
            <CardContent>
              {pharmacy.instantDiscountPrograms.map((program, index) => (
                <Chip label={program.name} />
              ))}
            </CardContent>
          </Card>
          <Card>
            <Grid style={{ marginTop: 12 }} item xs={12}>
              <form onSubmit={updateAccessControlFormik.handleSubmit}>
                <Card variant={'outlined'} sx={{ flex: 1 }}>
                  <CardContent>
                    <Box mb={1} sx={{ display: 'flex', alignItems: 'center' }}>
                      <Key />
                      <Typography ml={2} variant={'h5'}>
                        <b>{locale['access-control'] || 'access-control'}</b>
                      </Typography>
                    </Box>
                    <Typography variant={'subtitle2'}>{locale['access-control-description'] || 'access-control-description'}</Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          disabled
                          id='username'
                          label={locale['username'] || 'username'}
                          margin='normal'
                          type='text'
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          variant='filled'
                          value={updateAccessControlFormik.values.username}
                          error={updateAccessControlFormik.touched.username && Boolean(updateAccessControlFormik.errors.username)}
                          onChange={updateAccessControlFormik.handleChange}
                          helperText={updateAccessControlFormik.touched.username && updateAccessControlFormik.errors.username}
                        />
                      </Grid>
                    </Grid>
                    <TextField
                      disabled
                      id='username'
                      label={locale['username'] || 'username'}
                      margin='normal'
                      type='text'
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      variant='filled'
                      value={updateAccessControlFormik.values.username}
                      error={updateAccessControlFormik.touched.username && Boolean(updateAccessControlFormik.errors.username)}
                      onChange={updateAccessControlFormik.handleChange}
                      helperText={updateAccessControlFormik.touched.username && updateAccessControlFormik.errors.username}
                    />
                    <TextField
                      id='password'
                      label={locale['new-password'] || 'new-password'}
                      margin='normal'
                      type='text'
                      fullWidth
                      variant='filled'
                      value={updateAccessControlFormik.values.password}
                      error={updateAccessControlFormik.touched.password && Boolean(updateAccessControlFormik.errors.password)}
                      onChange={updateAccessControlFormik.handleChange}
                      helperText={updateAccessControlFormik.touched.password && updateAccessControlFormik.errors.password}
                    />
                  </CardContent>
                  <CardActions sx={{ backgroundColor: '#f1f1f1' }}>
                    <Button type={'submit'} sx={{ margin: 1 }} startIcon={<Save />} variant={'contained'} color='primary'>
                      {locale['save'] || 'save'}
                    </Button>
                    <Button
                      onClick={() => {
                        updateAccessControlFormik.setFieldValue('password', generatePassword(8))
                      }}
                      sx={{ margin: 1 }}
                      startIcon={<Settings />}
                      variant={'contained'}
                      color='primary'
                    >
                      {locale['generate-password'] || 'generate-password'}
                    </Button>
                  </CardActions>
                </Card>
              </form>
            </Grid>

          </Card>
        </Grid>
      <Grid item xs={9}>
        <Tabs
          value={tab}
          style={{ marginLeft: 18, marginBottom: 12 }}
          onChange={(event: React.SyntheticEvent, newValue: number) => {
            setTab(newValue)
          }}
          aria-label='basic tabs example'
        >
          <Tab label={locale['stamp-collection'] || 'stamp-collection'} {...a11yProps(0)} />
          <Tab label={locale['instant-discount'] || 'instant-discount'} {...a11yProps(1)} />
          <Tab label={locale['ha-sfi'] || 'ha-sfi'} {...a11yProps(2)} />
        </Tabs>
        <Grid container style={{ marginBottom: 24 }}>
          <form onSubmit={filterFormik.handleSubmit} style={{ display: "flex", marginTop: 8, marginLeft: 42 }}>

            <FormControl variant={'filled'} fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label={locale['date-from'] || 'date-from'}
                  value={filterFormik.values.dateFrom}
                  inputFormat='YYYY-MM-DD'
                  onChange={(val) => {
                    filterFormik.setFieldValue('dateFrom', val == null ? null : val)
                  }}
                  renderInput={(params) => <TextField {...params} variant={'filled'} />}
                />
              </LocalizationProvider>
            </FormControl>
            <FormControl style={{ marginLeft: 8 }} variant={'filled'} fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label={locale['date-to'] || 'date-to'}
                  value={filterFormik.values.dateTo}
                  inputFormat='YYYY-MM-DD'
                  onChange={(val) => {
                    filterFormik.setFieldValue('dateTo', val == null ? null : val)
                  }}
                  renderInput={(params) => <TextField {...params} variant={'filled'} />}
                />
              </LocalizationProvider>
            </FormControl>
            <FormControl style={{ marginLeft: 8 }} variant={'filled'} fullWidth>
              <InputLabel id={'group-label'}>{locale['brand'] || 'brand'}</InputLabel>
              <Select

                labelId={'group-label'}
                id='brand'
                name='brand'
                value={filterFormik.values.brand}
                label={locale['brand'] || 'brand'}

                onChange={(val) => {
                  filterFormik.setFieldValue('brand', val.target.value)
                }}
              >
                {brandNames.map((brand, index) => (
                  <MenuItem key={index} value={brand}>
                    {brand}
                  </MenuItem>
                ))}
              </Select>

            </FormControl>
            <FormControl variant={'filled'} style={{ marginLeft: 8 }} fullWidth>
              <InputLabel id={'group-label'}>{locale['program'] || 'program'}</InputLabel>
              <Select

                labelId={'group-label'}
                id='program'
                name='program'
                value={filterFormik.values.program}
                label={locale['program'] || 'program'}

                onChange={(val) => {
                  filterFormik.setFieldValue('program', val.target.value)
                }}
              >
                {programNames.map((program, index) => (
                  <MenuItem key={index} value={program}>
                    {program}
                  </MenuItem>
                ))}
              </Select>

            </FormControl>
          </form>
          <IconButton style={{ paddingLeft: 20, paddingRight: 20, marginLeft: 8 }} onClick={filterFormik.submitForm}><Check /></IconButton>
          <IconButton type='reset' style={{ paddingLeft: 20, paddingRight: 20 }} onClick={resetFilters}><Close /></IconButton>
        </Grid>
        <TabPanel value={tab} index={0}>
          <Card style={{ marginLeft: 18, marginTop: 18 }}>
            <CardHeader title={<b>{locale['pending-order'] || 'pending-order'}</b>} />
            <CardContent>
              <Paper>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {pendingHeaders.map((i) => (
                          <TableCell key={i} align={'center'}>
                            {locale[i] || i}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {stampsPending.content.map((stamp, index) => (
                        <PharmacyPendingTableRowContainer key={index} stamp={stamp} />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[
                    { label: '5', value: 5 },
                    { label: '10', value: 10 },
                    { label: '15', value: 15 },
                    { label: '20', value: 20 },
                    { label: 'All', value: stampsPending.totalItems }
                  ].filter((row) => +row.value > 0)}
                  component='div'
                  count={stampsPending.totalItems}
                  rowsPerPage={size}
                  page={page}
                  onPageChange={(_, currentPage) => setPage(currentPage)}
                  onRowsPerPageChange={(e) => {
                    setSize(Number(e.target.value))
                    setPage(0)
                  }}
                />
              </Paper>
            </CardContent>
          </Card>

          <Card style={{ marginLeft: 18, marginTop: 18 }}>
            <CardHeader title={<b>{locale['picked-up'] || 'picked-up'}</b>} />
            <CardContent>
              <Paper>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {pickedUpHeaders.map((i) => (
                          <TableCell key={i} align={'center'}>
                            {locale[i] || i}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {stampsPickedUp.content.map((stamp, index) => (
                        <PharmacyPickedUpTableRowContainer key={index} stamp={stamp} />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[
                    { label: '5', value: 5 },
                    { label: '10', value: 10 },
                    { label: '15', value: 15 },
                    { label: '20', value: 20 },
                    { label: 'All', value: stampsPickedUp.totalItems }
                  ].filter((row) => +row.value > 0)}
                  component='div'
                  count={stampsPickedUp.totalItems}
                  rowsPerPage={size}
                  page={page}
                  onPageChange={(_, currentPage) => setPage(currentPage)}
                  onRowsPerPageChange={(e) => {
                    setSize(Number(e.target.value))
                    setPage(0)
                  }}
                />
              </Paper>
            </CardContent>
          </Card>
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <Card style={{ marginLeft: 18, marginTop: 18 }}>
            <CardHeader title={<b>{locale['instant-discounts'] || 'instant-discounts'}</b>} />
            <CardContent>
              <Paper>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {instantHeaders.map((i) => (
                          <TableCell key={i} align={'center'}>
                            {locale[i] || i}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {discounts.content.map((discount, index) => (
                        <PharmacyDiscountTableRowContainer key={index} discount={discount} />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[
                    { label: '5', value: 5 },
                    { label: '10', value: 10 },
                    { label: '15', value: 15 },
                    { label: '20', value: 20 },
                    { label: 'All', value: discounts.totalItems }
                  ].filter((row) => +row.value > 0)}
                  component='div'
                  count={discounts.totalItems}
                  rowsPerPage={size}
                  page={page}
                  onPageChange={(_, currentPage) => setPage(currentPage)}
                  onRowsPerPageChange={(e) => {
                    setSize(Number(e.target.value))
                    setPage(0)
                  }}
                />
              </Paper>
            </CardContent>
          </Card>
        </TabPanel>
        <TabPanel value={tab} index={2}>
          <Card style={{ marginLeft: 18, marginTop: 18 }}>
            <CardHeader title={<b>{locale['ha-sfi'] || 'ha-sfi'}</b>} />
            <CardContent>
              <Paper>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {haSfiHeaders.map((i) => (
                          <TableCell key={i} align={'center'}>
                            {locale[i] || i}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {hasfis.content.map((hasfi, index) => (
                        <PharmacyHaSfiTableRowContainer key={index} discount={hasfi} />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[
                    { label: '5', value: 5 },
                    { label: '10', value: 10 },
                    { label: '15', value: 15 },
                    { label: '20', value: 20 },
                    { label: 'All', value: hasfis.totalItems }
                  ].filter((row) => +row.value > 0)}
                  component='div'
                  count={hasfis.totalItems}
                  rowsPerPage={size}
                  page={page}
                  onPageChange={(_, currentPage) => setPage(currentPage)}
                  onRowsPerPageChange={(e) => {
                    setSize(Number(e.target.value))
                    setPage(0)
                  }}
                />
              </Paper>
            </CardContent>
          </Card>
        </TabPanel>
      </Grid>
    </Grid>
    </div>
  )
}

export default PharmacyDataPageComponent
