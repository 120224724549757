import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { ActionTypes } from '../actions/types'
import { stampActionCreators } from '../actions/creators'
import * as API from '../api/stamps'
import { AxiosError } from 'axios'
import { GetStampPendingAction, GetStampPickedUpAction } from '../actions/stampActionTypes'
import 'react-toastify/dist/ReactToastify.css'

function* onGetStampPendingByPharmacy(getStampPendingByPharmacy: GetStampPendingAction) {
  yield put(stampActionCreators.getPendingByPharmacyRequest())
  try {
    const { data } = yield call(
      API.getStampTransactionsPendingByPharmacy,
      getStampPendingByPharmacy.payload.id,
      getStampPendingByPharmacy.payload.pageRequest,
      getStampPendingByPharmacy.filter
    )
    console.log()
    yield put(stampActionCreators.getPendingByPharmacySuccess(data))
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      yield put(stampActionCreators.getPendingByPharmacyFailure(error.response.data.errors[0]))
    }
  }
}

function* onGetStampPickedUpByPharmacy(getStampPickedUpByPharmacy: GetStampPickedUpAction) {
  yield put(stampActionCreators.getPickedUpByPharmacyRequest())
  try {
    const { data } = yield call(
      API.getStampTransactionsPickedUpByPharmacy,
      getStampPickedUpByPharmacy.payload.id,
      getStampPickedUpByPharmacy.payload.pageRequest,
      getStampPickedUpByPharmacy.filter
    )
    console.log()
    yield put(stampActionCreators.getPickedUpByPharmacySuccess(data))
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      yield put(stampActionCreators.getPickedUpByPharmacyFailure(error.response.data.errors[0]))
    }
  }
}

function* watchStampActions() {
  yield takeEvery(ActionTypes.GET_STAMPS_PENDING, onGetStampPendingByPharmacy)
  yield takeEvery(ActionTypes.GET_STAMPS_PICKED_UP, onGetStampPickedUpByPharmacy)
}

export default function* saga() {
  yield all([fork(watchStampActions)])
}
