import type { RegisteredUsersTriggersActions } from '../actions/registeredUsersTriggersAction'
import type { RegisteredUsersTriggers } from '../api/types'
import { ActionTypes } from '../actions/types'

const initialState: RegisteredUsersTriggers = {
  verifiedTrigger: false,
  activatedTrigger: false,
  blockedTrigger: false,
}

const reducer = (state = initialState, action: RegisteredUsersTriggersActions) => {
  switch (action.type) {
    case ActionTypes.SET_VERIFFIED_USER_TRIGGER:
      return {
        ...state,
        verifiedTrigger: !state.verifiedTrigger,
      }
    case ActionTypes.SET_ACTIVATED_USER_TRIGGER:
      return {
        ...state,
        activatedTrigger: !state.activatedTrigger,
      }
    case ActionTypes.SET_BLOCKED_USER_TRIGGER:
      return {
        ...state,
        blockedTrigger: !state.blockedTrigger,
      }
    default:
      return state
  }
}

export default reducer
