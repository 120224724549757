import { Pharmacy } from '../api/types'
import { PharmacyAction } from '../actions/pharmacyActionTypes'
import { ActionTypes } from '../actions/types'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import POSITION = toast.POSITION

const reducer = (state: Pharmacy | null = null, action: PharmacyAction): Pharmacy | null => {
  switch (action.type) {
    case ActionTypes.GET_PHARMACY_DATA:
      return state
    case ActionTypes.GET_PHARMACY_DATA_REQUEST:
      return state
    case ActionTypes.GET_PHARMACY_DATA_SUCCESS:
      return action.payload
    case ActionTypes.GET_PHARMACY_DATA_FAILURE:
      toast.error('There was an error loading pharmacy data', {
        position: POSITION.BOTTOM_CENTER,
      })
      return state
    default:
      return state
  }
}

export default reducer
