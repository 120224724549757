import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { PagedTracker, PageRequest, User, UserFilter } from '../core/api/types'
import { GetUsersAction } from '../core/actions/usersActionTypes'
import { TableCell, TableContainer, TableHead, TableRow, Paper, TableBody, Button, Grid, Table, TablePagination, FormControl, IconButton, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { UsersTableRowContainer } from '../components/index'
import { Add, Check, Close, Download, Refresh } from '@mui/icons-material'
import { useNavigate, useLocation } from 'react-router-dom'
import csvDownload from 'json-to-csv-export'
import { RoleAccessProvider } from '../providers'
import { Roles } from '../constants/roles'
import { useFormik } from 'formik'
import { availableGroups } from '../core/validations/consts-and-functions'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'

interface Props {
  getUsers: (pageRequest: PageRequest, filter?: UserFilter) => GetUsersAction
  users: PagedTracker<User>
}

export const UsersPageComponent: FC<Props> = ({ getUsers, users: pagedUsers }) => {
  const users = pagedUsers.data
  const navigate = useNavigate()
  const location = useLocation()
  const previousPage = location.state?.from
  const [filters, setFilters] = useState<UserFilter>(previousPage === '/create-user' ? location.state?.filter : {} as UserFilter);
  const [page, setPage] = useState(previousPage === '/create-user' ? pagedUsers.page : 0)
  const [size, setSize] = useState(5)

  const tableHeaders = useMemo(() => ['', 'Username', 'Chinese name', 'Registration Date', 'Group', 'Actions'], [])

  const filterFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      group: filters?.group === undefined ? null : filters?.group,
      dateFrom: filters?.dateFrom === undefined ? null : filters?.dateFrom,
      dateTo: filters?.dateTo === undefined ? null : filters?.dateTo
    },
    onSubmit: (filters: UserFilter) => {
      setPage(0);
      setFilters(filters);
    },
  })

  const resetFilters = () => {
    setFilters({} as UserFilter)
    setPage(0)
    setSize(5)
    filterFormik.resetForm({
      values: {
        group: null,
        dateFrom: null,
        dateTo: null
      }
    })
  }

  const resetNavigateState = useCallback(() => {
    navigate(location.pathname, {})
  }, [navigate, location.pathname])

  const refreshPage = useCallback(() => {
    console.log("COMPONENT FILTERS", filters);

    getUsers({ page: page, size: size }, filters);
  }, [getUsers, page, size])

  useEffect(() => {
    refreshPage()
  }, [refreshPage])

  useEffect(() => {
    getUsers({ page: page, size: size }, filters);
  }, [filters]);

  useEffect(() => {
    if (previousPage === '/create-user') resetNavigateState()
  }, [previousPage, resetNavigateState])

  return (
    <RoleAccessProvider required={[Roles.VIEW_USERS]}>
      <div>
        <Grid container justifyContent='flex-end' style={{ marginBottom: 24 }}>
          <RoleAccessProvider required={[Roles.MANAGE_USERS]}>
            <Button onClick={() => navigate('/create-user', { state: { filter: filters } })} style={{ marginRight: 12 }} startIcon={<Add />} variant={'contained'}>
              Create
            </Button>
          </RoleAccessProvider>
          <Button
            onClick={() => csvDownload({
              delimiter: ',', data: users.content.map((content) => {
                return {
                  "Username": content.identity.username,
                  "Chinese name": content.chineseName,
                  "Registration date": dayjs(content.identity.dateRegistered).tz('Asia/Hong_Kong').format('DD-MM-YYYY'),
                  "Group": content.identity.group,
                  "Active": content.identity.isActive
                }
              })
            })}
            style={{ marginRight: 12 }}
            startIcon={<Download />}
            variant={'contained'}
          >
            Export
          </Button>
          <Button
            onClick={() => getUsers({ page, size }, filters)}
            style={{ marginRight: 12 }}
            startIcon={<Refresh />}
            variant={'contained'}
          >
            Refresh
          </Button>
        </Grid>
        <Grid container justifyContent='flex-end' style={{ marginBottom: 24 }}>
          <form onSubmit={filterFormik.handleSubmit} style={{ display: "flex" }}>

            <FormControl variant={'filled'} fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label='Date From'
                  value={filterFormik.values.dateFrom}
                  inputFormat='YYYY-MM-DD'
                  onChange={(val) => {
                    filterFormik.setFieldValue('dateFrom', val == null ? null : dayjs(val).toDate())
                  }}
                  renderInput={(params) => <TextField {...params} variant={'filled'} />}
                />
              </LocalizationProvider>
            </FormControl>
            <FormControl style={{ marginLeft: 8 }} variant={'filled'} fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label='Date To'
                  value={filterFormik.values.dateTo}
                  inputFormat='YYYY-MM-DD'
                  onChange={(val) => {
                    filterFormik.setFieldValue('dateTo', val == null ? null : dayjs(val).toDate())
                  }}
                  renderInput={(params) => <TextField {...params} variant={'filled'} />}
                />
              </LocalizationProvider>
            </FormControl>
            <FormControl style={{ marginLeft: 8 }} variant={'filled'} fullWidth>
              <InputLabel id={'group-label'}>Group</InputLabel>
              <Select

                style={{ width: 200 }}
                labelId={'group-label'}
                id='group'
                name='group'
                value={filterFormik.values.group}
                label='Group'

                onChange={(val) => {
                  filterFormik.setFieldValue('group', val.target.value)
                }}
              >
                {availableGroups.map((group, index) => (
                  <MenuItem key={index} value={group}>
                    {group}
                  </MenuItem>
                ))}
              </Select>

            </FormControl>
          </form>
          <IconButton style={{ paddingLeft: 20, paddingRight: 20, marginLeft: 8 }} onClick={filterFormik.submitForm}><Check /></IconButton>
          <IconButton type='reset' style={{ paddingLeft: 20, paddingRight: 20 }} onClick={resetFilters}><Close /></IconButton>
        </Grid>
        <Paper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {tableHeaders.map((i) => (
                    <TableCell key={i} align={'center'}>
                      {i}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {users.content.map((user, index) => (
                  <UsersTableRowContainer key={user.id} refreshPage={refreshPage} user={user} filter={filters} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[
              { label: '5', value: 5 },
              { label: '10', value: 10 },
              { label: '15', value: 15 },
              { label: '20', value: 20 },
              { label: 'All', value: users.totalItems }
            ]}
            component='div'
            count={users.totalItems}
            rowsPerPage={size}
            page={page}
            onPageChange={(_, currentPage) => setPage(currentPage)}
            onRowsPerPageChange={(e) => {
              setSize(Number(e.target.value))
              setPage(0)
            }}
          />
        </Paper>
      </div>
    </RoleAccessProvider>
  )
}
