import { ThemeProvider } from '@mui/material'
import { BrowserRouter as Router } from 'react-router-dom'
import { store } from './core/store'
import { Provider } from 'react-redux'
import { theme } from './utils'
import { ToastContainer } from 'react-toastify'
import './index.css'
import AllRoutes from './routes/AllRoutes'
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import dayjs from 'dayjs'
dayjs.extend(utc);
dayjs.extend(timezone);

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <ToastContainer />
        <Router>
          <AllRoutes />
        </Router>
      </ThemeProvider>
    </Provider>
  )
}

export default App
