import { Brand } from "../api/types";
import { BrandAction } from "../actions/brandActionTypes";
import { ActionTypes } from "../actions/types";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import POSITION = toast.POSITION;

const reducer = (
  state: Brand | null = null,
  action: BrandAction
): Brand | null => {
  switch (action.type) {
    case ActionTypes.GET_BRAND_DATA:
      return state;
    case ActionTypes.GET_BRAND_DATA_REQUEST:
      return state;
    case ActionTypes.GET_BRAND_DATA_SUCCESS:
      return action.payload;
    case ActionTypes.GET_BRAND_DATA_FAILURE:
      toast.error("There was an error loading brand data", {
        position: POSITION.BOTTOM_CENTER,
      });
      return state;
    case ActionTypes.DEACTIVATE_BRAND:
      return state;
    case ActionTypes.DEACTIVATE_BRAND_REQUEST:
      return state;
    case ActionTypes.DEACTIVATE_BRAND_SUCCESS:
      return state;
    case ActionTypes.DEACTIVATE_BRAND_FAILURE:
      toast.dismiss();
      toast.error(action.error.toString(), {
        position: POSITION.BOTTOM_CENTER,
      });
      return state;
    default:
      return state;
  }
};

export default reducer;
