import { Dispatch } from '@reduxjs/toolkit'
import { Action } from '../core/actions'
import { State } from '../core/reducers'
import { connect } from 'react-redux'
import ProgramCreationPageComponent from './ProgramCreationPageComponent'
import { PageRequest, Program, ProgramFilter } from '../core/api/types'
import { saveProgram } from '../core/actions/creators/programActionCreators'
import { getBrands } from '../core/actions/creators/brandActionCreators'

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  createProgram: (program: Program, filter?: ProgramFilter) => dispatch(saveProgram(program, filter)),
  getBrands: (pageRequest: PageRequest) => dispatch(getBrands(pageRequest)),
})

const mapStateToProps = (state: State) => ({
  brands: state.brands,
})

export default connect(mapStateToProps, mapDispatchToProps)(ProgramCreationPageComponent)
