import { LogInCredentials, TokenPair } from "../../api/types";
import {
  LogInAction,
  LogInFailureAction,
  LogInRequestAction,
  LogInRestoreAction,
  LogInSuccessAction,
  LogOutAction,
} from "../authActionTypes";
import { ActionTypes } from "../types";

export function logIn(credentials: LogInCredentials): LogInAction {
  return {
    type: ActionTypes.LOG_IN,
    payload: credentials,
  };
}

export function logInRequest(): LogInRequestAction {
  return {
    type: ActionTypes.LOG_IN_REQUEST,
  };
}

export function logInSuccess(tokens: TokenPair): LogInSuccessAction {
  return {
    type: ActionTypes.LOG_IN_SUCCESS,
    payload: tokens,
  };
}

export function logInFailure(error: Error | string): LogInFailureAction {
  return {
    type: ActionTypes.LOG_IN_FAILURE,
    error,
  };
}

export function logInRestore(): LogInRestoreAction {
  return {
    type: ActionTypes.LOG_IN_RESTORE,
  };
}

export function logOut(): LogOutAction {
  return {
    type: ActionTypes.LOG_OUT,
  };
}
