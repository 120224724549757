import { TableCell, TableRow } from '@mui/material'
import { FC } from 'react'
import { Stamp } from '../core/api/types'

interface Props {
  stamp: Stamp
}

const PharmacyStampTableRowComponent: FC<Props> = ({ stamp }) => {
  console.log(stamp)
  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell style={{ textAlign: 'center' }}>{stamp.enrollment.patient.id.slice(stamp.enrollment.patient.id.length - 8) + stamp.enrollment.program.id.slice(stamp.enrollment.program.id.length - 8)}</TableCell>
        <TableCell style={{ textAlign: 'center' }}>{stamp.patientNumberId}</TableCell>
        <TableCell style={{ textAlign: 'center' }}>{stamp.brandName}</TableCell>
        <TableCell style={{ textAlign: 'center' }}>{stamp.dosage}</TableCell>
        <TableCell style={{ textAlign: 'center' }}>{stamp.numberOfBoxes}</TableCell>
      </TableRow>
    </>
  )
}

export default PharmacyStampTableRowComponent
