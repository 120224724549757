import * as authActionCreators from './authActionCreators'
import * as brandActionCreators from './brandActionCreators'
import * as discountActionCreators from './discountActionCreators'
import * as enrollmentActionCreators from './enrollmentActionCreators'
import * as patientActionCreators from './patientActionCreators'
import * as identityActionCreators from './identityActionCreators'
import * as pharmacyActionCreators from './pharmacyActionCreators'
import * as programActionCreators from './programActionCreators'
import * as usersActionCreators from './usersActionCreators'
import * as storageActionCreators from './storageActionCreators'
import * as stampActionCreators from './stampActionCreators'
import * as submissionActionCreators from './submissionActionCreator'
import * as loadingActionCreators from './loadingActionCreators'
import * as ModalActionCreators from './modalActionCreators'
import * as registeredUsersTriggersActionCreators from './registeredUsersTriggersActionCreators'

export {
  authActionCreators,
  brandActionCreators,
  discountActionCreators,
  enrollmentActionCreators,
  patientActionCreators,
  identityActionCreators,
  pharmacyActionCreators,
  programActionCreators,
  usersActionCreators,
  storageActionCreators,
  stampActionCreators,
  submissionActionCreators,
  loadingActionCreators,
  ModalActionCreators,
  registeredUsersTriggersActionCreators,
}
