import { PagedTracker } from './../api/types/index'
import { Program } from '../api/types'
import { ProgramAction } from '../actions/programActionTypes'
import { ActionTypes } from '../actions/types'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import POSITION = toast.POSITION

const initialState: PagedTracker<Program> = {
  page: 0,
  size: 5,
  data: {
    content: [],
    pages: 0,
    totalItems: 0,
  },
}

const reducer = (state: PagedTracker<Program> = initialState, action: ProgramAction): PagedTracker<Program> => {
  switch (action.type) {
    case ActionTypes.GET_PROGRAM:
      return { ...state, page: action.payload.page, size: action.payload.size }
    case ActionTypes.GET_PROGRAM_REQUEST:
      return state
    case ActionTypes.GET_PROGRAM_SUCCESS:
      return { page: state.page, size: state.size, data: action.payload }
    case ActionTypes.GET_PROGRAM_FAILURE:
      toast.error('There was an error loading programs', {
        position: POSITION.BOTTOM_CENTER,
      })
      return state
    case ActionTypes.SAVE_PROGRAM_REQUEST:
      toast.dismiss()
      return state
    case ActionTypes.SAVE_PROGRAM_SUCCESS:
      toast.dismiss()
      toast.success('Program saved successfully', {
        position: POSITION.BOTTOM_CENTER,
      })
      return state
    case ActionTypes.SAVE_PROGRAM_FAILURE:
      toast.dismiss()
      console.log(action.error)
      toast.error(action.error.toString(), {
        position: POSITION.BOTTOM_CENTER,
      })
      return state
    case ActionTypes.UPDATE_PROGRAM_REQUEST:
      toast.dismiss()
      return state
    case ActionTypes.UPDATE_PROGRAM_SUCCESS:
      toast.dismiss()
      toast.success('Program updated successfully', {
        position: POSITION.BOTTOM_CENTER,
      })
      return state
    case ActionTypes.UPDATE_PROGRAM_FAILURE:
      toast.dismiss()
      toast.error(action.error.toString(), {
        position: POSITION.BOTTOM_CENTER,
      })
      return state
    default:
      return state
  }
}

export default reducer
