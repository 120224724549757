import { Dispatch } from '@reduxjs/toolkit'
import { Action } from '../core/actions'
import { State } from '../core/reducers'
import { connect } from 'react-redux'
import PharmacyDiscountTableRowComponent from './PharmacyDiscountTableRowComponent'

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({})

const mapStateToProps = (state: State) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(PharmacyDiscountTableRowComponent)
