import authReducer from './authReducer'
import brandReducer from './brandReducer'
import discountReducer from './discountReducer'
import hasfiReducer from './haSfiReducer'
import enrollmentReducer from './enrollmentReducer'
import brandDataReducer from './brandDataReducer'
import pharmacyReducer from './pharmacyReducer'
import pharmacyDataReducer from './pharmacyDataReducer'
import programReducer from './programReducer'
import programDataReducer from './programDataReducer'
import usersReducer from './usersReducer'
import storageReducer from './storageReducer'
import submissionReducer from './submissionReducer'
import stampPickedUpReducer from './stampPickedUpReducer'
import stampPendingReducer from './stampPendingReducer'
import programNamesReducer from './programNamesReducer'
import regionReducer from './regionReducer'
import roleReducer from './roleReducer'
import loadingReducer from './loadingReducer'
import pharmacyUnpagedReducer from './pharmacyUnpagedReducer'
import brandNamesReducer from './brandNamesReducer'
import patientReducer from './patientReducer'
import programNamesWithBrandsReducer from './programNamesWithBrandsReducer'
import modalReducer from './modalReducer'
import registeredUsersTriggers from './registeredUsersTriggers'

import { combineReducers } from '@reduxjs/toolkit'

const reducers = combineReducers({
  auth: authReducer,
  brands: brandReducer,
  brandData: brandDataReducer,
  discounts: discountReducer,
  hasfis: hasfiReducer,
  enrollments: enrollmentReducer,
  pharmacies: pharmacyReducer,
  pharmacyData: pharmacyDataReducer,
  programs: programReducer,
  programData: programDataReducer,
  users: usersReducer,
  storage: storageReducer,
  stampsPending: stampPendingReducer,
  stampsPickedUp: stampPickedUpReducer,
  submissions: submissionReducer,
  programNames: programNamesReducer,
  regions: regionReducer,
  roles: roleReducer,
  isLoading: loadingReducer,
  pharmaciesUnpaged: pharmacyUnpagedReducer,
  brandNames: brandNamesReducer,
  programNamesWithBrands: programNamesWithBrandsReducer,
  patients: patientReducer,
  modal: modalReducer,
  registereduserstriggers: registeredUsersTriggers,
})
export type State = ReturnType<typeof reducers>
export default reducers
