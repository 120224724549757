import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { ActionTypes } from '../actions/types'
import { submissionActionCreators, enrollmentActionCreators } from '../actions/creators'
import * as API from '../api/submissions'
import { AxiosError } from 'axios'
import { ApproveSubmssionAction, DeclineSubmssionAction } from '../actions/submissionActionTypes'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import POSITION = toast.POSITION
import { store } from '../store'

function* onApproveSubmission(approveSubmissionAction: ApproveSubmssionAction) {
  console.log(approveSubmissionAction)
  yield put(submissionActionCreators.approveSubmissionRequest())

  try {
    yield call(API.approveSubmission, approveSubmissionAction.payload)
    yield put(submissionActionCreators.approveSubmissionSuccess());
    yield put(enrollmentActionCreators.getEnrollments({ page: store.getState().enrollments.page, size: store.getState().enrollments.size }, approveSubmissionAction.filter))
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      console.log()
      toast.error(error.response.data.message, {
        position: POSITION.BOTTOM_CENTER,
      })
      yield put(submissionActionCreators.approveSubmissionFailure(error.response.data.errors[0]))
    }
  }
}

function* onDeclineSubmission(declineSubmissionAction: DeclineSubmssionAction) {
  console.log(declineSubmissionAction)
  yield put(submissionActionCreators.declineSubmissionRequest())

  try {
    yield call(API.declineSubmission, declineSubmissionAction.payload)
    yield put(submissionActionCreators.declineSubmissionSuccess())
    yield put(enrollmentActionCreators.getEnrollments({ page: store.getState().enrollments.page, size: store.getState().enrollments.size }, declineSubmissionAction?.filter))
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      console.log()
      toast.error(error.response.data.message, {
        position: POSITION.BOTTOM_CENTER,
      })
      yield put(submissionActionCreators.declineSubmissionFailure(error.response.data.errors[0]))
    }
  }
}

function* watchSubmissionActions() {
  yield takeEvery(ActionTypes.APPROVE_SUBMISSION, onApproveSubmission)
  yield takeEvery(ActionTypes.DECLINE_SUBMISSION, onDeclineSubmission)
}

export default function* saga() {
  yield all([fork(watchSubmissionActions)])
}
