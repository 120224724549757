import { Dispatch } from '@reduxjs/toolkit'
import { Action } from '../core/actions'
import { State } from '../core/reducers'
import { connect } from 'react-redux'
import { UsersPageComponent } from './UsersPageComponent'
import { PageRequest, UserFilter } from '../core/api/types'
import { getUsers } from '../core/actions/creators/usersActionCreators'

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getUsers: (pageRequest: PageRequest, filter?: UserFilter) => dispatch(getUsers(pageRequest, filter)),
})

const mapStateToProps = (state: State) => ({
  users: state.users,
})

export default connect(mapStateToProps, mapDispatchToProps)(UsersPageComponent)
