import { TransactionFilter } from './types/index';
import * as request from './helpers/request'
import { PageRequest } from './types'

export async function getStampTransactionsPendingByPharmacy(id: string, pageRequest: PageRequest, filter?: TransactionFilter) {
  console.log("TRANSACTION FILTER", filter);
  
  return await request.call({
    url: `${process.env.REACT_APP_HOST_BASE_URL}/api/stamps/transactions/pending/filter/${id}?page=${pageRequest.page}&size=${pageRequest.size}`,
    method: 'POST',
    data: filter ?? <TransactionFilter>{
      dateFrom: null,
      dateTo: null,
      brand: null,
      program: null
    }
  })
}

export async function getStampTransactionsPickedUpByPharmacy(id: string, pageRequest: PageRequest, filter?: TransactionFilter) {
  console.log("TRANSACTION FILTER", filter);

  return await request.call({
    url: `${process.env.REACT_APP_HOST_BASE_URL}/api/stamps/transactions/picked-up/filter/${id}?page=${pageRequest.page}&size=${pageRequest.size}`,
    method: 'POST',
    data: filter ?? <TransactionFilter>{
      dateFrom: null,
      dateTo: null,
      brand: null,
      program: null
    }
  })
}
