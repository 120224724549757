import { Roles } from './../../constants/roles';
import { GetIdentityRolesAction } from './../actions/identityActionTypes';

import { all,call, fork, put, takeEvery } from 'redux-saga/effects'
import { identityActionCreators, pharmacyActionCreators, usersActionCreators } from '../actions/creators'
import { getPharmacies } from '../actions/creators/pharmacyActionCreators';
import { AxiosError } from 'axios'
import { ActionTypes } from '../actions/types'
import { UpdateIdentityAction } from '../actions/identityActionTypes'

import * as API from '../api/identities';
import { store } from '../store'
import { getIdentityRoles } from '../actions/creators/identityActionCreators';

function* onUpdateIdentity(updateIdentity: UpdateIdentityAction) {
  yield put(identityActionCreators.updateIdentityRequest())

  try {
    yield call(API.updateIdentity, updateIdentity.payload);
    yield put(identityActionCreators.updateIdentitySuccess())
    if (store.getState().roles.includes(Roles.VIEW_USERS)) {
      yield put(usersActionCreators.getUsers({ page: store.getState().users.page, size: store.getState().users.size }, updateIdentity.filter))
      yield put(pharmacyActionCreators.getPharmacyData(`${updateIdentity.payload.id}`))
    }
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      yield put(identityActionCreators.updateIdentityFailure(error.response.data.errors[0]))
    }
  }
}

function* onGetIdentityRoles(event : GetIdentityRolesAction) {
  yield put(identityActionCreators.getIdentityRolesRequest())

  try {
    console.log("GETTING ROELS");
    
    const {data} = yield call(API.getIdentityRoles);
    console.log("ROLES", data);
    
    yield put(identityActionCreators.getIdentityRolesSuccess(data));
    console.log("==========================================");
    console.log(store.getState().roles.includes(Roles.VIEW_PHARMACIES));
    console.log("==========================================");
    if (store.getState().roles.includes(Roles.VIEW_PHARMACIES)) {
      console.log("REGIONS");
      
      yield put(pharmacyActionCreators.getRegions());
      yield put(pharmacyActionCreators.getAllPharmacies());
    }
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      yield put(identityActionCreators.getIdentityRolesFailure(error.response.data.errors[0]))
    }
  }
}
function* watchIdentityActions() {
  yield takeEvery(ActionTypes.UPDATE_IDENTITY, onUpdateIdentity);
  yield takeEvery(ActionTypes.GET_IDENTITY_ROLES, onGetIdentityRoles);
}

export default function* saga() {
  yield all([fork(watchIdentityActions)]);
}
