import { FC, useEffect, useState } from 'react'
import { Box, Button, Card, CardActions, CardContent, Grid, Paper, TextField, Typography } from '@mui/material'
import { brandValidationSchema } from '../core/validations'
import { CancelOutlined, Loyalty, PhotoLibraryOutlined, Save } from '@mui/icons-material'
import { useFormik } from 'formik'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { Brand, BrandCreation } from '../core/api/types'
import { GetBrandDataAction, UpdateBrandAction } from '../core/actions/brandActionTypes'
import { StorageGalleryContainer } from '../components'
import { RoleAccessProvider } from '../providers'
import { Roles } from '../constants/roles'

interface Props {
  getBrandData: (id: string) => GetBrandDataAction
  updateBrand: (brand: BrandCreation) => UpdateBrandAction
  brand: Brand | null
}

const BrandCreationPageComponent: FC<Props> = ({ getBrandData, updateBrand, brand }) => {
  const [selectedLogo, setSelectedLogo] = useState<string>('')
  const { brandId } = useParams()
  useEffect(() => {
    if (brandId) {
      getBrandData(brandId)
    }
  }, [brandId, getBrandData])

  const navigate = useNavigate()
  const location = useLocation()

  const goBackToBrandsPage = () => {
    navigate('/brands', { state: { from: location.pathname.replace(`${brandId}`, 'update') } })
  }

  const { values, errors, touched, handleSubmit, handleChange, setFieldValue } = useFormik({
    initialValues: {
      ...(brand as Brand),
    },
    validationSchema: brandValidationSchema,
    onSubmit: (brand: Brand) => {
      console.log(brand)
      updateBrand(brand)
      goBackToBrandsPage()
    },
    enableReinitialize: true,
  })

  if (!brand) return <></>

  return (
    <RoleAccessProvider required={[Roles.MANAGE_BRANDS]}>
      <div>
        <Grid spacing={4} container>
          <Grid item xs={6}>
            <Card sx={{ flex: 1 }}>
              <form onSubmit={handleSubmit}>
                <CardContent>
                  <Box mb={1} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Loyalty />
                    <Typography ml={2} variant={'h5'}>
                      <b>Arrange brand data</b>
                    </Typography>
                  </Box>
                  <Grid spacing={2} container>
                    <Grid item xs={12}>
                      <TextField
                        id='name'
                        label='Name of the brand'
                        margin='normal'
                        type='text'
                        fullWidth
                        variant='filled'
                        value={values.name}
                        error={touched.name && Boolean(errors.name)}
                        onChange={handleChange}
                        helperText={touched.name && errors.name}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Paper variant={'outlined'}>
                        <Box className={'brand-creation-upload-area'}>
                          {selectedLogo === '' ? (
                            <PhotoLibraryOutlined className={'centered-icon'} />
                          ) : (
                            <img
                              className={'image-preview centered-icon'}
                              src={`${process.env.REACT_APP_HOST_BASE_URL}/api/storage/image?id=${selectedLogo}`}
                              alt={selectedLogo}
                              loading='lazy'
                            />
                          )}

                          <Typography className={'centered-title'} variant={'overline'}>
                            {selectedLogo === '' ? 'Select a logo from gallery' : selectedLogo}
                          </Typography>
                        </Box>
                      </Paper>
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions sx={{ backgroundColor: '#f1f1f1' }}>
                  <Button type={'submit'} sx={{ margin: 1 }} startIcon={<Save />} variant={'contained'} color='primary'>
                    Save
                  </Button>
                  <Button
                    onClick={goBackToBrandsPage}
                    sx={{ margin: 1 }}
                    startIcon={<CancelOutlined />}
                    variant={'contained'}
                    color='primary'
                  >
                    Cancel
                  </Button>
                </CardActions>
              </form>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <StorageGalleryContainer
              onImageClicked={(file) => {
                setFieldValue('logo', file.identifier)
                setSelectedLogo(file.identifier)
              }}
            />
          </Grid>
        </Grid>
      </div>
    </RoleAccessProvider>
  )
}

export default BrandCreationPageComponent
