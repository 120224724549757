import { PagedTracker } from './../api/types/index'
import { Brand } from '../api/types'
import { BrandAction } from '../actions/brandActionTypes'
import { ActionTypes } from '../actions/types'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import POSITION = toast.POSITION

const initialState: PagedTracker<Brand> = {
  page: 0,
  size: 5,
  data: {
    content: [],
    pages: 0,
    totalItems: 0,
  },
}

const reducer = (state: PagedTracker<Brand> = initialState, action: BrandAction): PagedTracker<Brand> => {
  switch (action.type) {
    case ActionTypes.GET_BRAND:
      return { ...state, page: action.payload.page, size: action.payload.size }
    case ActionTypes.GET_BRAND_REQUEST:
      return state
    case ActionTypes.GET_BRAND_SUCCESS:
      return { page: state.page, size: state.size, data: action.payload }
    case ActionTypes.GET_BRAND_FAILURE:
      toast.error('There was an error loading brands', {
        position: POSITION.BOTTOM_CENTER,
      })
      return state
    case ActionTypes.SAVE_BRAND_REQUEST:
      toast.dismiss()
      return state
    case ActionTypes.SAVE_BRAND_SUCCESS:
      toast.dismiss()
      toast.success('Brand saved successfully', {
        position: POSITION.BOTTOM_CENTER,
      })
      return state
    case ActionTypes.SAVE_BRAND_FAILURE:
      toast.dismiss()
      console.log(action.error)
      toast.error(action.error.toString(), {
        position: POSITION.BOTTOM_CENTER,
      })
      return state
    case ActionTypes.UPDATE_BRAND_REQUEST:
      toast.dismiss()
      return state
    case ActionTypes.UPDATE_BRAND_SUCCESS:
      toast.dismiss()
      toast.success('Brand updated successfully', {
        position: POSITION.BOTTOM_CENTER,
      })
      return state
    case ActionTypes.UPDATE_BRAND_FAILURE:
      toast.dismiss()
      toast.error(action.error.toString(), {
        position: POSITION.BOTTOM_CENTER,
      })
      return state
    default:
      return state
  }
}

export default reducer
