import * as request from './helpers/request'
import { PageRequest, RegisteredUserFilter } from './types'

export async function getPatientsData(pageRequest: PageRequest, filter?: RegisteredUserFilter) {
  return await request.call({
    url: `${process.env.REACT_APP_HOST_BASE_URL}/api/patients/filter?page=${pageRequest.page}&size=${pageRequest.size}`,
    method: 'POST',
    data: filter,
  })
}

export async function activatePatient(id: string) {
  return await request.call({
    url: `${process.env.REACT_APP_HOST_BASE_URL}/api/patients/activate/${id}`,
    method: 'PUT',
  })
}

export async function deactivatePatient(id: string) {
  return await request.call({
    url: `${process.env.REACT_APP_HOST_BASE_URL}/api/patients/deactivate/${id}`,
    method: 'PUT',
  })
}

export async function verifyPatient(id: string) {
  return await request.call({
    url: `${process.env.REACT_APP_HOST_BASE_URL}/api/patients/verify/${id}`,
    method: 'PUT',
  })
}
