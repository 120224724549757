import { Paged, Discount } from '../api/types'
import { DiscountAction } from '../actions/discountActionTypes'
import { ActionTypes } from '../actions/types'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import POSITION = toast.POSITION

const initialState = {
  content: [],
  pages: 0,
  totalItems: 0,
}

const reducer = (state: Paged<Discount> = initialState, action: DiscountAction): Paged<Discount> => {
  switch (action.type) {
    case ActionTypes.GET_HA_SFI_BY_PHARMACY_REQUEST:
      return state
    case ActionTypes.GET_HA_SFI_BY_PHARMACY_SUCCESS:
      console.log(action.payload)
      return action.payload
    case ActionTypes.GET_HA_SFI_BY_PHARMACY_FAILURE:
      toast.error('There was an error loading Discounts', {
        position: POSITION.BOTTOM_CENTER,
      })
      return state
    default:
      return state
  }
}

export default reducer
