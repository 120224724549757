import { createTheme } from '@mui/material'

export default createTheme({
  palette: {
    primary: {
      main: '#D0006F',
    },
    secondary: {
      main: '#D0006F',
    },
  },
})
