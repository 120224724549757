import { BrandAction } from './../actions/brandActionTypes';
import { ProgramAction } from "../actions/programActionTypes";
import { ActionTypes } from "../actions/types";
import "react-toastify/dist/ReactToastify.css";

const reducer = (
  state : string[] = [],
  action: BrandAction
): string[] => {
  switch (action.type) {
    case ActionTypes.GET_BRAND_NAMES_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

export default reducer;
