import { Dispatch } from "@reduxjs/toolkit";
import { Action } from "../core/actions";
import { State } from "../core/reducers";
import { connect } from "react-redux";
import AppBarComponent from "./AppBarComponent";
import { logOut } from "../core/actions/creators/authActionCreators";
import { getIdentityRoles } from "../core/actions/creators/identityActionCreators";

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  logOut: () => dispatch(logOut()),
  getIdentityRoles: () => dispatch(getIdentityRoles()),
});

const mapStateToProps = (state: State) => ({
  roles: state.roles
});

export default connect(mapStateToProps, mapDispatchToProps)(AppBarComponent);
