import { Paged, Stamp } from '../api/types'
import { StampAction } from '../actions/stampActionTypes'
import { ActionTypes } from '../actions/types'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import POSITION = toast.POSITION

const initialState = {
  content: [],
  pages: 0,
  totalItems: 0,
}

const reducer = (state: Paged<Stamp> = initialState, action: StampAction): Paged<Stamp> => {
  switch (action.type) {
    case ActionTypes.GET_STAMPS_PENDING_REQUEST:
      return state
    case ActionTypes.GET_STAMPS_PENDING_SUCCESS:
      console.log(action.payload)
      return action.payload
    case ActionTypes.GET_STAMPS_PENDING_FAILURE:
      toast.error('There was an error loading stamps', {
        position: POSITION.BOTTOM_CENTER,
      })
      return state
    default:
      return state
  }
}

export default reducer
