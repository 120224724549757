import * as request from "./helpers/request";
import { BrandCreation, PageRequest } from "./types";

export async function getBrands(pageRequest: PageRequest) {
  return await request.call({
    url: `${process.env.REACT_APP_HOST_BASE_URL}/api/brands?page=${pageRequest.page}&size=${pageRequest.size}`,
    method: "GET",
  });
}

export async function getBrandData(id: string) {
  return await request.call({
    url: `${process.env.REACT_APP_HOST_BASE_URL}/api/brands/${id}`,
    method: "GET",
  });
}

export async function deactiveBrand(id: string) {
  return await request.call({
    url: `${process.env.REACT_APP_HOST_BASE_URL}/api/brands/${id}`,
    method: "DELETE",
  });
}

export async function createBrand(brand: BrandCreation) {
  return await request.call({
    url: `${process.env.REACT_APP_HOST_BASE_URL}/api/brands`,
    method: 'POST',
    data: brand,
  })
}

export async function updateBrand(brand : BrandCreation) {
  return await request.call({
      url: `${process.env.REACT_APP_HOST_BASE_URL}/api/brands/${brand.id}`,
      method: "PUT",
      data: brand
  });
}

export async function getBrandNames() {
  return await request.call({
    url: `${process.env.REACT_APP_HOST_BASE_URL}/api/brands/names`,
    method: 'GET',
  })
}
