import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from "react-redux"
import { GetProgramAction } from '../core/actions/programActionTypes'
import { PagedTracker, PageRequest, PharmacyFilter, Program, ProgramFilter } from '../core/api/types'
import { Button, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'
import { Add, Check, Close, Download, Refresh, AutoMode } from '@mui/icons-material'
import csvDownload from 'json-to-csv-export'
import { useNavigate, useLocation } from 'react-router-dom'
import ProgramsTableRowContainer from '../components/ProgramsTableRowContainer'
import { Roles } from '../constants/roles'
import { RoleAccessProvider } from '../providers'
import { useFormik } from 'formik'
import { nets, types } from '../core/validations/consts-and-functions'
import dayjs from 'dayjs'
import {ResetBalancesModalComponent} from '../components/ResetBalancesModalComponent'
import { setOpenModal } from "../core/actions/creators/modalActionCreators"

interface Props {
  getPrograms: (pageRequest: PageRequest, filter?: ProgramFilter) => GetProgramAction
  programs: PagedTracker<Program>,
  brandNames: string[]
}

export const ProgramPageComponent: FC<Props> = ({ brandNames, getPrograms, programs: pagedPrograms }) => {
  const programs = pagedPrograms.data
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const previousPage = location.state?.from
  const [page, setPage] = useState(previousPage === '/programs/update' || previousPage === '/programs/create' ? pagedPrograms.page : 0)
  const [size, setSize] = useState(5)
  const [filters, setFilters] = useState<ProgramFilter>(previousPage === '/programs/update' || previousPage === '/programs/create' ? location.state?.filter : undefined);

  const tableHeaders = useMemo(() => ['', 'Program Name', 'Type', 'Brand', 'Start Date', 'End Date', 'Status'], [])


  const showResetBalancesModal = () => {
    dispatch(setOpenModal({ open: true, patient: undefined }));
  }

  const filterFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      type: filters?.type === undefined ? null : filters?.type,
      active: filters?.active === true ? 'Active' : filters?.active === false ? 'Inactive' : null,
      brand: filters?.brand === undefined ? null : filters?.brand
    },
    onSubmit: (filters: any) => {
      console.log("FILTERS", filters);
      let programFilters: ProgramFilter = {
        type: filters.type,
        active: !filters.active ? null : (filters.active == "Active" ? true : false),
        brand: filters.brand
      }
      setPage(0);
      setFilters(programFilters);
    },
  })

  const resetFilters = () => {
    setFilters({} as ProgramFilter)
    setPage(0)
    setSize(5)
    filterFormik.resetForm({
      values: {
        type: null,
        active: null,
        brand: null
      }
    })
  }

  const resetNavigateState = useCallback(() => {
    navigate(location.pathname, {})
  }, [navigate, location.pathname])

  useEffect(() => {
    getPrograms({ page, size }, filters)
  }, [getPrograms, page, size])

  useEffect(() => {
    getPrograms({ page, size }, filters);
  }, [filters])

  useEffect(() => {
    if (previousPage === '/programs/update' || previousPage === '/programs/create') resetNavigateState()
  }, [previousPage, resetNavigateState])

  return (
    <RoleAccessProvider required={[Roles.VIEW_PROGRAMS]}>
      <div>
        <Grid container justifyContent='flex-end' style={{ marginBottom: 24 }}>
          <RoleAccessProvider required={[Roles.MANAGE_PROGRAMS]}>
            <Button onClick={() => navigate(`/programs/create`, { state: { filter: filters } })} style={{ marginRight: 12 }} startIcon={<Add />} variant={'contained'}>
              Create
            </Button>
          </RoleAccessProvider>
          <RoleAccessProvider required={[Roles.MANAGE_PROGRAMS]}>
            <Button onClick={showResetBalancesModal} style={{ marginRight: 12 }} startIcon={<AutoMode />} variant={'contained'}>
              New Year Reset
            </Button>
          </RoleAccessProvider>
          <Button
            onClick={() => csvDownload({
              delimiter: ',', data: programs.content.map((program) => {
                return {
                  "Name": program.name,
                  "Type": program.type,
                  "Brand": program.brand,
                  "Start Date": dayjs(program.startDate).tz('Asia/Hong_Kong').format('DD-MM-YYYY'),
                  "End Date": dayjs(program.endDate).tz('Asia/Hong_Kong').format('DD-MM-YYYY')
                }
              })
            })}
            style={{ marginRight: 12 }}
            startIcon={<Download />}
            variant={'contained'}
          >
            Export
          </Button>
          <Button onClick={() => getPrograms({ page, size }, filters)} startIcon={<Refresh />} variant={'contained'}>
            Refresh
          </Button>
        </Grid>
        <Grid container justifyContent='flex-end' style={{ marginBottom: 24 }}>
          <form onSubmit={filterFormik.handleSubmit} style={{ display: "flex" }}>
            <FormControl style={{ marginLeft: 8 }} variant={'filled'} fullWidth>
              <InputLabel id={'group-label'}>Program Status</InputLabel>
              <Select

                style={{ width: 200 }}
                labelId={'group-label'}
                id='active'
                name='active'
                value={filterFormik.values.active}
                label='Program Status'

                onChange={(val) => {
                  filterFormik.setFieldValue('active', val.target.value)
                }}
              >
                {["Active", "Inactive"].map((active, index) => (
                  <MenuItem key={index} value={active}>
                    {active}
                  </MenuItem>
                ))}
              </Select>

            </FormControl>
            <FormControl style={{ marginLeft: 8 }} variant={'filled'} fullWidth>
              <InputLabel id={'group-label'}>Program Type</InputLabel>
              <Select

                style={{ width: 200 }}
                labelId={'group-label'}
                id='type'
                name='type'
                value={filterFormik.values.type}
                label='Program Type'

                onChange={(val) => {
                  filterFormik.setFieldValue('type', val.target.value)
                }}
              >
                {types.map((type, index) => (
                  <MenuItem key={index} value={type.transfer}>
                    {type.label}
                  </MenuItem>
                ))}
              </Select>

            </FormControl>
            <FormControl style={{ marginLeft: 8 }} variant={'filled'} fullWidth>
              <InputLabel id={'group-label'}>Brand</InputLabel>
              <Select

                style={{ width: 200 }}
                labelId={'group-label'}
                id='brand'
                name='brand'
                value={filterFormik.values.brand}
                label='Brand'

                onChange={(val) => {
                  filterFormik.setFieldValue('brand', val.target.value)
                }}
              >
                {brandNames.map((brand, index) => (
                  <MenuItem key={index} value={brand}>
                    {brand}
                  </MenuItem>
                ))}
              </Select>

            </FormControl>
          </form>
          <IconButton style={{ paddingLeft: 20, paddingRight: 20, marginLeft: 8 }} onClick={filterFormik.submitForm}><Check /></IconButton>
          <IconButton type='reset' style={{ paddingLeft: 20, paddingRight: 20 }} onClick={resetFilters}><Close /></IconButton>
        </Grid>
        <Paper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {tableHeaders.map((i) => (
                    <TableCell key={i} align={'center'}>
                      {i}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {programs.content.map((program, index) => (
                  <ProgramsTableRowContainer key={index} program={program} filter={filters} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[
              { label: '5', value: 5 },
              { label: '10', value: 10 },
              { label: '15', value: 15 },
              { label: '20', value: 20 },
              { label: 'All', value: programs.totalItems }
            ]}
            component='div'
            count={programs.totalItems}
            rowsPerPage={size}
            page={page}
            onPageChange={(_, currentPage) => setPage(currentPage)}
            onRowsPerPageChange={(e) => {
              setSize(Number(e.target.value))
              setPage(0)
            }}
          />
        </Paper>
      <ResetBalancesModalComponent/>
      </div>

    </RoleAccessProvider>
  )
}
