import { Modal } from '../api/types'
import { ModalAction } from '../actions/modalActionTypes'
import { ActionTypes } from '../actions/types'

const initialState = {
  open: false,
  patient: undefined,
}

const reducer = (state: Modal = initialState, action: ModalAction) => {
  switch (action.type) {
    case ActionTypes.OPEN_MODAL:
      return action.payload
    default:
      return state
  }
}

export default reducer
