import { PagedTracker } from '../api/types/index'
import { Enrollment } from '../api/types'
import { EnrollmentAction } from '../actions/enrollmentActionTypes'
import { ActionTypes } from '../actions/types'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import POSITION = toast.POSITION

const initialState: PagedTracker<Enrollment> = {
  page: 0,
  size: 5,
  data: {
    content: [],
    pages: 0,
    totalItems: 0,
  },
}

const reducer = (state: PagedTracker<Enrollment> = initialState, action: EnrollmentAction): PagedTracker<Enrollment> => {
  switch (action.type) {
    case ActionTypes.GET_ENROLLMENT:
      return { ...state, page: action.payload.page, size: action.payload.size }
    case ActionTypes.GET_ENROLLMENT_REQUEST:
      return state
    case ActionTypes.GET_ENROLLMENT_SUCCESS:
      return { page: state.page, size: state.size, data: action.payload }
    case ActionTypes.GET_ENROLLMENT_FAILURE:
      toast.error('There was an error loading Enrollments', {
        position: POSITION.BOTTOM_CENTER,
      })
      return state
    case ActionTypes.UPDATE_ENROLLMENT_STATUS_REQUEST:
      toast.dismiss()
      return state
    case ActionTypes.UPDATE_ENROLLMENT_STATUS_SUCCESS:
      toast.dismiss()
      toast.success('Enrollment updated successfully', {
        position: POSITION.BOTTOM_CENTER,
      })
      return state
    case ActionTypes.UPDATE_ENROLLMENT_STATUS_FAILURE:
      toast.dismiss()
      toast.error(action.error.toString(), {
        position: POSITION.BOTTOM_CENTER,
      })
      return state
    default:
      return state
  }
}

export default reducer
