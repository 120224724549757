import { KeyboardArrowUp, KeyboardArrowDown, Save, Close } from '@mui/icons-material'
import {
  Box,
  Button,
  Collapse,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import { useFormik, FormikProps } from 'formik'
import { FC, useState, useMemo } from 'react'
import { Roles } from '../constants/roles'
import {
  UpdateEnrollmentDataAction,
  UpdateEnrollmentStatusAction,
  UpdatePatientDataAction,
} from '../core/actions/enrollmentActionTypes'
import { Enrollment, EnrollmentStatus, OrderUpdate, PatientFilter, PatientUpdate, Pharmacy } from '../core/api/types'
import { RoleAccessProvider } from '../providers'
import SubmissionTableRowContainer from './SubmissionTableRowContainer'
import * as yup from 'yup'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'

interface Props {
  enrollment: Enrollment
  pharmaciesUnpaged: Pharmacy[]
  roles: string[]
  filter?: PatientFilter
  showOrderInformationSection: boolean
  updateEnrollmentStatus: (enrollment: EnrollmentStatus, filter?: PatientFilter) => UpdateEnrollmentStatusAction
  updatePatientData: (patientUpdate: PatientUpdate, filter?: PatientFilter) => UpdatePatientDataAction
  updateEnrollmentData: (orderUpdate: OrderUpdate) => UpdateEnrollmentDataAction
}

type OrderFormikProps = {
  patientId: string,
  programId: string,
  brand: string,
  order: string,
  pharmacyNetwork: string,
  district?: string
  address?: string
  dosage: string
}

const EnrollmentsTableRowComponent: FC<Props> = ({
  roles,
  enrollment,
  filter,
  showOrderInformationSection,
  updateEnrollmentStatus,
  updatePatientData,
  pharmaciesUnpaged,
  updateEnrollmentData,
}) => {
  const [open, setOpen] = useState(false)
  const [showLatest, setShowLatest] = useState(true)

  const patientFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: enrollment.patient.id,
      chineseName: enrollment.patient.chineseName,
      englishName: enrollment.patient.englishName,
      email: enrollment.patient.email,
      hongKongId: enrollment.patient.hongKongId,
      memberId: enrollment.patient.memberId,
      address: enrollment.patient.address,
      birthDate: enrollment.patient.birthDate,
      comment: enrollment.patient.comment,
      verified: enrollment.patient.verified,
      active: enrollment.patient.active,
      dateRegistered: enrollment.patient.dateRegistered,
      blocked: enrollment.patient.blocked
    },
    validationSchema: yup.object({ chineseName: yup.string().required('Chinese Name is mandatory.') }),
    onSubmit: (patientUpdate: PatientUpdate) => {
      updatePatientData(patientUpdate, filter)
    },
  })

  console.log(enrollment)

  const orderFormik: FormikProps<OrderFormikProps> = useFormik<OrderFormikProps>({
    enableReinitialize: true,
    initialValues: {
      patientId: enrollment.patient.id,
      programId: enrollment.program.id,
      brand: enrollment.program.brand,
      order: enrollment.dosage ?? 'Order not made yet',
      pharmacyNetwork: enrollment.pharmacyNetwork ?? 'Order not made yet',
      district: enrollment.pharmacy?.region,
      address: enrollment.pharmacy?.addressChinese,
      dosage: enrollment.dosage,
    },
    validationSchema: yup.object({ address: yup.string().required('Pharmacy address is required') }),
    onSubmit: (order: any) => {
      let pharmacy: Pharmacy | undefined = pharmaciesUnpaged.find((p) => p.addressChinese === order.address)
      if (!pharmacy) {
        return
      }

      let orderUpdate: OrderUpdate = {
        pharmacyId: pharmacy.id,
        patientId: enrollment.patient.id,
        programId: enrollment.program.id,
        dosage: order.dosage,
      }

      updateEnrollmentData(orderUpdate)
    },
  })

  const districts = useMemo(() => getDistricts(pharmaciesUnpaged, orderFormik), [pharmaciesUnpaged])

  const mapTransactionStatusToMessage = (enrollment: Enrollment) => {
    if (enrollment.transactionStatus === 'COUPON_PROPOSED' && dayjs(enrollment.arrangePickUpBy.slice(0, 10), 'YYYY-MM-DD').isBefore(dayjs())) return 'Expired'
    if (enrollment.transactionStatus === 'DELIVERED' && dayjs(enrollment.redeemBy.slice(0, 10), 'YYYY-MM-DD').isBefore(dayjs())) return 'Expired'
    switch (enrollment.transactionStatus) {
      case 'ENROLLMENT_BASED':
        return 'Waiting for photos'
      case 'COUPON_PROPOSED':
        return 'To arrange pick up'
      case 'PICKUP_ARRANGED':
        return 'Pick up arranged'
      case 'PICKUP_APPROVED':
        return 'Pick up approved'
      case 'PICKUP_DECLINED':
        return 'Waiting for photos'
      case 'SHIPPED':
        return 'Shipped'
      case 'DELIVERED':
        return 'Delivered'
      case 'COUPON_BURNT':
        return 'No redeem balance left'
    }
  }

  const remove852fromPhoneNumber = (phone: string) => {
    if (phone.length === 11 && phone.startsWith('852')) return phone.slice(3)
    return phone
  }

  const calculatePatientStatus = (enrollment: Enrollment) => {
    if (enrollment.patient.active) {
      return enrollment.patient.verified ? 'VERIFIED' : 'UNVERIFIED'
    }
    return 'DELETED/'.concat(enrollment.patient.dateRegistered ? dayjs(enrollment.patient.dateRegistered).tz('Asia/Hong_Kong').format('DD-MM-YYYY') : '—')
  }

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} hover key={enrollment.dateEnrolled}>
        <TableCell style={{ width: '5%' }}>
          <IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell style={{ textAlign: 'center' }}>
          {roles.includes(Roles.MANAGE_PATIENT) ? (
            <>
              {enrollment.patient.englishName ?? '-'} / {enrollment.patient.chineseName}
            </>
          ) : (
            '-'
          )}
        </TableCell>
        <TableCell style={{ textAlign: 'center' }}>{remove852fromPhoneNumber(enrollment.patient.identity.username)}</TableCell>
        <TableCell style={{ textAlign: 'center' }}>{dayjs(enrollment.patient.dateRegistered).tz('Asia/Hong_Kong').format('DD-MM-YYYY')}</TableCell>
        <TableCell style={{ textAlign: 'center' }}>{calculatePatientStatus(enrollment)}</TableCell>
        <TableCell style={{ textAlign: 'center' }}><Link to={`/programs/${enrollment.program.id}`}>{enrollment.program.name}</Link></TableCell>
        <TableCell style={{ textAlign: 'center' }}>{enrollment.redemptionBalance}</TableCell>
        <TableCell style={{ textAlign: 'center' }}>{enrollment.approvedStamps}</TableCell>
        <TableCell style={{ textAlign: 'center' }}>{enrollment.declinedStamps}</TableCell>
        <TableCell style={{ textAlign: 'center' }}>{enrollment.pendingStamps}</TableCell>
        <TableCell style={{ textAlign: 'center' }}>{mapTransactionStatusToMessage(enrollment)}</TableCell>
        <TableCell style={{ textAlign: 'center' }}>{enrollment.arrangePickUpBy ? dayjs(enrollment.arrangePickUpBy).tz('Asia/Hong_Kong').format('DD-MM-YYYY') : '—'}</TableCell>
        <TableCell style={{ textAlign: 'center' }}>{enrollment.redeemBy ? dayjs(enrollment.redeemBy).tz('Asia/Hong_Kong').format('DD-MM-YYYY') : '—'}</TableCell>
      </TableRow>
      <TableRow style={{ textAlign: 'center' }}>
        <TableCell style={{ paddingBottom: open ? 18 : 0, paddingTop: 0 }} colSpan={14}>
          <Collapse in={open} timeout={'auto'} unmountOnExit>
            <Grid container>
              <Grid item xs={4}>
                <FormControlLabel
                  style={{ marginLeft: 2, marginTop: 16 }}
                  control={<Switch checked={showLatest} onChange={(e) => setShowLatest(e.target.checked)} />}
                  label={`Show latest submissions - (Latest ${enrollment.program.discountQuantityBuyX ?? 0})`}
                />

                <SubmissionTableRowContainer
                  pharmacyNetwork={enrollment.pharmacyNetwork}
                  numberOfLatestSubmissions={showLatest ? enrollment.program.discountQuantityBuyX ?? 0 : null}
                  submissions={enrollment.submissions}
                  filter={filter}
                />
              </Grid>
              <Grid item xs={4}>
                <Paper variant='outlined' style={{ margin: 16, paddingLeft: 24, paddingRight: 24, paddingBottom: 16 }}>
                  <Box>
                    <Typography
                      variant='h5'
                      style={{
                        textAlign: 'center',
                        flex: 1,
                        fontWeight: 'bold',
                        paddingTop: 18,
                      }}
                    >
                      Patient Information
                    </Typography>
                    <Typography
                      style={{
                        textAlign: 'center',
                        flex: 1,
                        paddingTop: 26,
                        paddingBottom: 24,
                      }}
                    >
                      Manage patient information from this dashboard
                    </Typography>
                  </Box>
                  <form onSubmit={patientFormik.handleSubmit}>
                    <Box>
                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                          <TextField
                            id='chineseName'
                            label='Chinese Name'
                            type='text'
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            variant='filled'
                            value={patientFormik.values.chineseName}
                            error={patientFormik.touched.chineseName && Boolean(patientFormik.errors.chineseName)}
                            onChange={patientFormik.handleChange}
                            helperText={patientFormik.touched.chineseName && patientFormik.errors.chineseName}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            id='englishName'
                            label='English Name'
                            InputLabelProps={{ shrink: true }}
                            type='text'
                            fullWidth
                            variant='filled'
                            value={patientFormik.values.englishName}
                            error={patientFormik.touched.englishName && Boolean(patientFormik.errors.englishName)}
                            onChange={patientFormik.handleChange}
                            helperText={patientFormik.touched.englishName && patientFormik.errors.englishName}
                          />
                        </Grid>
                      </Grid>
                      <Grid container mt={1} spacing={1}>
                        <Grid item xs={12}>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            id='id'
                            label='Patient ID'
                            type='text'
                            disabled
                            fullWidth
                            variant='filled'
                            value={patientFormik.values.id}
                            error={patientFormik.touched.id && Boolean(patientFormik.errors.id)}
                            onChange={patientFormik.handleChange}
                            helperText={patientFormik.touched.id && patientFormik.errors.id}
                          />
                        </Grid>
                      </Grid>
                      <Grid container mt={1} spacing={1}>
                        <Grid item xs={12}>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            id='address'
                            label='Address'
                            type='text'
                            fullWidth
                            variant='filled'
                            value={patientFormik.values.address}
                            error={patientFormik.touched.address && Boolean(patientFormik.errors.address)}
                            onChange={patientFormik.handleChange}
                            helperText={patientFormik.touched.address && patientFormik.errors.address}
                          />
                        </Grid>
                      </Grid>
                      <Grid container mt={1} spacing={1}>
                        <Grid item xs={6}>
                          <TextField
                            id='hongKongId'
                            label='Hong Kong ID'
                            type='text'
                            fullWidth
                            variant='filled'
                            InputLabelProps={{ shrink: true }}
                            value={patientFormik.values.hongKongId}
                            error={patientFormik.touched.hongKongId && Boolean(patientFormik.errors.hongKongId)}
                            onChange={patientFormik.handleChange}
                            helperText={patientFormik.touched.hongKongId && patientFormik.errors.hongKongId}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            id='memberId'
                            label='Member ID'
                            type='text'
                            fullWidth
                            variant='filled'
                            InputLabelProps={{ shrink: true }}
                            value={patientFormik.values.memberId}
                            error={patientFormik.touched.memberId && Boolean(patientFormik.errors.memberId)}
                            onChange={patientFormik.handleChange}
                            helperText={patientFormik.touched.memberId && patientFormik.errors.memberId}
                          />
                        </Grid>
                      </Grid>
                      <Grid container mt={1} spacing={1}>
                        <Grid item xs={6}>
                          <TextField
                            id='email'
                            label='E-mail Address'
                            InputLabelProps={{ shrink: true }}
                            type='text'
                            fullWidth
                            variant='filled'
                            value={patientFormik.values.email}
                            error={patientFormik.touched.email && Boolean(patientFormik.errors.email)}
                            onChange={patientFormik.handleChange}
                            helperText={patientFormik.touched.email && patientFormik.errors.email}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            id='englishName'
                            label='Phone Number'
                            disabled
                            type='text'
                            fullWidth
                            variant='filled'
                            InputLabelProps={{ shrink: true }}
                            value={remove852fromPhoneNumber(enrollment.patient.identity.username)}
                          />
                        </Grid>
                      </Grid>
                      <Grid container mt={1} spacing={1}>
                        <Grid item xs={12}>
                          <TextField
                            id='comment'
                            label='Comment'
                            type='text'
                            fullWidth
                            rows={4}
                            multiline
                            variant='filled'
                            InputLabelProps={{ shrink: true }}
                            value={patientFormik.values.comment}
                            error={patientFormik.touched.comment && Boolean(patientFormik.errors.comment)}
                            onChange={patientFormik.handleChange}
                            helperText={patientFormik.touched.comment && patientFormik.errors.comment}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <Button
                      type={'submit'}

                      sx={{ marginTop: 2, marginRight: 1 }}
                      startIcon={<Save />}
                      variant={'contained'}
                      color='primary'
                    >
                      Save patient data
                    </Button>

                    <Button
                      onClick={patientFormik.handleReset}
                      sx={{ marginTop: 2 }}
                      startIcon={<Close />}
                      variant={'contained'}
                      color='primary'
                    >
                      Cancel
                    </Button>
                  </form>
                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper variant='outlined' style={{ margin: 16, paddingLeft: 24, paddingRight: 24, paddingBottom: 16 }}>
                  {((enrollment.transactionStatus !== "ENROLLMENT_BASED" && enrollment.transactionStatus !== "PICKUP_DECLINED")) && (
                    <>
                      <Box>
                        <Typography
                          variant='h5'
                          style={{
                            textAlign: 'center',
                            flex: 1,
                            fontWeight: 'bold',
                            paddingTop: 18,
                          }}
                        >
                          Order Information
                        </Typography>
                        <Typography
                          style={{
                            textAlign: 'center',
                            flex: 1,
                            paddingTop: 6,
                            paddingBottom: 24,
                          }}
                        >
                          Manage order information from this dashboard
                        </Typography>
                      </Box>
                      <form style={{ paddingBottom: 24 }} onSubmit={orderFormik.handleSubmit}>
                        <Box>
                          <Grid container mt={1} spacing={1}>
                            <Grid item xs={12}>
                              <TextField
                                InputLabelProps={{ shrink: true }}
                                id='brand'
                                label='Medicine Name'
                                type='text'
                                disabled
                                fullWidth
                                variant='filled'
                                value={orderFormik.values.brand}
                                error={orderFormik.touched.brand && Boolean(orderFormik.errors.brand)}
                                onChange={orderFormik.handleChange}
                                helperText={orderFormik.touched.brand && orderFormik.errors.brand}
                              />
                            </Grid>
                          </Grid>
                          <Grid container mt={1} spacing={1}>
                            <Grid item xs={12}>
                              <FormControl variant={'filled'} fullWidth>
                                <InputLabel id={'group-label'}>Dosage</InputLabel>
                                <Select
                                  id='dosage'
                                  name='dosage'
                                  value={orderFormik.values.dosage}
                                  label='dosage'
                                  onChange={orderFormik.handleChange}
                                >
                                  {enrollment.program.availableDosages?.map((dosage, index) => (
                                    <MenuItem key={index} value={dosage}>
                                      {dosage}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>
                          </Grid>
                          <Grid container mt={1} spacing={1}>
                            <Grid item xs={12}>
                              <TextField
                                id='pharmacyNetwork'
                                label='Pharmacy Network'
                                name='pharmacyNetwork'
                                type='text'
                                disabled
                                fullWidth
                                variant='filled'
                                InputLabelProps={{ shrink: true }}
                                value={orderFormik.values.pharmacyNetwork}
                                error={orderFormik.touched.pharmacyNetwork && Boolean(orderFormik.errors.pharmacyNetwork)}
                                onChange={orderFormik.handleChange}
                                helperText={orderFormik.touched.pharmacyNetwork && orderFormik.errors.pharmacyNetwork}
                              />
                            </Grid>
                          </Grid>
                          <Grid container mt={1} spacing={1}>
                            <Grid item xs={12}>
                              <FormControl variant={'filled'} fullWidth>
                                <InputLabel id={'group-label'}>District</InputLabel>
                                <Select
                                  id='district'
                                  name='district'
                                  value={orderFormik.values.district}
                                  label='region'
                                  onChange={(e) => {
                                    orderFormik.setFieldValue('address', undefined)
                                    orderFormik.handleChange(e)
                                  }}
                                >
                                  {districts.map((district, index) => (
                                    <MenuItem key={index} value={district}>
                                      {district}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>
                          </Grid>
                          <Grid container mt={1} spacing={1}>
                            <Grid item xs={12}>
                              <FormControl variant={'filled'} fullWidth>
                                <InputLabel id={'group-label'}>Pharmacy Address</InputLabel>
                                <Select
                                  error={orderFormik.touched.address && Boolean(orderFormik.errors.address)}
                                  id='address'
                                  name='address'
                                  value={orderFormik.values.address}
                                  label='region'
                                  onChange={orderFormik.handleChange}
                                >
                                  {pharmaciesUnpaged
                                    .filter((p) => (p.region === orderFormik.values.district || !orderFormik.values.district) && p.pharmacyNet === orderFormik.values.pharmacyNetwork)
                                    .filter((p) => p.identity.isActive)
                                    .filter((p) => p.stampCollectionPrograms.map(p => p.id).includes(enrollment.program.id))
                                    .map((pharmacy, index) => (
                                      <MenuItem key={index} value={pharmacy.addressChinese}>
                                        {pharmacy.nameChinese} - {pharmacy.addressChinese}
                                      </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText style={{ color: 'red' }}>{orderFormik.errors.address}</FormHelperText>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Box>
                        <Button
                          type={'submit'}
                          disabled={!orderFormik.values.dosage || !orderFormik.values.address || !orderFormik.values.district}
                          sx={{ marginTop: 2, marginRight: 1 }}
                          startIcon={<Save />}
                          variant={'contained'}
                          color='primary'
                        >
                          Save order information
                        </Button>
                        <Button
                          onClick={orderFormik.handleReset}
                          sx={{ marginTop: 2 }}
                          startIcon={<Close />}
                          variant={'contained'}
                          color='primary'
                        >
                          Cancel
                        </Button>
                      </form>
                    </>
                  )}
                  <Box>
                    <Typography
                      variant='h5'
                      style={{
                        textAlign: 'center',
                        flex: 1,
                        fontWeight: 'bold',
                        paddingTop: 18,
                      }}
                    >
                      Active Transaction Status
                    </Typography>
                    <Typography
                      style={{
                        textAlign: 'center',
                        flex: 1,
                        paddingTop: 6,
                        paddingBottom: 24,
                      }}
                    >
                      Manage transaction information from this dashboard
                    </Typography>
                  </Box>
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography
                        style={{
                          textAlign: 'center',
                          fontWeight: 'bold',
                        }}
                      >
                        Enrolled:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        style={{
                          textAlign: 'center',
                        }}
                      >
                        {dayjs(enrollment.dateEnrolled).tz('Asia/Hong_Kong').format('DD-MM-YYYY')}
                      </Typography>
                    </Grid>
                  </Grid>
                  <hr />
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography
                        style={{
                          textAlign: 'center',
                          fontWeight: 'bold',
                        }}
                      >
                        Status:{' '}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        style={{
                          textAlign: 'center',
                        }}
                      >
                        {mapTransactionStatusToMessage(enrollment)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <hr />
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography
                        style={{
                          textAlign: 'center',
                          fontWeight: 'bold',
                        }}
                      >
                        Date:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        style={{
                          textAlign: 'center',
                        }}
                      >
                        {dayjs(enrollment.statusChanged).tz('Asia/Hong_Kong').format('DD-MM-YYYY')}
                      </Typography>
                    </Grid>
                  </Grid>
                  <RoleAccessProvider required={[Roles.MANAGE_PATIENT_STAMP_COLLECITON_ENROLLMENTS]}>
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-evenly',
                        flexWrap: 'wrap',
                        marginTop: 16,
                      }}
                    >
                      <Button
                        onClick={() => {
                          updateEnrollmentStatus({
                            patientId: enrollment.patient.id,
                            programId: enrollment.program.id,
                            transactionStatus: 'PICKUP_APPROVED',
                          }, filter)
                        }}
                        disabled={enrollment.transactionStatus !== 'PICKUP_ARRANGED'}
                        style={{ alignSelf: 'center', margin: 8, width: 180 }}
                        variant='contained'
                      >
                        Mark approved
                      </Button>
                      <Button
                        onClick={() => {
                          updateEnrollmentStatus({
                            patientId: enrollment.patient.id,
                            programId: enrollment.program.id,
                            transactionStatus: 'PICKUP_DECLINED',
                          }, filter)
                        }}
                        disabled={enrollment.transactionStatus !== 'PICKUP_ARRANGED'}
                        style={{ alignSelf: 'center', margin: 8, width: 180 }}
                        variant='contained'
                      >
                        Mark declined
                      </Button>
                      <Button
                        onClick={() => {
                          updateEnrollmentStatus({
                            patientId: enrollment.patient.id,
                            programId: enrollment.program.id,
                            transactionStatus: 'SHIPPED',
                          }, filter)
                        }}
                        disabled={enrollment.transactionStatus !== 'PICKUP_APPROVED'}
                        style={{ alignSelf: 'center', margin: 8, width: 180 }}
                        variant='contained'
                      >
                        Mark shipped
                      </Button>
                      <Button
                        onClick={() => {
                          updateEnrollmentStatus({
                            patientId: enrollment.patient.id,
                            programId: enrollment.program.id,
                            transactionStatus: 'DELIVERED',
                          }, filter)
                        }}
                        disabled={enrollment.transactionStatus !== 'SHIPPED'}
                        style={{ alignSelf: 'center', margin: 8, width: 180 }}
                        variant='contained'
                      >
                        Mark delivered
                      </Button>
                      {
                      (enrollment.transactionStatus === 'COUPON_PROPOSED' && dayjs(enrollment.arrangePickUpBy.slice(0, 10), 'YYYY-MM-DD').isBefore(dayjs())) || 
                      (enrollment.transactionStatus === 'DELIVERED' && dayjs(enrollment.redeemBy.slice(0, 10), 'YYYY-MM-DD').isBefore(dayjs()))
                      ? <Button
                        onClick={() => {
                          updateEnrollmentStatus({
                            patientId: enrollment.patient.id,
                            programId: enrollment.program.id,
                            transactionStatus: 'DECLINE_EXPIRED',
                          }, filter)
                        }}
                        style={{ alignSelf: 'center', margin: 8, width: 180 }}
                        variant='contained'
                      >
                        Decline if expired
                      </Button> : null}
                    </Box>
                  </RoleAccessProvider>
                </Paper>
              </Grid>
            </Grid>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

function getDistricts(pharmacies: Pharmacy[], orderFormik: FormikProps<OrderFormikProps>): string[] {
  let districts: string[] = []

  pharmacies.forEach((pharmacy) => {
    if (pharmacy.pharmacyNet === orderFormik.values.pharmacyNetwork) {
      if (!districts.includes(pharmacy.region)) {
        districts.push(pharmacy.region)
      }
    }
  })

  return districts
}
export default EnrollmentsTableRowComponent
