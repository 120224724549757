import * as request from "./helpers/request";
import {IdentityUpdateData} from "./types";

export async function updateIdentity(identity : IdentityUpdateData) {
    return await request.call({
        url: `${process.env.REACT_APP_HOST_BASE_URL}/api/identity/${identity.id}`,
        method: "PUT",
        data: identity
    });
}

export async function getIdentityRoles() {
    return await request.call({
        url: `${process.env.REACT_APP_HOST_BASE_URL}/api/identity/roles`,
        method: "GET"
    });
}