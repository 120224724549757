import { ModalAction } from '../modalActionTypes'
import { ActionTypes } from '../types'
import type { Modal } from '../../api/types'

export function setOpenModal(modalData: Modal): ModalAction {
  return {
    type: ActionTypes.OPEN_MODAL,
    payload: modalData,
  }
}
