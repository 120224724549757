import { FC, Fragment } from "react";
import { Program, ProgramFilter } from "../core/api/types";
import { IconButton, TableCell, TableRow } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Roles } from "../constants/roles";
import { RoleAccessProvider } from "../providers";
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

interface Props {
  program: Program;
  filter?: ProgramFilter
}

const ProgramsTableRowComponent: FC<Props> = ({ program, filter }) => {
  const navigate = useNavigate();

  return (
    <Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        hover
        key={program.id}
      >
        <TableCell style={{ width: "5%" }}>
          <RoleAccessProvider required={[Roles.MANAGE_PROGRAMS]}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => navigate(`/programs/${program.id}`, { state: { filter } })}
            >
              <InfoOutlined />
            </IconButton>
          </RoleAccessProvider>
        </TableCell>
        <TableCell style={{ textAlign: "center" }}>{program.name}</TableCell>
        <TableCell style={{ textAlign: "center" }}>{program.type}</TableCell>
        <TableCell style={{ textAlign: "center" }}>{program.brand}</TableCell>
        <TableCell style={{ textAlign: "center" }}>
        {program.startDate ? dayjs(program.startDate).tz("Asia/Hong_Kong").tz('Asia/Hong_Kong').format('DD-MM-YYYY') : "—"}
        </TableCell>
        <TableCell style={{ textAlign: "center" }}>
          {program.endDate ? dayjs(program.endDate).tz("Asia/Hong_Kong").tz('Asia/Hong_Kong').format('DD-MM-YYYY') : "—"}
        </TableCell>
        <TableCell style={{ textAlign: "center" }}>
          {program.active ? "Active" : "Inactive"}
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

export default ProgramsTableRowComponent;
