import { Dispatch } from "@reduxjs/toolkit";
import { Action } from "../core/actions";
import { State } from "../core/reducers";
import { connect } from "react-redux";
import BrandsTableRowComponent from "./BrandsTableRowComponent";
import { deactivateBrand } from "../core/actions/creators/brandActionCreators";

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  deactivateBrand: (id: string) => dispatch(deactivateBrand(id)),
});

const mapStateToProps = (state: State) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BrandsTableRowComponent);
