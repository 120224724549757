import { Dispatch } from "@reduxjs/toolkit";
import { Action } from "../core/actions";
import { State } from "../core/reducers";
import { connect } from "react-redux";
import { PharmacyPageComponent } from "./PharmacyPageComponent";
import { getPharmacies } from "../core/actions/creators/pharmacyActionCreators";
import { PageRequest, PharmacyFilter } from "../core/api/types";

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getPharmacies: (pageRequest: PageRequest, filter?: PharmacyFilter) =>
    dispatch(getPharmacies(pageRequest, filter)),
});

const mapStateToProps = (state: State) => ({
  pharmacies: state.pharmacies,
  regions: state.regions,
  programNames: state.programNames
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PharmacyPageComponent);
