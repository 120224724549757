import { PatientFilter, Submission } from "../../api/types";
import {
  ApproveSubmssionAction,
  ApproveSubmssionRequestAction,
  ApproveSubmssionSuccessAction,
  ApproveSubmssionFailureAction,
  DeclineSubmssionAction,
  DeclineSubmssionRequestAction,
  DeclineSubmssionSuccessAction,
  DeclineSubmssionFailureAction
} from "../submissionActionTypes";
import { ActionTypes } from "../types";


export function approveSubmission(submission: Submission, filter?: PatientFilter): ApproveSubmssionAction {
  return {
    type: ActionTypes.APPROVE_SUBMISSION,
    payload: submission,
    filter
  };
}

export function approveSubmissionRequest(): ApproveSubmssionRequestAction {
  return {
    type: ActionTypes.APPROVE_SUBMISSION_REQUEST,
  };
}

export function approveSubmissionSuccess(): ApproveSubmssionSuccessAction {
  return {
    type: ActionTypes.APPROVE_SUBMISSION_SUCCESS,
  };
}

export function approveSubmissionFailure(
  error: Error | string
): ApproveSubmssionFailureAction {
  return {
    type: ActionTypes.APPROVE_SUBMISSION_FAILURE,
    error,
  };
}

export function declineSubmission(submission: Submission, filter?: PatientFilter): DeclineSubmssionAction {
    return {
      type: ActionTypes.DECLINE_SUBMISSION,
      payload: submission,
      filter
    };
  }
  
  export function declineSubmissionRequest(): DeclineSubmssionRequestAction {
    return {
      type: ActionTypes.DECLINE_SUBMISSION_REQUEST,
    };
  }
  
  export function declineSubmissionSuccess(): DeclineSubmssionSuccessAction {
    return {
      type: ActionTypes.DECLINE_SUBMISSION_SUCCESS,
    };
  }
  
  export function declineSubmissionFailure(
    error: Error | string
  ): DeclineSubmssionFailureAction {
    return {
      type: ActionTypes.DECLINE_SUBMISSION_FAILURE,
      error,
    };
  }
  
