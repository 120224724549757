import { PagedTracker, User } from '../api/types'
import { ActionTypes } from '../actions/types'
import { toast } from 'react-toastify'
import POSITION = toast.POSITION
import 'react-toastify/dist/ReactToastify.css'
import { CreateUserFailureAction, UserAction } from '../actions/usersActionTypes'
import { IdentityAction } from '../actions/identityActionTypes'

const initialState: PagedTracker<User> = {
  page: 0,
  size: 5,
  data: {
    totalItems: 0,
    pages: 0,
    content: [],
  },
  error:true
}

const reducer = (state: PagedTracker<User> = initialState, action: UserAction | IdentityAction|CreateUserFailureAction): PagedTracker<User> => {
  switch (action.type) {
    case ActionTypes.GET_USERS:
      return { ...state, page: action.payload.page, size: action.payload.size,error:false }
    case ActionTypes.GET_USERS_REQUEST:
      return state
    case ActionTypes.GET_USERS_SUCCESS:
      toast.dismiss()
      return { page: state.page, size: state.size, data: action.payload,error:false  }
    case ActionTypes.GET_USERS_FAILURE:
      toast.dismiss()
      toast.error('There was an error fetching users', {
        position: POSITION.BOTTOM_CENTER,
      })
      return state
    case ActionTypes.CREATE_USER_REQUEST:
      toast.dismiss()
      state.error = undefined
      console.log("CREATE_USER_REQUEST",state);
      
      return state
    case ActionTypes.CREATE_USER_SUCCESS:
      toast.dismiss()
      toast.success('User saved successfully', {
        position: POSITION.BOTTOM_CENTER,
      })
      state.error = undefined
      return state
    case ActionTypes.CREATE_USER_FAILURE:
      toast.dismiss()
      console.log(action);
      toast.error(action.error as string, {
        position: POSITION.BOTTOM_CENTER,
      })
      state.error = true
      console.log("CREATE_USER_FAILURE",state);
      return {
        ...state,
        error: true
      };
    case ActionTypes.UPDATE_USER_SUCCESS:
      toast.dismiss()
      toast.success('User saved successfully', {
        position: POSITION.BOTTOM_CENTER,
      })
      return state
    case ActionTypes.UPDATE_IDENTITY_SUCCESS:
      toast.dismiss()
      toast.success('User saved successfully', {
        position: POSITION.BOTTOM_CENTER,
      })
      return state
    default:
      return state
  }
}

export default reducer
