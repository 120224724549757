import { GetBrandNamesAction } from './../actions/brandActionTypes';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { ActionTypes } from '../actions/types'
import { brandActionCreators } from '../actions/creators'
import * as API from '../api/brands'
import { AxiosError } from 'axios'
import { DeactivateBrandAction, GetBrandAction, GetBrandDataAction, SaveBrandAction, UpdateBrandAction } from '../actions/brandActionTypes'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import POSITION = toast.POSITION
import { store } from '../store'

function* onGetBrandNames(getBrandNamesAction: GetBrandNamesAction) {
  yield put(brandActionCreators.getBrandNamesRequest())

  try {
    const { data } = yield call(API.getBrandNames);
    yield put(brandActionCreators.getBrandNamesSuccess(data))
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      yield put(brandActionCreators.getBrandNamesFailure(error.response.data.error))
    }
  }
}


function* onListBrands(getBrandAction: GetBrandAction) {
  yield put(brandActionCreators.getBrandsRequest())

  try {
    const { data } = yield call(API.getBrands, getBrandAction.payload)
    yield put(brandActionCreators.getBrandsSuccess(data))
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      yield put(brandActionCreators.getBrandsFailure(error.response.data.error))
    }
  }
}

function* onGetBrandData(getBrandData: GetBrandDataAction) {
  yield put(brandActionCreators.getBrandDataRequest())

  try {
    const { data } = yield call(API.getBrandData, getBrandData.payload)
    yield put(brandActionCreators.getBrandDataSuccess(data))
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      yield put(brandActionCreators.getBrandDataFailure(error.response.data.error))
    }
  }
}

function* onDeactivateBrand(deactivateBrand: DeactivateBrandAction) {
  yield put(brandActionCreators.deactivateBrandRequest())

  try {
    yield call(API.deactiveBrand, deactivateBrand.payload)
    yield put(brandActionCreators.deactivateBrandSuccess())
    yield put(brandActionCreators.getBrands({ page: store.getState().brands.page, size: store.getState().brands.size }))
    /*  if (deactivateBrand.payload.pageToRefresh)
      yield put(
        brandActionCreators.getBrands(deactivateBrand.payload.pageToRefresh)
      ); */
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      toast.error(error.response.data.errors[0], {
        position: POSITION.BOTTOM_CENTER,
      })
      yield put(brandActionCreators.deactivateBrandDataFailure(error.response.data.errors[0]))
    }
  }
}

function* onSaveBrand(createBrandAction: SaveBrandAction) {
  console.log(createBrandAction)
  yield put(brandActionCreators.saveBrandRequest())

  try {
    yield call(API.createBrand, createBrandAction.payload)
    yield put(brandActionCreators.saveBrandSuccess())
    yield put(brandActionCreators.getBrandNames());
    yield put(brandActionCreators.getBrands({ page: store.getState().brands.page, size: store.getState().brands.size }))
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      console.log(error.response)
      yield put(brandActionCreators.saveBrandFailure(error.response.data.errors[0]))
    }
  }
}

function* onUpdateBrand(updateBrandAction: UpdateBrandAction) {
  console.log(updateBrandAction)
  yield put(brandActionCreators.updateBrandRequest())

  try {
    yield call(API.updateBrand, updateBrandAction.payload)
    yield put(brandActionCreators.updateBrandSuccess())
    yield put(brandActionCreators.getBrandNames());
    yield put(brandActionCreators.getBrands({ page: store.getState().brands.page, size: store.getState().brands.size }))
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      console.log()
      toast.error(error.response.data.message, {
        position: POSITION.BOTTOM_CENTER,
      })
      yield put(brandActionCreators.updateBrandFailure(error.response.data.errors[0]))
    }
  }
}

function* watchBrandActions() {
  yield takeEvery(ActionTypes.GET_BRAND, onListBrands)
  yield takeEvery(ActionTypes.GET_BRAND_DATA, onGetBrandData)
  yield takeEvery(ActionTypes.DEACTIVATE_BRAND, onDeactivateBrand)
  yield takeEvery(ActionTypes.SAVE_BRAND, onSaveBrand)
  yield takeEvery(ActionTypes.UPDATE_BRAND, onUpdateBrand)
  yield takeEvery(ActionTypes.GET_BRAND_NAMES, onGetBrandNames);
}

export default function* saga() {
  yield all([fork(watchBrandActions)])
  yield put(brandActionCreators.getBrandNames());
}
