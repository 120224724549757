import {PageRequest, UploadImageData} from "./types";
import * as request from "./helpers/request";

export async function getStorageFiles(pageRequest : PageRequest) {
    return await request.call({
        url: `${process.env.REACT_APP_HOST_BASE_URL}/api/storage`,
        method: "GET",
        params: pageRequest
    });
}

export async function uploadImageFile(uploadImageData : UploadImageData) {
    const form = new FormData();
    form.append("identifier", uploadImageData.identifier);
    form.append("file", uploadImageData.file, uploadImageData.identifier);
    return await request.call({
        url: `${process.env.REACT_APP_HOST_BASE_URL}/api/storage`,
        method: "PUT",
        data: form
    }, true);
}