import { GetProgramNamesAction } from './../actions/programActionTypes'
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { ActionTypes } from '../actions/types'
import { programActionCreators } from '../actions/creators'
import * as API from '../api/programs'
import { AxiosError } from 'axios'
import { GetProgramAction, GetProgramDataAction, SaveProgramAction, UpdateProgramAction } from '../actions/programActionTypes'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import POSITION = toast.POSITION
import { store } from '../store'

function* onListPrograms(getProgramAction: GetProgramAction) {
  yield put(programActionCreators.getProgramsRequest())
  
  try {
    const { data } = yield call(API.getPrograms, getProgramAction.payload, getProgramAction.filter);
    yield put(programActionCreators.getProgramsSuccess(data))
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      yield put(programActionCreators.getProgramsFailure(error.response.data.errors[0]))
    }
  }
}

function* onGetProgramData(getProgramData: GetProgramDataAction) {
  yield put(programActionCreators.getProgramDataRequest())

  try {
    const { data } = yield call(API.getProgramData, getProgramData.payload)
    yield put(programActionCreators.getProgramDataSuccess(data))
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      yield put(programActionCreators.getProgramDataFailure(error.response.data.errors[0]))
    }
  }
}

function* onSaveProgram(createProgramAction: SaveProgramAction) {
  console.log(createProgramAction)
  yield put(programActionCreators.saveProgramRequest())

  try {
    yield call(API.createProgram, createProgramAction.payload)
    yield put(programActionCreators.saveProgramSuccess())
    yield put(programActionCreators.getPrograms({ page: store.getState().programs.page, size: store.getState().programs.size }, createProgramAction.filter))
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      console.log(error.response)
      yield put(programActionCreators.saveProgramFailure(error.response.data.errors[0]))
    }
  }
}

function* onUpdateProgram(updateProgramAction: UpdateProgramAction) {
  console.log(updateProgramAction)
  yield put(programActionCreators.updateProgramRequest())

  try {
    yield call(API.updateProgram, updateProgramAction.payload)
    yield put(programActionCreators.updateProgramSuccess())
    yield put(programActionCreators.getPrograms({ page: store.getState().programs.page, size: store.getState().programs.size }, updateProgramAction.filter))
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      console.log()
      toast.error(error.response.data.message, {
        position: POSITION.BOTTOM_CENTER,
      })
      yield put(programActionCreators.updateProgramFailure(error.response.data.errors[0]))
    }
  }
}

function* onGetProgramNames(event: GetProgramNamesAction) {
  yield put(programActionCreators.getProgramNamesRequest())

  try {
    const { data } = yield call(API.getProgramNames)
    yield put(programActionCreators.getProgramNamesSuccess(data))
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      yield put(programActionCreators.getProgramNamesFailure(error.response.data.errors[0]))
    }
  }
}

function* watchProgramActions() {
  yield takeEvery(ActionTypes.GET_PROGRAM, onListPrograms)
  yield takeEvery(ActionTypes.GET_PROGRAM_DATA, onGetProgramData)
  yield takeEvery(ActionTypes.SAVE_PROGRAM, onSaveProgram)
  yield takeEvery(ActionTypes.UPDATE_PROGRAM, onUpdateProgram)
  yield takeEvery(ActionTypes.GET_PROGRAM_NAMES, onGetProgramNames)
}

export default function* saga() {
  yield all([fork(watchProgramActions)])
}

// search: () => dispatch({ type: "GET_PROGRAM" })
/*
  component {
    props.search, props.results
    <div>
      {props.results}
    </div>
  }
*/