import { Dispatch } from "@reduxjs/toolkit";
import { Action } from "../core/actions";
import { State } from "../core/reducers";
import { connect } from "react-redux";
import StorageGalleryComponent from "./StorageGalleryComponent";
import {getStorageFiles, uploadImage} from "../core/actions/creators/storageActionCreators";
import {PageRequest, UploadImageData} from "../core/api/types";

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    getStorageFiles: ({ page, size } : PageRequest) => dispatch(getStorageFiles({ page, size })),
    uploadImage: (uploadImageData : UploadImageData) => dispatch(uploadImage(uploadImageData))
});

const mapStateToProps = (state: State) => ({
    storage: state.storage
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StorageGalleryComponent);
