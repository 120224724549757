import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { store } from "../../store";
import { refreshTokens } from "../authentication";
import {
  logInRestore,
  logOut,
} from "../../actions/creators/authActionCreators";

export const call = async (config: AxiosRequestConfig = {}, isMultipart = false) => {
  const tokens = store.getState().auth;

  if (tokens == null) {
    throw new Error("Session tokens not provided");
  }

  config.headers = {
    Authorization: `Bearer ${tokens.accessToken}`,
    "Content-Type": isMultipart ? "multipart/form-data" : "application/json"
  };

  try {
    return await axios(config);
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      if (error.response.status === 401) {
        try {
          let newAccessToken = await refreshTokens(tokens.refreshToken!);

          localStorage.setItem(
            "auth.tokens",
            JSON.stringify({
              accessToken: newAccessToken.accessToken,
              refreshToken: tokens.refreshToken,
            })
          );
          store.dispatch(logInRestore());
          config.headers = {
            Authorization: `Bearer ${newAccessToken.accessToken}`,
            "Content-Type": isMultipart ? "multipart/form-data" : "application/json"
          };
          return await axios(config);
        } catch (error) {
          if (error instanceof AxiosError && error.response) {
            if (error.response.status === 401) {
              store.dispatch(logOut());
            } else throw error;
          }
        }
      } else throw error;
    }
  }
};
