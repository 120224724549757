import { PharmacyFilter } from './../../api/types/index';
import { GetRegionsAction, GetRegionsRequestAction, GetRegionsSuccessAction, GetRegionsFailureAction, GetAllPharmaciesAction, GetAllPharmaciesRequestAction, GetAllPharmaciesSuccessAction, GetAllPharmaciesFailureAction } from './../pharmacyActionTypes';
import { Paged, PageRequest, Pharmacy, PharmacyCreation, PharmacyUpdate, Region } from "../../api/types";
import {
  GetPharmacyAction,
  GetPharmacyDataAction,
  GetPharmacyDataFailureAction,
  GetPharmacyDataRequestAction,
  GetPharmacyDataSuccessAction,
  GetPharmacyFailureAction,
  GetPharmacyRequestAction,
  GetPharmacySuccessAction,
  SavePharmacyAction,
  SavePharmacyFailureAction,
  SavePharmacyRequestAction,
  SavePharmacySuccessAction,
  UpdatePharmacyAction,
  UpdatePharmacyRequestAction,
  UpdatePharmacySuccessAction,
  UpdatePharmacyFailureAction,
} from "../pharmacyActionTypes";
import { ActionTypes } from "../types";

export function getPharmacies(pageRequest: PageRequest, filter? : PharmacyFilter): GetPharmacyAction {
  return {
    type: ActionTypes.GET_PHARMACY,
    payload: pageRequest,
    filter: filter ?? <PharmacyFilter>{}
  };
}

export function getPharmaciesRequest(): GetPharmacyRequestAction {
  return {
    type: ActionTypes.GET_PHARMACY_REQUEST,
  };
}

export function getPharmaciesSuccess(
  pharmacies: Paged<Pharmacy>
): GetPharmacySuccessAction {
  return {
    type: ActionTypes.GET_PHARMACY_SUCCESS,
    payload: pharmacies,
  };
}

export function getPharmaciesFailure(
  error: Error | string
): GetPharmacyFailureAction {
  return {
    type: ActionTypes.GET_PHARMACY_FAILURE,
    error,
  };
}

export function getPharmacyData(id: string): GetPharmacyDataAction {
  return {
    type: ActionTypes.GET_PHARMACY_DATA,
    payload: id,
  };
}

export function getPharmacyDataRequest(): GetPharmacyDataRequestAction {
  return {
    type: ActionTypes.GET_PHARMACY_DATA_REQUEST,
  };
}

export function getPharmacyDataSuccess(
  pharmacy: Pharmacy
): GetPharmacyDataSuccessAction {
  return {
    type: ActionTypes.GET_PHARMACY_DATA_SUCCESS,
    payload: pharmacy,
  };
}

export function getPharmacyDataFailure(
  error: Error | string
): GetPharmacyDataFailureAction {
  return {
    type: ActionTypes.GET_PHARMACY_DATA_FAILURE,
    error,
  };
}

export function savePharmacy(
  pharmacyCreation: PharmacyCreation, 
  filter?: PharmacyFilter
): SavePharmacyAction {
  return {
    type: ActionTypes.SAVE_PHARMACY,
    payload: pharmacyCreation,
    filter
  };
}

export function savePharmacyRequest(): SavePharmacyRequestAction {
  return {
    type: ActionTypes.SAVE_PHARMACY_REQUEST,
  };
}

export function savePharmacySuccess(): SavePharmacySuccessAction {
  return {
    type: ActionTypes.SAVE_PHARMACY_SUCCESS,
  };
}

export function savePharmacyFailure(
  error: Error | string
): SavePharmacyFailureAction {
  return {
    type: ActionTypes.SAVE_PHARMACY_FAILURE,
    error,
  };
}

export function updatePharmacy(pharmacy: PharmacyUpdate): UpdatePharmacyAction {
  return {
    type: ActionTypes.UPDATE_PHARMACY,
    payload: pharmacy,
  };
}

export function updatePharmacyRequest(): UpdatePharmacyRequestAction {
  return {
    type: ActionTypes.UPDATE_PHARMACY_REQUEST,
  };
}

export function updatePharmacySuccess(): UpdatePharmacySuccessAction {
  return {
    type: ActionTypes.UPDATE_PHARMACY_SUCCESS,
  };
}

export function updatePharmacyFailure(
  error: Error | string
): UpdatePharmacyFailureAction {
  return {
    type: ActionTypes.UPDATE_PHARMACY_FAILURE,
    error,
  };
}

export function getRegions() : GetRegionsAction {
  return {
    type: ActionTypes.GET_REGIONS,
  };
}

export function getRegionsRequest() : GetRegionsRequestAction {
  return {
    type: ActionTypes.GET_REGIONS_REQUEST,
  };
}

export function getRegionsSuccess(regions: Region[]) : GetRegionsSuccessAction {
  return {
    type: ActionTypes.GET_REGIONS_SUCCESS,
    payload: regions,
  }
}

export function getRegionsFailure(error: Error | string) : GetRegionsFailureAction {
  return {
    type: ActionTypes.GET_REGIONS_FAILURE,
    error,
  }
}

export function getAllPharmacies() : GetAllPharmaciesAction {
  return {
    type: ActionTypes.GET_ALL_PHARMACIES,
  };
}

export function getAllPharmaciesRequest() : GetAllPharmaciesRequestAction {
  return {
    type: ActionTypes.GET_ALL_PHARMACIES_REQUEST,
  };
}

export function getAllPharmaciesSuccess(pharmacies: Pharmacy[]) : GetAllPharmaciesSuccessAction {
  return {
    type: ActionTypes.GET_ALL_PHARMACIES_SUCCESS,
    payload: pharmacies,
  }
}

export function getAllPharmaciesFailure(error: Error | string) : GetAllPharmaciesFailureAction {
  return {
    type: ActionTypes.GET_ALL_PHARMACIES_FAILURE,
    error,
  }
}