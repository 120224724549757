import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { GetDiscountAction } from '../core/actions/discountActionTypes'
import { Paged, PageRequest, Discount, InstantDiscountFilter } from '../core/api/types'
import { Button, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from '@mui/material'
import { Check, Close, Download, Refresh } from '@mui/icons-material'
import csvDownload from 'json-to-csv-export'
import DiscountsTableRowContainer from '../components/DiscountsTableRowContainer'
import { useFormik } from 'formik'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { isRole } from '../helpers/roleChecker'

interface Props {
  getDiscounts: (pageRequest: PageRequest, filter?: InstantDiscountFilter) => GetDiscountAction
  discounts: Paged<Discount>,
  brandNames: string[],
  programNames: string[],
  roles: string[]
}

export const DiscountPageComponent: FC<Props> = ({ getDiscounts, discounts, brandNames, programNames, roles }) => {
  const [filters, setFilters] = useState<InstantDiscountFilter>();
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(5)

  const filterFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      dateFrom: null,
      dateTo: null,
      brand: null,
      program: null,
      pharmacyName: null,
      patientName: null,
      phone: null
    },
    onSubmit: (filters: InstantDiscountFilter) => {
      setPage(0);
      setFilters(filters);
    },
  })

  const tableHeaders = useMemo(
    () => [
      'Purchase Date',
      'Purchase ID',
      'Phone Number',
      'Patient Name',
      'Patient ID',
      'Brand',
      'Program',
      'Used Programs',
      'Number of boxes',
      'Discount Amount',
      'Pharmacy Id',
      'Pharmacy Name',
      'Pharmacy Net',
      'HK City District',
      'Zuellig Id',
    ],
    [],
  )


  const resetFilters = () => {
    setFilters({} as InstantDiscountFilter)
    setPage(0)
    setSize(5)
    filterFormik.resetForm({
      values: {
        dateFrom: null,
        dateTo: null,
        brand: null,
        program: null,
        pharmacyName: null,
        patientName: null,
        phone: null
      }
    })
  }

  const refreshPage = useCallback(() => {
    getDiscounts({ page, size }, filters)
  }, [getDiscounts, page, size])

  useEffect(() => {
    refreshPage()
  }, [refreshPage])

  useEffect(() => {
    getDiscounts({ page, size }, filters)
  }, [filters]);


  return (
    <div>
      <Grid container justifyContent='flex-end' style={{ marginBottom: 24 }}>

        <Button
          onClick={
            () => csvDownload({
              delimiter: ',', data: discounts.content.map((discount) => {

                return {
                  "Purchase Date": dayjs(discount.purchaseDate).tz('Asia/Hong_Kong').format('DD-MM-YYYY'),
                  "Purchase ID": discount.id,
                  "Patient ID": discount.patientId,
                  ...isRole('fingerbox') && {
                    "Patient Address": discount.patient.address,
                    "Patient Email Address": discount.patient.email,
                    "Patient Phone Number": discount.phoneNumber,
                    "Patient Name": discount.patientName
                  },
                  "Brand": discount.brandName,
                  "Program": discount.programName,
                  "Used Programs": discount.numberOfBoxes,
                  "Number of Boxes": discount.numberOfBoxes,
                  "Discount Amount": discount.discountAmount,
                  "Pharmacy ID": discount.pharmacyId,
                  "Pharmacy Name": discount.pharmacyName,
                  "Pharmacy Address": discount.pharmacy.addressChinese,
                  "Pharmacy Net": discount.pharmacyNet,
                  "HK City District": discount.region,
                  "Zuellig ID": discount.zuelligId,
                };
              })
            })
          }
          style={{ marginRight: 12 }}
          startIcon={<Download />}
          variant={'contained'}
        >
          Export
        </Button>
        <Button onClick={() => getDiscounts({ page, size }, filters)} startIcon={<Refresh />} variant={'contained'}>
          Refresh
        </Button>
      </Grid>
      <Grid minWidth={1800} container justifyContent='flex-end' style={{ marginBottom: 24 }}>
        <form onSubmit={filterFormik.handleSubmit} style={{ display: "flex" }}>

          <FormControl variant={'filled'} fullWidth>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label='Date From'
                value={filterFormik.values.dateFrom}
                inputFormat='YYYY-MM-DD'
                onChange={(val) => {
                  filterFormik.setFieldValue('dateFrom', val == null ? null : dayjs(val).toDate())
                }}
                renderInput={(params) => <TextField {...params} variant={'filled'} />}
              />
            </LocalizationProvider>
          </FormControl>
          <FormControl style={{ marginLeft: 8 }} variant={'filled'} fullWidth>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label='Date To'
                value={filterFormik.values.dateTo}
                inputFormat='YYYY-MM-DD'
                onChange={(val) => {
                  filterFormik.setFieldValue('dateTo', val == null ? null : dayjs(val).toDate())
                }}
                renderInput={(params) => <TextField {...params} variant={'filled'} />}
              />
            </LocalizationProvider>
          </FormControl>
          <FormControl style={{ marginLeft: 8 }} variant={'filled'} fullWidth>
            <InputLabel id={'group-label'}>Brand</InputLabel>
            <Select

              style={{ width: 200 }}
              labelId={'group-label'}
              id='brand'
              name='brand'
              value={filterFormik.values.brand}
              label='Brand'

              onChange={(val) => {
                filterFormik.setFieldValue('brand', val.target.value)
              }}
            >
              {brandNames.map((brand, index) => (
                <MenuItem key={index} value={brand}>
                  {brand}
                </MenuItem>
              ))}
            </Select>

          </FormControl>
          <FormControl variant={'filled'} fullWidth>
            <InputLabel id={'group-label'}>Program</InputLabel>
            <Select

              style={{ width: 200 }}
              labelId={'group-label'}
              id='program'
              name='program'
              value={filterFormik.values.program}
              label='Program'

              onChange={(val) => {
                filterFormik.setFieldValue('program', val.target.value)
              }}
            >
              {programNames.map((program, index) => (
                <MenuItem key={index} value={program}>
                  {program}
                </MenuItem>
              ))}
            </Select>

          </FormControl>
          <TextField
            style={{ marginLeft: 8 }}
            id='patientName'
            label='Patient Name'
            name='patientName'
            type='text'
            fullWidth
            variant='filled'
            value={filterFormik.values.patientName}
            onChange={filterFormik.handleChange}
          />
          <TextField
            style={{ marginLeft: 8 }}
            id='phone'
            label='Phone Number'
            name='phone'
            type='text'
            fullWidth
            variant='filled'
            value={filterFormik.values.phone}
            onChange={filterFormik.handleChange}
          />
          <TextField
            style={{ marginLeft: 8 }}
            id='pharmacyName'
            label='Pharmacy Name'
            name='pharmacyName'
            type='text'
            fullWidth
            variant='filled'
            value={filterFormik.values.pharmacyName}
            onChange={filterFormik.handleChange}
          />
        </form>
        <IconButton style={{ paddingLeft: 20, paddingRight: 20, marginLeft: 8 }} onClick={filterFormik.submitForm}><Check /></IconButton>
        <IconButton type='reset' style={{ paddingLeft: 20, paddingRight: 20 }} onClick={resetFilters}><Close /></IconButton>
      </Grid>
      <Paper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {tableHeaders.map((i) => (
                  <TableCell key={i} align={'center'}>
                    {i}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {discounts.content.map((discount, index) => (
                <DiscountsTableRowContainer discount={discount} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[
            { label: '5', value: 5 },
            { label: '10', value: 10 },
            { label: '15', value: 15 },
            { label: '20', value: 20 },
            { label: '50', value: 50 },
            { label: '100', value: 100 },
            { label: '500', value: 500 },
            { label: '1000', value: 1000 },
            { label: 'All', value: discounts.totalItems }
          ]}
          component='div'
          count={discounts.totalItems}
          rowsPerPage={size}
          page={page}
          onPageChange={(_, currentPage) => setPage(currentPage)}
          onRowsPerPageChange={(e) => {
            setSize(Number(e.target.value))
            setPage(0)
          }}
        />
      </Paper>
    </div>

  )
}
