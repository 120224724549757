import { Dispatch } from "@reduxjs/toolkit";
import { Action } from "../core/actions";
import { State } from "../core/reducers";
import { connect } from "react-redux";
import {
  getBrandData,
  updateBrand,
} from "../core/actions/creators/brandActionCreators";
import BrandDataPageComponent from "./BrandDataPageComponent";
import { BrandCreation } from "../core/api/types";

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getBrandData: (id: string) => dispatch(getBrandData(id)),
  updateBrand: (brand: BrandCreation) => dispatch(updateBrand(brand)),
});

const mapStateToProps = (state: State) => ({
  brand: state.brandData,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BrandDataPageComponent);
