import { Program } from '../api/types'
import { ProgramAction } from '../actions/programActionTypes'
import { ActionTypes } from '../actions/types'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import POSITION = toast.POSITION

const reducer = (state: Program | null = null, action: ProgramAction): Program | null => {
  switch (action.type) {
    case ActionTypes.GET_PROGRAM_DATA:
      return state
    case ActionTypes.GET_PROGRAM_DATA_REQUEST:
      return state
    case ActionTypes.GET_PROGRAM_DATA_SUCCESS:
      return action.payload
    case ActionTypes.GET_PROGRAM_DATA_FAILURE:
      toast.error('There was an error loading program data', {
        position: POSITION.BOTTOM_CENTER,
      })
      return state
    default:
      return state
  }
}

export default reducer
