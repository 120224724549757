import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { ActionTypes } from '../actions/types'
import { usersActionCreators } from '../actions/creators'
import * as API from '../api/users'
import { AxiosError } from 'axios'
import { CreateUserAction, GetUsersAction, ChangeUserPasswordAction, UpdateUserAction } from '../actions/usersActionTypes'

import { store } from '../store'
function* onGetUsers(getUsersAction: GetUsersAction) {
  yield put(usersActionCreators.getUsersRequest())

  try {
    const { data } = yield call(API.getUsers, getUsersAction.payload, getUsersAction.filter)
    yield put(usersActionCreators.getUsersSuccess(data))
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      yield put(usersActionCreators.getUsersFailure(error.response.data.errors[0]))
    }
  }
}

function* onUpdateUser(updateUserAction: UpdateUserAction) {
  yield put(usersActionCreators.updateUserRequest())

  try {
    yield call(API.updateUser, updateUserAction.payload)
    yield put(usersActionCreators.updateUserSuccess())
    yield put(usersActionCreators.getUsers({ page: store.getState().users.page, size: store.getState().users.size }, updateUserAction.filter))
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      yield put(usersActionCreators.updateUserFailure(error.response.data.errors[0]))
    }
  }
}

function* onCreateUser(createUserAction: CreateUserAction) {
  console.log(createUserAction)
  yield put(usersActionCreators.createUserRequest())
  try {
    yield call(API.createUser, createUserAction.payload)
    yield put(usersActionCreators.createUserSuccess())
    yield put(usersActionCreators.getUsers({ page: store.getState().users.page, size: store.getState().users.size }, createUserAction.filter))
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      if(error.response.status===409){
        console.log(error.response.data.error);
        yield put(usersActionCreators.createUserFailure(error.response.data.error))
      }else{
        console.log(error.response.data.errors[0]);
        yield put(usersActionCreators.createUserFailure(error.response.data.errors[0]))
      }
   
    }
  }
}

function* onChangePassword(changeUserPasswordAction: ChangeUserPasswordAction) {
  yield put(usersActionCreators.changeUserPasswordRequest())

  try {
    yield call(API.changeUserPassword, changeUserPasswordAction.payload)
    yield put(usersActionCreators.changeUserPasswordSuccess())
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      yield put(usersActionCreators.changeUserPasswordFailure(error.response.data.errors[0]))
    }
  }
}

function* watchUsersActions() {
  yield takeEvery(ActionTypes.GET_USERS, onGetUsers)
  yield takeEvery(ActionTypes.CREATE_USER, onCreateUser)
  yield takeEvery(ActionTypes.UPDATE_USER, onUpdateUser)
  yield takeEvery(ActionTypes.CHANGE_USER_PASSWORD, onChangePassword)
}

export default function* saga() {
  yield all([fork(watchUsersActions)])
}
