import * as yup from 'yup'

export const loginValidationSchema = yup.object({
  username: yup.string().required('E-mail or username is required'),
  password: yup.string().required('Password is required'),
})

export const userDetailsValidationSchema = yup.object({
  chineseName: yup.string().required('Chinese name is required'),
  englishName: yup.string().required('English name is required'),
  position: yup.string().required('Position name is required'),
  /* phoneNumber: yup
    .string()
    .required('Phone number is required')
    .matches(/^[0-9]{8}$/, 'Must contain 8 digits.'), */
})

export const programValidationSchema = yup.object({
  name: yup.string().required('Name is required'),
  type: yup.string().required('Type is required'),
  brand: yup.string().required('Brand is required'),
  discountQuantityBuyX: yup
    .number()
    .nullable()
    .when('type', {
      is: (value: string) => value === 'BUY_X_GET_Y',
      then: yup
        .number()
        .required('Can not be empty.')
        .transform((val) => (isNaN(val) ? undefined : val))
        .moreThan(0, 'Can not be negative.'),
    }),
  discountAmountGetY: yup
    .number()
    .nullable()
    .when('type', {
      is: (value: string) => value === 'BUY_X_GET_Y',
      then: yup
        .number()
        .nullable()
        .required('Can not be empty.')
        .transform((val) => (isNaN(val) ? undefined : val))
        .moreThan(0, 'Can not be negative.'),
    }),
  maximumQuantity: yup
    .number()
    .nullable()
    .when('type', {
      is: (value: string) => value === 'INSTANT_DISCOUNT' || value === 'BUY_X_GET_Y',
      then: yup
        .number()
        .nullable()
        .required('Can not be empty.')
        .transform((val) => (isNaN(val) ? undefined : val))
        .moreThan(0, 'Can not be negative.'),
    }),
  discountAmount: yup
    .number()
    .nullable()
    .when('type', {
      is: (value: string) => value === 'INSTANT_DISCOUNT',
      then: yup
        .number()
        .required('Can not be empty.')
        .transform((val) => (isNaN(val) ? undefined : val))
        .moreThan(0, 'Can not be negative.'),
    }),
  minimumAmount: yup
    .number()
    .nullable()
    .when('type', {
      is: (value: string) => value === 'INSTANT_DISCOUNT',
      then: yup
        .number()
        .required('Can not be empty.')
        .transform((val) => (isNaN(val) ? undefined : val))
        .moreThan(0, 'Can not be negative.'),
    }),
  startDate: yup.string().optional().nullable(),
  endDate: yup.string().optional().nullable(),

})

export const brandValidationSchema = yup.object({
  name: yup.string().required('Name is required'),
})

export const updateIdentityValidationSchema = yup.object({
  group: yup.string().required('Group is required'),
})

export const createPharamacyValidationSchema = yup.object({
  nameChinese: yup.string().required('Name is required'),
})
