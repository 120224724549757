import { connect } from 'react-redux'
import type { Dispatch } from '@reduxjs/toolkit'
import type { Action } from '../core/actions'
import type { PatientFilter, PatientUpdate } from '../core/api/types'
import { RegisteredUsersTableRowComponent } from './RegisteredUsersTableRowComponent'
import {
    activatePatient,
    deactivatePatient,
    verifyPatient,
} from '../core/actions/creators/patientActionCreators'
import { updatePatientData } from '../core/actions/creators/enrollmentActionCreators'


const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    verifyPatient: (id: string) => dispatch(verifyPatient(id)),
    activatePatient: (id: string) => dispatch(activatePatient(id)),
    deactivatePatient: (id: string) => dispatch(deactivatePatient(id)),
    updatePatientData: (patientUpdate: PatientUpdate, filter?: PatientFilter) => dispatch(updatePatientData(patientUpdate, filter)),
})

export default connect(null, mapDispatchToProps)(RegisteredUsersTableRowComponent)
