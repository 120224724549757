import {
    GetStorageFilesAction,
    GetStorageFilesFailureAction,
    GetStorageFilesRequestAction,
    GetStorageFilesSuccessAction,
    UploadImageAction,
    UploadImageActionFailure,
    UploadImageActionRequest,
    UploadImageActionSuccess
} from "../storageActionTypes";
import {ActionTypes} from "../types";
import {Paged, PageRequest, StorageFile, UploadImageData} from "../../api/types";

export function uploadImage(uploadImageData : UploadImageData) : UploadImageAction {
    return {
        type: ActionTypes.UPLOAD_IMAGE,
        payload: uploadImageData
    }
}

export function uploadImageRequest() : UploadImageActionRequest {
    return {
        type: ActionTypes.UPLOAD_IMAGE_REQUEST
    }
}

export function uploadImageSuccess() : UploadImageActionSuccess {
    return {
        type: ActionTypes.UPLOAD_IMAGE_SUCCESS
    }
}

export function uploadImageFailure(error : Error | string) : UploadImageActionFailure {
    return {
        type: ActionTypes.UPLOAD_IMAGE_FAILURE,
        error
    }
}

export function getStorageFiles(pageRequest : PageRequest) : GetStorageFilesAction {
    return {
        type: ActionTypes.GET_STORAGE_FILES,
        payload: pageRequest
    }
}

export function getStorageFilesRequest() : GetStorageFilesRequestAction {
    return {
        type: ActionTypes.GET_STORAGE_FILES_REQUEST
    }
}

export function getStorageFilesSuccess(storageFiles : Paged<StorageFile>) : GetStorageFilesSuccessAction {
    return {
        type: ActionTypes.GET_STORAGE_FILES_SUCCESS,
        payload: storageFiles
    }
}

export function getStorageFilesFailure(error : Error | string) : GetStorageFilesFailureAction {
    return {
        type: ActionTypes.GET_STORAGE_FILES_FAILURE,
        error
    }
}
