import { Dispatch } from '@reduxjs/toolkit'
import { Action } from '../core/actions'
import { State } from '../core/reducers'
import { connect } from 'react-redux'
import { DiscountPageComponent } from './DiscountPageComponent'
import { getDiscounts } from '../core/actions/creators/discountActionCreators'
import { InstantDiscountFilter, PageRequest } from "../core/api/types";

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    getDiscounts: (pageRequest : PageRequest, filter? : InstantDiscountFilter) => dispatch(getDiscounts(pageRequest, filter)),
})

const mapStateToProps = (state: State) => ({
    discounts: state.discounts,
    brandNames: state.brandNames,
    programNames: state.programNames,
    roles: state.roles
})

export default connect(mapStateToProps, mapDispatchToProps)(DiscountPageComponent)
