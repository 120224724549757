import { Dispatch } from "@reduxjs/toolkit";
import { Action } from "../core/actions";
import { State } from "../core/reducers";
import { connect } from "react-redux";
import UsersTableRowComponent from "./UsersTableRowComponent";
import { IdentityUpdateData, UserFilter, UserUpdateData } from "../core/api/types";
import { updateUser } from "../core/actions/creators/usersActionCreators";
import { updateIdentity } from "../core/actions/creators/identityActionCreators";

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  updateUser: (userDetails: UserUpdateData, filter?: UserFilter) =>
    dispatch(updateUser(userDetails, filter)),
  updateIdentity: (accessControlDetails: IdentityUpdateData, filter?: UserFilter) =>
    dispatch(updateIdentity(accessControlDetails, filter)),
});

const mapStateToProps = (state: State) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersTableRowComponent);
