import { TableCell, TableRow } from '@mui/material'
import { FC } from 'react'
import { Discount } from '../core/api/types'
import dayjs from 'dayjs'

interface Props {
  discount: Discount
}

const PharmacyDiscountTableRowComponent: FC<Props> = ({ discount }) => {
  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell style={{ textAlign: 'center' }}>{discount.id}</TableCell>
        <TableCell style={{ textAlign: 'center' }}>{discount.patientId}</TableCell>
        <TableCell style={{ textAlign: 'center' }}>{discount.brandName}</TableCell>
        <TableCell style={{ textAlign: 'center' }}>{discount.programName}</TableCell>
        <TableCell style={{ textAlign: 'center' }}>{discount.numberOfBoxes}</TableCell>
        <TableCell style={{ textAlign: 'center' }}>{discount.discountAmount}</TableCell>
        <TableCell style={{ textAlign: 'center' }}>{dayjs(discount.purchaseDate).tz("Asia/Hong_Kong").tz('Asia/Hong_Kong').format('DD-MM-YYYY')}</TableCell>
      </TableRow>
    </>
  )
}

export default PharmacyDiscountTableRowComponent
