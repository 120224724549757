import { TransactionFilter } from './../../api/types/index';
import { Paged, PageRequest, Stamp } from '../../api/types'
import {
  GetStampPickedUpAction,
  GetStampPickedUpRequestAction,
  GetStampPickedUpSuccessAction,
  GetStampPickedUpFailureAction,
  GetStampPendingAction,
  GetStampPendingRequestAction,
  GetStampPendingSuccessAction,
  GetStampPendingFailureAction,
} from '../stampActionTypes'
import { ActionTypes } from '../types'

export function getPendingByPharmacy(id: string, pageRequest: PageRequest, filter?: TransactionFilter): GetStampPendingAction {
  return {
    type: ActionTypes.GET_STAMPS_PENDING,
    payload: { id, pageRequest },
    filter: filter ?? <TransactionFilter>{}
  }
}

export function getPendingByPharmacyRequest(): GetStampPendingRequestAction {
  return {
    type: ActionTypes.GET_STAMPS_PENDING_REQUEST,
  }
}

export function getPendingByPharmacySuccess(stampsPending: Paged<Stamp>): GetStampPendingSuccessAction {
  return {
    type: ActionTypes.GET_STAMPS_PENDING_SUCCESS,
    payload: stampsPending,
  }
}

export function getPendingByPharmacyFailure(error: Error | string): GetStampPendingFailureAction {
  return {
    type: ActionTypes.GET_STAMPS_PENDING_FAILURE,
    error,
  }
}

export function getPickedUpByPharmacy(id: string, pageRequest: PageRequest, filter?: TransactionFilter): GetStampPickedUpAction {
  return {
    type: ActionTypes.GET_STAMPS_PICKED_UP,
    payload: { id, pageRequest },
    filter: filter ?? <TransactionFilter>{}
  }
}

export function getPickedUpByPharmacyRequest(): GetStampPickedUpRequestAction {
  return {
    type: ActionTypes.GET_STAMPS_PICKED_UP_REQUEST,
  }
}

export function getPickedUpByPharmacySuccess(stampsPickedUp: Paged<Stamp>): GetStampPickedUpSuccessAction {
  return {
    type: ActionTypes.GET_STAMPS_PICKED_UP_SUCCESS,
    payload: stampsPickedUp,
  }
}

export function getPickedUpByPharmacyFailure(error: Error | string): GetStampPickedUpFailureAction {
  return {
    type: ActionTypes.GET_STAMPS_PICKED_UP_FAILURE,
    error,
  }
}
