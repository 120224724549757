import { PagedTracker } from './../api/types/index'
import { Pharmacy } from '../api/types'
import { PharmacyAction } from '../actions/pharmacyActionTypes'
import { ActionTypes } from '../actions/types'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import POSITION = toast.POSITION

const reducer = (state: Pharmacy[] = [], action: PharmacyAction): Pharmacy[] => {
  switch (action.type) {
    case ActionTypes.GET_ALL_PHARMACIES_SUCCESS:
        return action.payload;
    default:
        return state;
  }
}

export default reducer;
