import { Roles } from '../constants/roles'
import { store } from '../core/store'

export const isRole = (roleToCheck: 'admin' | 'fingerbox'): boolean | undefined => {
  const roles = store.getState().roles

  switch (roleToCheck) {
    case 'admin':
      return roles.includes(Roles.MANAGE_USERS)
    case 'fingerbox':
      return roles.includes(Roles.MANAGE_PATIENT) && !roles.includes(Roles.MANAGE_USERS)
  }
}
