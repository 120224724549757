import { FC, useEffect } from 'react'
import { Button, Card, CardActions, CardContent, Checkbox, FormControl, FormControlLabel, Grid, TextField } from '@mui/material'
import { programValidationSchema } from '../core/validations'
import { CancelOutlined, Save } from '@mui/icons-material'
import { useFormik } from 'formik'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { Program, ProgramFilter } from '../core/api/types'
import { GetProgramDataAction, UpdateProgramAction } from '../core/actions/programActionTypes'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker, DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { RoleAccessProvider } from '../providers'
import { Roles } from '../constants/roles'
import { transferType } from '../core/validations/consts-and-functions'

interface Props {
  getProgramData: (id: string) => GetProgramDataAction
  updateProgram: (program: Program, filter?: ProgramFilter) => UpdateProgramAction
  program: Program | null
}

const ProgramCreationPageComponent: FC<Props> = ({ getProgramData, updateProgram, program }) => {
  const { programId } = useParams()

  const navigate = useNavigate()
  const location = useLocation()

  const goBackToProgramsPage = () => {
    navigate('/programs', {state: {from: `${location.pathname.replace(`${programId}`, 'update')}`, filter: location.state?.filter}, replace:true})
  }
  

  const { values, setFieldValue, errors, touched, handleSubmit, handleChange, handleBlur } = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...(program as Program),
      type: transferType(program?.type ?? ''),
      availableDosages: program?.availableDosages ?? [],
      availableDosagesTextual: program?.availableDosages?.join(", ") ?? '',
      termsAndConditionsUrl: program?.termsAndConditionsUrl ?? '',
      consentAgreementUrl: program?.consentAgreementUrl ?? '',
    },
    validationSchema: programValidationSchema,
    onSubmit: (program: Program & { availableDosagesTextual: string }) => {
      program.availableDosages = program.availableDosagesTextual.split(',').map(s => s.trim());
      program.startDate = dayjs(program.startDate?.toString()).format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]");
      program.endDate = dayjs(program.endDate?.toString()).format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]");
      updateProgram(program, location.state?.filter)
      goBackToProgramsPage()
    },
  })

  console.log(values)

  useEffect(() => {
    if (programId) {
      getProgramData(programId)
    }
  }, [programId, getProgramData])

  if (!program) {
    return <></>
  }

  return (
    <RoleAccessProvider required={[Roles.MANAGE_PROGRAMS]}>
      <Card sx={{ flex: 1 }}>
        <form onSubmit={handleSubmit}>
          <CardContent>
            <Grid container>
              <Grid item xs={12}>
                <Grid container>
                  <Grid padding={1} item xs={6}>
                    <TextField
                      disabled
                      id='brand'
                      label='Brand'
                      type='text'
                      fullWidth
                      variant='filled'
                      value={values.brand}
                      error={touched.brand && Boolean(errors.brand)}
                      helperText={touched.brand && errors.brand}
                    />
                  </Grid>
                  <Grid padding={1} item xs={6}>
                    <TextField
                      disabled
                      id='name'
                      label='Program name'
                      type='text'
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      variant='filled'
                      value={values.name}
                      onBlur={handleBlur}
                      error={touched.name && Boolean(errors.name)}
                      onChange={handleChange}
                      helperText={touched.name && errors.name}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid padding={1} item xs={6}>
                    <TextField
                      disabled
                      id='type'
                      label='Type'
                      type='text'
                      fullWidth
                      variant='filled'
                      value={values.type}
                      error={touched.type && Boolean(errors.type)}
                      helperText={touched.type && errors.type}
                    />
                  </Grid>
                  <Grid padding={1} item xs={3}>
                    <FormControl variant={'filled'} fullWidth>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                          minDate={Date.now()}
                          label='Start Date'
                          value={values.startDate}
                          inputFormat='YYYY-MM-DD'
                          onChange={(val) => {
                            setFieldValue('startDate', val == null ? null : val)
                          }}
                          renderInput={(params) => <TextField {...params} variant={'filled'} />}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  </Grid>
                  <Grid padding={1} item xs={3}>
                    <FormControl variant={'filled'} fullWidth>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                          minDate={Date.now()}
                          label='End Date'
                          inputFormat='YYYY-MM-DD'
                          value={values.endDate}
                          onChange={(val) => {
                            setFieldValue('endDate', val == null ? null : val)
                          }}
                          renderInput={(params) => <TextField {...params} variant={'filled'} />}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container>
                  {values.type !== 'HA_SFI' && (
                    <Grid container item xs={12}>
                      {values.type === 'BUY_X_GET_Y' && (
                        <Grid padding={1} item xs={12}>
                          <TextField
                            id='discountQuantityBuyX'
                            label='Enter number of boxes for eligibility'
                            type='text'
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            variant='filled'
                            value={values.discountQuantityBuyX}
                            error={touched.discountQuantityBuyX && Boolean(errors.discountQuantityBuyX)}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={touched.discountQuantityBuyX && errors.discountQuantityBuyX}
                          />
                        </Grid>
                      )}
                      {values.type === 'BUY_X_GET_Y' && (
                        <Grid padding={1} item xs={12}>
                          <TextField
                            id='discountAmountGetY'
                            label='Enter number of free boxes'
                            InputLabelProps={{ shrink: true }}
                            type='text'
                            fullWidth
                            variant='filled'
                            value={values.discountAmountGetY}
                            error={touched.discountAmountGetY && Boolean(errors.discountAmountGetY)}
                            onChange={handleChange}
                            helperText={touched.discountAmountGetY && errors.discountAmountGetY}
                          />
                        </Grid>
                      )}
                      {values.type === 'BUY_X_GET_Y' && (
                        <Grid padding={1} item xs={12}>
                          <TextField
                            id='maximumQuantity'
                            label='Enter maximum quantity'
                            InputLabelProps={{ shrink: true }}
                            type='text'
                            fullWidth
                            variant='filled'
                            value={values.maximumQuantity}
                            error={touched.maximumQuantity && Boolean(errors.maximumQuantity)}
                            onChange={handleChange}
                            helperText={touched.maximumQuantity && errors.maximumQuantity}
                          />
                        </Grid>
                      )}
                       {values.type === 'BUY_X_GET_Y' && (
                          <Grid padding={1} item xs={12}>
                            <TextField
                              id='availableDosagesTextual'
                              label='Available dosage for redemption'
                              InputLabelProps={{ shrink: true }}
                              type='text'
                              fullWidth
                              variant='filled'
                              value={values.availableDosagesTextual}
                              error={touched.availableDosagesTextual && Boolean(errors.availableDosagesTextual)}
                              onChange={handleChange}
                              helperText={touched.availableDosagesTextual && errors.availableDosagesTextual}
                            />
                          </Grid>
                        )}
                      {values.type === 'INSTANT_DISCOUNT' && (
                        <Grid padding={1} item xs={12}>
                          <TextField
                            id='discountAmount'
                            label='Enter discounted amount'
                            InputLabelProps={{ shrink: true }}
                            type='text'
                            fullWidth
                            variant='filled'
                            value={values.discountAmount}
                            error={touched.discountAmount && Boolean(errors.discountAmount)}
                            onChange={handleChange}
                            helperText={touched.discountAmount && errors.discountAmount}
                          />
                        </Grid>
                      )}
                      {values.type === 'INSTANT_DISCOUNT' && (
                          <Grid padding={1} item xs={8}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  id='incremental'
                                  value={values.incremental}
                                  onChange={handleChange}
                                  checked={values.incremental === true}
                                />
                              }
                              label='Incremental (method for calculation discount amount)'
                            />
                          </Grid>
                        )}
                      {values.type === 'INSTANT_DISCOUNT' && (
                        <Grid padding={1} item xs={12}>
                          <TextField
                            id='minimumAmount'
                            label='Enter minimum number of boxes'
                            InputLabelProps={{ shrink: true }}
                            type='text'
                            fullWidth
                            variant='filled'
                            value={values.minimumAmount}
                            error={touched.minimumAmount && Boolean(errors.minimumAmount)}
                            onChange={handleChange}
                            helperText={touched.minimumAmount && errors.minimumAmount}
                          />
                        </Grid>
                      )}
                      {values.type === 'INSTANT_DISCOUNT' && (
                        <Grid padding={1} item xs={12}>
                          <TextField
                            id='maximumQuantity'
                            label='Enter maximum number of boxes'
                            type='text'
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            variant='filled'
                            value={values.maximumQuantity}
                            error={touched.maximumQuantity && Boolean(errors.maximumQuantity)}
                            onChange={handleChange}
                            helperText={touched.maximumQuantity && errors.maximumQuantity}
                          />
                        </Grid>
                      )}
                      <Grid padding={1} item xs={8}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              id='oneOffDiscountOnly'
                              value={values.oneOffDiscountOnly}
                              onChange={handleChange}
                              checked={values.oneOffDiscountOnly === true}
                            />
                          }
                          label='One-off Discount Only'
                        />
                      </Grid>
                    </Grid>
                  )}
                  <Grid container item xs={12}>
                    {values.type === 'BUY_X_GET_Y' && (
                      <Grid padding={1} item xs={6}>
                        <TextField
                          id='photoSubmissionInstructionPage'
                          label='Enter instructions'
                          multiline
                          rows={6}
                          type='text'
                          fullWidth
                          inputProps={{ maxLength: 2000 }}
                          InputLabelProps={{ shrink: true }}
                          variant='filled'
                          value={values.photoSubmissionInstructionPage}
                          error={touched.photoSubmissionInstructionPage && Boolean(errors.photoSubmissionInstructionPage)}
                          onChange={handleChange}
                          helperText={touched.photoSubmissionInstructionPage && errors.photoSubmissionInstructionPage}
                        />
                      </Grid>
                    )}
                    {values.type === 'INSTANT_DISCOUNT' && (
                      <Grid padding={1} item xs={6}>
                        <TextField
                          id='howToJoin'
                          label='Enter instructions'
                          multiline
                          rows={6}
                          inputProps={{ maxLength: 2000 }}
                          InputLabelProps={{ shrink: true }}
                          type='text'
                          fullWidth
                          variant='filled'
                          value={values.howToJoin}
                          error={touched.howToJoin && Boolean(errors.howToJoin)}
                          onChange={handleChange}
                          helperText={touched.howToJoin && errors.howToJoin}
                        />
                      </Grid>
                    )}
                    {values.type === 'HA_SFI' && (
                      <Grid padding={1} item xs={6}>
                        <TextField
                          id='howToJoin'
                          label='Enter instructions'
                          multiline
                          rows={6}
                          type='text'
                          inputProps={{ maxLength: 2000 }}
                          fullWidth
                          variant='filled'
                          value={values.howToJoin}
                          InputLabelProps={{ shrink: true }}
                          error={touched.howToJoin && Boolean(errors.howToJoin)}
                          onChange={handleChange}
                          helperText={touched.howToJoin && errors.howToJoin}
                        />
                      </Grid>
                    )}
                    <Grid padding={1} item xs={6}>
                      <TextField
                        id='consentAgreementStatement'
                        label='Enter consent agreement statement'
                        multiline
                        rows={6}
                        type='text'
                        inputProps={{ maxLength: 2000 }}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        variant='filled'
                        value={values.consentAgreementStatement}
                        error={touched.consentAgreementStatement && Boolean(errors.consentAgreementStatement)}
                        onChange={handleChange}
                        helperText={touched.consentAgreementStatement && errors.consentAgreementStatement}
                      />
                    </Grid>
                    
                          <Grid padding={1} item xs={12}>
                            <TextField
                              id='termsAndConditionsUrl'
                              label='T&C URL'
                              InputLabelProps={{ shrink: true }}
                              type='text'
                              fullWidth
                              variant='filled'
                              value={values.termsAndConditionsUrl}
                              error={touched.termsAndConditionsUrl && Boolean(errors.termsAndConditionsUrl)}
                              onChange={handleChange}
                              helperText={touched.termsAndConditionsUrl && errors.termsAndConditionsUrl}
                            />
                            </Grid>
                      {values.type === 'HA_SFI' && (
                          <Grid padding={1} item xs={12}>
                            <TextField
                              id='consentAgreementUrl'
                              label='Consent URL'
                              InputLabelProps={{ shrink: true }}
                              type='text'
                              fullWidth
                              variant='filled'
                              value={values.consentAgreementUrl}
                              error={touched.consentAgreementUrl && Boolean(errors.consentAgreementUrl)}
                              onChange={handleChange}
                              helperText={touched.consentAgreementUrl && errors.consentAgreementUrl}
                            />
                          </Grid>
                        )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions sx={{ backgroundColor: '#f1f1f1' }}>
            <Button type={'submit'} sx={{ margin: 1 }} startIcon={<Save />} variant={'contained'} color='primary'>
              Save
            </Button>
            <Button
              onClick={goBackToProgramsPage}
              sx={{ margin: 1 }}
              startIcon={<CancelOutlined />}
              variant={'contained'}
              color='primary'
            >
              Cancel
            </Button>
          </CardActions>
        </form>
      </Card>
    </RoleAccessProvider>
  )
}

export default ProgramCreationPageComponent
