import * as React from 'react'
import { styled, Theme, CSSObject } from '@mui/material/styles'
import Box from '@mui/material/Box'
import MuiDrawer from '@mui/material/Drawer'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import CssBaseline from '@mui/material/CssBaseline'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { AccountCircle, Bloodtype, Group, LocalHospitalRounded, Loyalty, PriceCheck, PeopleAlt } from '@mui/icons-material'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { Menu, MenuItem } from '@mui/material'
import { LogOutAction } from '../core/actions/authActionTypes'
import { RoleAccessProvider } from "../providers/";
import { Roles } from '../constants/roles'
import { Dna } from 'react-loader-spinner'
import { useSelector } from 'react-redux'
import { State } from '../core/reducers'
import { GetIdentityRolesAction } from '../core/actions/identityActionTypes'
import { useEffect } from 'react'
import { isRole } from '../helpers/roleChecker'

interface Props {
  logOut: () => LogOutAction,
  roles: string[],
  getIdentityRoles: () => GetIdentityRolesAction
}

const drawerWidth = 220

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}))

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}))

const AppBarComponent: React.FunctionComponent<Props> = ({ logOut, getIdentityRoles, roles }) => {
  const [open, setOpen] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const accountMenuOpen = Boolean(anchorEl)

  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    getIdentityRoles();
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)

  const handleClose = () => setAnchorEl(null)
  const handleDrawerOpen = () => setOpen(true)
  const handleDrawerClose = () => setOpen(false)
  const isLoading = useSelector<State>(state => state.isLoading);
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position='fixed' open={open}>
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={handleDrawerOpen}
            edge='start'
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant='h6' noWrap component='div' sx={{ flexGrow: 1 }}>
            {location.pathname
              .slice(1, location.pathname.length)
              .split('-')
              .map((term) => capitalizeFirstLetter(term))
              .join(' ')
              .split('/')
              .slice(0, 1)}
          </Typography>
          <IconButton onClick={handleClick} color='inherit'>
            <AccountCircle />
          </IconButton>
          <Menu
            id='basic-menu'
            anchorEl={anchorEl}
            open={accountMenuOpen}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem
              onClick={() => {
                logOut()
                window.location.href = '/login';
              }}
            >
              Logout
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer variant='permanent' open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <RoleAccessProvider required={[Roles.VIEW_PHARMACY_SELF]}>
            <ListItem disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                onClick={() => navigate('/pharmacy/us')}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <LocalHospitalRounded />
                </ListItemIcon>
                <ListItemText primary={'Pharmacy'} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          </RoleAccessProvider>
          <RoleAccessProvider required={[Roles.VIEW_USERS]}>
            <ListItem disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                onClick={() => navigate('/users')}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <AccountCircle />
                </ListItemIcon>
                <ListItemText primary={'Users'} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          </RoleAccessProvider>
          {
            isRole('fingerbox') && (<ListItem disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                onClick={() => navigate('/registered-users')}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <PeopleAlt />
                </ListItemIcon>
                <ListItemText primary={'Users'} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>)
          }
          <RoleAccessProvider required={[Roles.VIEW_BRANDS]}>
            <ListItem disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                onClick={() => navigate('/brands')}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <Bloodtype />
                </ListItemIcon>
                <ListItemText primary={'Brands'} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          </RoleAccessProvider>
          <RoleAccessProvider required={[Roles.VIEW_PROGRAMS]}>
            <ListItem disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                onClick={() => navigate('/programs')}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <Loyalty />
                </ListItemIcon>
                <ListItemText primary={'Programs'} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          </RoleAccessProvider>
          <RoleAccessProvider required={[Roles.VIEW_PHARMACIES]}>
            <ListItem disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                onClick={() => navigate('/pharmacies')}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <LocalHospitalRounded />
                </ListItemIcon>
                <ListItemText primary={'Pharmacies'} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          </RoleAccessProvider>
          {
            (!roles.includes(Roles.VIEW_USERS)) ?
              <RoleAccessProvider required={[Roles.VIEW_PATIENT_STAMP_COLLECTION_ENROLLMENTS]}>
                <ListItem disablePadding sx={{ display: 'block' }}>
                  <ListItemButton
                    onClick={() => navigate('/patients')}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      <Group />
                    </ListItemIcon>
                    <ListItemText primary={'Patients'} sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </ListItem>
              </RoleAccessProvider> : null
          }

          <RoleAccessProvider required={[Roles.VIEW_PATIENT_INSTANT_DISCOUNT_ENROLLMENTS]}>
            <ListItem disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                onClick={() => navigate('/discounts')}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <PriceCheck />
                </ListItemIcon>
                <ListItemText primary={'Instant Discount'} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          </RoleAccessProvider>
        </List>
      </Drawer>
      <Box component={'main'} sx={{ flexGrow: 1, p: 3 }}>
        <>
          {
            isLoading &&
            <Box style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%',
            }}>
              <Dna
                visible={true}
                height="240"
                width="240"
                ariaLabel="dna-loading"
                wrapperStyle={{
                  marginTop: '20vh'
                }}
                wrapperClass="dna-wrapper"
              />
            </Box>
          }
          <DrawerHeader />
          <div style={{
            visibility: isLoading ? 'collapse' : 'visible'
          }}>
            <Outlet />
          </div>
        </>
      </Box>
    </Box>
  )
}

export default AppBarComponent
