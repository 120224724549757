import { Dispatch } from "@reduxjs/toolkit";
import { Action } from "../core/actions";
import { State } from "../core/reducers";
import { connect } from "react-redux";
import { ProgramPageComponent } from "./ProgramPageComponent";
import { getPrograms } from "../core/actions/creators/programActionCreators";
import { PageRequest, ProgramFilter } from "../core/api/types";

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getPrograms: (pageRequest: PageRequest, filter?: ProgramFilter) => dispatch(getPrograms(pageRequest, filter)),
});

const mapStateToProps = (state: State) => ({
  programs: state.programs,
  brandNames: state.brandNames,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProgramPageComponent);
