import { ProgramFilter } from './types/index';
import * as request from './helpers/request'
import { PageRequest, Program } from './types'

export async function getPrograms(pageRequest: PageRequest, filter?: ProgramFilter) {
  return await request.call({
    url: `${process.env.REACT_APP_HOST_BASE_URL}/api/programs/filter?page=${pageRequest.page}&size=${pageRequest.size}`,
    method: 'POST',
    data: filter
  })
}

export async function getProgramNames() {
  return await request.call({
    url: `${process.env.REACT_APP_HOST_BASE_URL}/api/programs/names`,
    method: 'GET',
  })
}

export async function getProgramData(id: string) {
  return await request.call({
    url: `${process.env.REACT_APP_HOST_BASE_URL}/api/programs/${id}`,
    method: 'GET',
  })
}

export async function createProgram(program: Program) {
  console.log(">>", program);
  return await request.call({
    url: `${process.env.REACT_APP_HOST_BASE_URL}/api/programs`,
    method: 'POST',
    data: program,
  })
}

export async function updateProgram(program: Program) {
  return await request.call({
    url: `${process.env.REACT_APP_HOST_BASE_URL}/api/programs/${program.id}`,
    method: 'PUT',
    data: program,
  })
}
