import { TableCell, TableRow } from '@mui/material'
import { FC } from 'react'
import { Stamp } from '../core/api/types'
import dayjs from 'dayjs'

interface Props {
  stamp: Stamp
}

const PharmacyStampTableRowComponent: FC<Props> = ({ stamp }) => {
  console.log(stamp)
  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell style={{ textAlign: 'center' }}>{stamp.transactionId}</TableCell>
        <TableCell style={{ textAlign: 'center' }}>{stamp.patientNumberId}</TableCell>
        <TableCell style={{ textAlign: 'center' }}>{stamp.brandName}</TableCell>
        <TableCell style={{ textAlign: 'center' }}>{stamp.dosage}</TableCell>
        <TableCell style={{ textAlign: 'center' }}>{stamp.numberOfBoxes}</TableCell>
        <TableCell style={{ textAlign: 'center' }}>{dayjs(stamp.pickUpDate).tz("Asia/Hong_Kong").tz('Asia/Hong_Kong').format('DD-MM-YYYY')}</TableCell>
      </TableRow>
    </>
  )
}

export default PharmacyStampTableRowComponent
