import type { FC } from "react"
import { Navigate } from "react-router-dom"
import { isRole } from "../helpers/roleChecker"

type Props = {
    children: JSX.Element
    role: 'admin' | 'fingerbox'
}

const ProtectedRoute: FC<Props> = ({ children, role }) => {
    return children
}

export default ProtectedRoute