import { PharmacyAction } from './../actions/pharmacyActionTypes'
import { Region } from '../api/types'
import { ActionTypes } from '../actions/types'
import 'react-toastify/dist/ReactToastify.css'

const reducer = (state: Region[] = [], action: PharmacyAction): Region[] => {
  switch (action.type) {
    case ActionTypes.GET_REGIONS_SUCCESS:
      return action.payload
    default:
      return state
  }
}

export default reducer
