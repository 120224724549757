import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { PageRequest, Enrollment, PagedTracker, PatientFilter } from '../core/api/types'
import { GetEnrollmentAction } from '../core/actions/enrollmentActionTypes'
import {
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableBody,
  Button,
  Grid,
  Table,
  TablePagination,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  IconButton,
} from '@mui/material'
import EnrollmentsTableRowContainer from '../components/EnrollmentsTableRowContainer'
import { Check, Close, Download, Refresh } from '@mui/icons-material'
import csvDownload from 'json-to-csv-export'
import { useFormik } from 'formik'
import dayjs from 'dayjs'
import { Roles } from '../constants/roles'

interface Props {
  getEnrollments: (pageRequest: PageRequest, filter?: PatientFilter) => GetEnrollmentAction
  enrollments: PagedTracker<Enrollment>
  programNames: string[],
  roles: string[]
}
const mapTransactionStatusToMessage = (enrollment: Enrollment) => {
  if (enrollment.transactionStatus === 'COUPON_PROPOSED' && dayjs(enrollment.arrangePickUpBy.slice(0, 10), 'YYYY-MM-DD').isBefore(dayjs())) return 'Expired'
  if (enrollment.transactionStatus === 'DELIVERED' && dayjs(enrollment.redeemBy.slice(0, 10), 'YYYY-MM-DD').isBefore(dayjs())) return 'Expired'
  switch (enrollment.transactionStatus) {
    case 'ENROLLMENT_BASED':
      return 'Waiting for photos'
    case 'COUPON_PROPOSED':
      return 'To arrange pick up'
    case 'PICKUP_ARRANGED':
      return 'Pick up arranged'
    case 'PICKUP_APPROVED':
      return 'Pick up approved'
    case 'SHIPPED':
      return 'Shipped'
    case 'DELIVERED':
      return 'Delivered'
    case 'COUPON_BURNT':
      return 'No redeem balance left'
  }
}
export const EnrollmentPageComponent: FC<Props> = ({ getEnrollments, enrollments: pagedEnrollments, programNames, roles }) => {
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(5)
  const [filters, setFilters] = useState<PatientFilter>()
  const [showOrderInformationSection, setShowOrderInformationSection] = useState<boolean>(true)
  const enrollments = pagedEnrollments.data

  const tableHeaders = useMemo(
    () => [
      '',
      'Name (English/Chinese)',
      'Phone No.',
      'Date of Registration',
      'User Status',
      'Program',
      'Redemption Balance',
      'Approved Photos',
      'Declined Photos',
      'Pending Photos',
      'Transaction Status',
      'Arrange Pick-Up By',
      'Redeem By',
    ],
    [],
  )

  const filterFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      phone: null,
      program: null,
      arrangePickUpBy: null,
      redeemBy: null,
      name: null,
      submissionBoxStatus: null,
      transactionStatus: null,
      userStatus: null,
    },
    onSubmit: (filters: PatientFilter) => {
      console.log('FILTERS', filters)
      setPage(0);
      filterFormik.values.transactionStatus === 'ENROLLMENT_BASED' || filterFormik.values.transactionStatus === "PICKUP_DECLINED"  ? setShowOrderInformationSection(false) : setShowOrderInformationSection(true)
      setFilters(filters)
    },
  })

  const resetFilters = () => {
    setPage(0)
    setSize(5)
    setFilters({} as PatientFilter)
    setShowOrderInformationSection(true)
    filterFormik.resetForm({
      values: {
        phone: null,
        program: null,
        arrangePickUpBy: null,
        redeemBy: null,
        name: null,
        submissionBoxStatus: null,
        transactionStatus: null,
        userStatus: null,
      }
    })
  }

  const refreshPage = useCallback(() => {
    console.log('FILTERS', filters)
    getEnrollments({ page, size }, filters)
  }, [getEnrollments, page, size, filters])

  useEffect(() => {
    refreshPage()
  }, [refreshPage])

  useEffect(() => {
    refreshPage()
  }, [filters])

  let periods = [0, 7, 14, 30]

  return (
    <div>
      <Grid container justifyContent='flex-end' style={{ marginBottom: 24 }}>
        <Button
          onClick={() => csvDownload({
            delimiter: ',', data: enrollments.content.map(enrollment => {
              return {
                "Transaction ID": enrollment.patient.id.slice(enrollment.patient.id.length - 8) + enrollment.program.id.slice(enrollment.program.id.length - 8),
                "Patient ID": enrollment.patient.id,
                ...roles.includes(Roles.MANAGE_PATIENT_ACTIVATION) && {
                  "Patient Phone Number": enrollment.patient.identity.username,
                },
                "Member ID": enrollment.patient.memberId,
                ...roles.includes(Roles.MANAGE_PATIENT_ACTIVATION) && {
                  "Chinese Name": enrollment.patient.chineseName,
                  "English Name": enrollment.patient.englishName,
                },
                "Address": enrollment.patient.address,
                "Comment": enrollment.patient.comment,
                "Date of Registration": enrollment.patient.dateRegistered,
                "User Status": enrollment.patient.active ? (enrollment.patient.verified ? "VERIFIED" : "UNVERIFIED") : "INACTIVE",
                "Program Name": enrollment.program.name,
                "Redemption Balance": enrollment.redemptionBalance,
                "Approved Photos": enrollment.approvedStamps,
                "Declined Photos": enrollment.declinedStamps,
                "Pending Photos": enrollment.pendingStamps,
                "Redeem By Date": enrollment.redeemBy,
                "Current Transaction Date": enrollment.statusChanged,
                "Transaction Status": mapTransactionStatusToMessage(enrollment),
                "Pick-Up Arrange Date": enrollment.arrangePickUpBy,
                "Brand": enrollment.program.brand,
                "Dosage": enrollment.dosage,
                "Zuellig ID": enrollment.pharmacy?.identity.username,
                "Pharmacy Network": enrollment.pharmacyNetwork,
                "Pharmacy Name": enrollment.pharmacy?.nameChinese,
                "Pharmacy Address": enrollment.pharmacy?.addressChinese,
                "District": enrollment.pharmacy?.region,
                "Active": enrollment.pharmacy?.identity.isActive
              };
            })
          })}
          style={{ marginRight: 12 }}
          startIcon={<Download />}
          variant={'contained'}
        >
          Export
        </Button>
        <Button
          onClick={() => {
            window.location.reload();
          }}
          style={{ marginRight: 12 }}
          startIcon={<Refresh />}
          variant={'contained'}
        >
          Refresh
        </Button>
      </Grid>
      <Grid minWidth={1800} container justifyContent='flex-end' style={{ marginBottom: 24 }}>

        <form onSubmit={filterFormik.handleSubmit} style={{ display: 'flex' }}>
          <FormControl variant={'filled'} fullWidth>
            <InputLabel id={'group-label'}>Program</InputLabel>
            <Select
              style={{ width: 200 }}
              labelId={'group-label'}
              id='program'
              name='program'
              value={filterFormik.values.program}
              label='Program'
              onChange={(val) => {
                filterFormik.setFieldValue('program', val.target.value)
              }}
            >
              {programNames.map((program, index) => (
                <MenuItem key={index} value={program}>
                  {program}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl style={{ marginLeft: 8 }} variant={'filled'} fullWidth>
            <InputLabel id={'group-label'}>Transaction Status</InputLabel>
            <Select
              style={{ width: 200 }}
              labelId={'group-label'}
              id='transactionStatus'
              name='transactionStatus'
              value={filterFormik.values.transactionStatus}
              label='Transaction Status'
              onChange={(val) => {
                filterFormik.setFieldValue('transactionStatus', val.target.value)
              }}
            >
              {[
                'ENROLLMENT_BASED',
                'COUPON_PROPOSED',
                'PICKUP_ARRANGED',
                'PICKUP_APPROVED',
                'PICKUP_DECLINED',
                'SHIPPED',
                'DELIVERED',
              ].map((status, index) => (
                <MenuItem key={index} value={status}>
                  {status}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl style={{ marginLeft: 8 }} variant={'filled'} fullWidth>
            <InputLabel id={'group-label'}>User Status</InputLabel>
            <Select
              style={{ width: 200 }}
              labelId={'group-label'}
              id='userStatus'
              name='userStatus'
              value={filterFormik.values.userStatus}
              label='User Status'
              onChange={(val) => {
                filterFormik.setFieldValue('userStatus', val.target.value)
              }}
            >
              {[
                'VERIFIED',
                'UNVERIFIED',
                'DELETED',
              ].map((status, index) => (
                <MenuItem key={index} value={status}>
                  {status}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            style={{ marginLeft: 8 }}
            id='phone'
            label='Phone Number'
            name='phone'
            type='text'
            fullWidth
            variant='filled'
            value={filterFormik.values.phone}
            onChange={filterFormik.handleChange}
          />
          <TextField
            style={{ marginLeft: 8 }}
            id='name'
            label='Name'
            name='name'
            type='text'
            fullWidth
            variant='filled'
            value={filterFormik.values.name}
            onChange={filterFormik.handleChange}
          />
          <FormControl style={{ marginLeft: 8 }} variant={'filled'} fullWidth>
            <InputLabel id={'group-label'}>Submission Box Status</InputLabel>
            <Select
              style={{ width: 200 }}
              labelId={'group-label'}
              id='submissionBoxStatus'
              name='submissionBoxStatus'
              value={filterFormik.values.submissionBoxStatus}
              label='SubmissionBoxStatus'
              onChange={(val) => {
                filterFormik.setFieldValue('submissionBoxStatus', val.target.value)
              }}
            >
              {[
                'APPROVED',
                'DECLINED',
                'UPLOADED',
              ].map((status, index) => (
                <MenuItem key={index} value={status}>
                  {status}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl style={{ marginLeft: 8 }} variant={'filled'} fullWidth>
            <InputLabel id={'group-label'}>Arrange Pick-Up Date</InputLabel>
            <Select
              style={{ width: 200 }}
              labelId={'group-label'}
              id='arrangePickUpBy'
              name='arrangePickUpBy'
              value={filterFormik.values.arrangePickUpBy}
              label='Arrange Pick-Up Date'
              onChange={(val) => {
                filterFormik.setFieldValue('arrangePickUpBy', val.target.value)
              }}
            >
              {[0, 1, 2, 3].map((arrangePickUpBy, index) => (
                <MenuItem key={index} value={arrangePickUpBy}>
                  {arrangePickUpBy === 0 ? 'Today' : `Next ${periods[arrangePickUpBy]} Days`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl style={{ marginLeft: 8 }} variant={'filled'} fullWidth>
            <InputLabel id={'group-label'}>Redeem By Date</InputLabel>
            <Select
              style={{ width: 200 }}
              labelId={'group-label'}
              id='redeemBy'
              name='redeemBy'
              value={filterFormik.values.redeemBy}
              label='Redeem By Date'
              onChange={(val) => {
                filterFormik.setFieldValue('redeemBy', val.target.value)
              }}
            >
              {[0, 1, 2, 3].map((redeemBy, index) => (
                <MenuItem key={index} value={redeemBy}>
                  {redeemBy === 0 ? 'Today' : `Next ${periods[redeemBy]} Days`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </form>
        <IconButton style={{ paddingLeft: 20, paddingRight: 20, marginLeft: 8 }} onClick={filterFormik.submitForm}>
          <Check />
        </IconButton>
        <IconButton
          type='reset'
          style={{ paddingLeft: 20, paddingRight: 20 }}
          onClick={resetFilters}
        >
          <Close />
        </IconButton>
      </Grid>
      <Paper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {tableHeaders.map((i) => (
                  <TableCell key={i} align={'center'}>
                    {i}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {enrollments.content.map((enrollment, index) => (
                <EnrollmentsTableRowContainer key={enrollment.program.id + enrollment.patient.id} enrollment={enrollment} filter={filters} showOrderInformationSection={showOrderInformationSection} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[
            { label: '5', value: 5 },
            { label: '10', value: 10 },
            { label: '15', value: 15 },
            { label: '20', value: 20 },
            { label: '50', value: 50 },
            { label: '100', value: 100 },
            { label: '150', value: 150 },
            // when requesting more that 180 entities, receive 500 server error.
            // please return this lines when fixed
            // { label: '500', value: 500 },
            // { label: '1000', value: 1000 },
            // { label: 'All', value: enrollments.totalItems }
          ]}
          component='div'
          count={enrollments.totalItems}
          rowsPerPage={pagedEnrollments.size}
          page={pagedEnrollments.page}
          onPageChange={(_, currentPage) => setPage(currentPage)}
          onRowsPerPageChange={(e) => {
            setSize(Number(e.target.value))
            setPage(0)
          }}
        />
      </Paper>
    </div>
  )
}
