import { FC } from 'react'
import { Box, Button, Grid, Paper, Typography } from '@mui/material'
import { PagedTracker, PageRequest, PatientFilter, StorageFile, Submission, UploadImageData } from '../core/api/types'
import '../styles/gallery.scss'
import { ApproveSubmssionAction, DeclineSubmssionAction } from '../core/actions/submissionActionTypes'
import { RoleAccessProvider } from '../providers'
import { Roles } from '../constants/roles'

interface Props {
  submissions: Submission[]
  storage: PagedTracker<StorageFile>
  filter?: PatientFilter
  pharmacyNetwork: string,
  getStorageFiles: (pageRequest: PageRequest) => void
  uploadImage: (uploadImageData: UploadImageData) => void
  approveSubmission: (submission: Submission, filter?: PatientFilter) => ApproveSubmssionAction
  declineSubmission: (submission: Submission, filter?: PatientFilter) => DeclineSubmssionAction,
  numberOfLatestSubmissions: number | null
}
const SubmissionTableRowComponent: FC<Props> = ({ submissions, filter, pharmacyNetwork, approveSubmission, declineSubmission, numberOfLatestSubmissions }) => {
  return (
    <Box style={{ width: "100%", flexDirection: "row", display: "flex", flexWrap: "wrap", marginTop: 12 }}>
      {submissions
        .sort((a, b) => parseInt(b.id) - parseInt(a.id)).filter(({ dateUploaded }) => dateUploaded !== null)
        .slice(0, numberOfLatestSubmissions ?? submissions.length )
        .map((submission: Submission, index: number) => {
          return (
            <Box style={{
              width: 280,
              margin: 8
            }}>
              <Paper variant="outlined">
                <img
                  style={{
                    objectFit: "contain",
                    width: "100%",
                    height: 280
                  }}
                  src={`${process.env.REACT_APP_HOST_BASE_URL}/api/storage/image?id=${submission.attachedFile}`}
                  alt={submission.attachedFile}
                  loading='lazy'
                />
                <Typography style={{
                  fontSize: 24,
                  textAlign: "center",
                  marginBottom: 6,
                  fontWeight: "bold"
                }}>{submission.id}</Typography>

                <Grid container>
                  <Grid item xs={6}>
                    <Typography style={{
                      textAlign: "left",
                      fontWeight: "bold",
                      paddingLeft: 18
                    }}>Date uploaded</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography style={{
                      textAlign: "center"
                    }}>{submission.dateUploaded.slice(0, 10)}</Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography style={{
                      textAlign: "left",
                      fontWeight: "bold",
                      paddingLeft: 18
                    }}>Date {submission.submissionStatus === "UPLOADED" ? "submitted" : submission.submissionStatus.toLocaleLowerCase()}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography style={{
                      textAlign: "center"
                    }}>{submission.dateOfStatusChange.slice(0, 10)}</Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography style={{
                      textAlign: "left",
                      fontWeight: "bold",
                      paddingLeft: 18
                    }}>Network: </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography style={{
                      textAlign: "center"
                    }}>{pharmacyNetwork}</Typography>
                  </Grid>
                </Grid>
                <RoleAccessProvider required={[ Roles.MANAGE_PATIENT_STAMP_COLLECITON_ENROLLMENTS ]}>
                  <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", marginBottom: 24, marginTop: 24 }}>
                    <Button onClick={() => approveSubmission(submission, filter)} disabled={submission.submissionStatus !== "UPLOADED"} variant="contained">Approve</Button>
                    <Button onClick={() => declineSubmission(submission, filter)} disabled={submission.submissionStatus !== "UPLOADED"} variant="contained">Decline</Button>
                  </Box>
                </RoleAccessProvider>
              </Paper>
            </Box>
          )
        })}
    </Box>
  )
}

export default SubmissionTableRowComponent
