import { UserFilter } from './types/index';
import {PageRequest, UserCreationData, UserPasswordPatch, UserUpdateData} from "./types";
import * as request from "./helpers/request";
export async function getUsers(pageRequest : PageRequest, filter?: UserFilter) {
    console.log("REQUEST FILTER", filter);
    
    return await request.call({
        url: `${process.env.REACT_APP_HOST_BASE_URL}/api/users/filter`,
        method: "POST",
        params: pageRequest,
        data: filter 
    });
}

export async function updateUser(user : UserUpdateData) {
    return await request.call({
        url: `${process.env.REACT_APP_HOST_BASE_URL}/api/users/${user.id}`,
        method: "PUT",
        data: user
    });
}

export async function createUser(user : UserCreationData) {
    return await request.call({
        url: `${process.env.REACT_APP_HOST_BASE_URL}/api/users/`,
        method: "POST",
        data: user
    });
}

export async function changeUserPassword(userPasswordPatch : UserPasswordPatch) {
    return await request.call({
        url: `${process.env.REACT_APP_HOST_BASE_URL}/api/user/reset-password`,
        method: "POST",
        data: userPasswordPatch
    });
}