import { LoadingAction } from './../actions/loadingActionTypes'

const isAnythingLoading = (actionType: string) => /(.*)_(request|success|failure)/.test(actionType)

const reducer = (state = false, action: LoadingAction) => {
  const matches = isAnythingLoading(action.type)
  console.log(matches)
  console.log(action.type)

  if (!matches) {
    return state
  }

  return action.type.endsWith('request')
}

export default reducer
