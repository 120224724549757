import React, { useRef } from 'react'
import { Box, Button, Card, CardActions, CardContent, Paper, TablePagination, Typography } from '@mui/material'
import { PhotoLibraryRounded, Upload } from '@mui/icons-material'
import { PagedTracker, PageRequest, StorageFile, UploadImageData } from '../core/api/types'
import '../styles/gallery.scss'

interface Props {
  storage: PagedTracker<StorageFile>
  getStorageFiles: (pageRequest: PageRequest) => void
  onImageClicked: (storageFile: StorageFile) => void
  uploadImage: (uploadImageData: UploadImageData) => void
}

const StorageGalleryComponent: React.FunctionComponent<Props> = ({ storage, getStorageFiles, onImageClicked, uploadImage }) => {
  const fileUploadRef = useRef<HTMLInputElement>(null)
  return (
    <Card sx={{ flex: 1 }}>
      <CardContent className={'gallery-inner-modal-wrapper'}>
        <Box mb={1} sx={{ display: 'flex', alignItems: 'center' }}>
          <PhotoLibraryRounded />
          <Typography ml={2} variant={'h5'}>
            <b>Choose an image from gallery</b>
          </Typography>
        </Box>
        <Typography variant={'subtitle2'}>
          Choose an image that you want to be used from the gallery of previously uploaded images, or upload a new one.
        </Typography>
        <hr className={'gallery-hr'} />
        <Box className={'gallery-container'}>
          {storage.data.content.map((storageFile: StorageFile, index: number) => {
            console.log(`${process.env.REACT_APP_HOST_BASE_URL}/api/storage/image?id=${storageFile.identifier}`)
            return (
              <Paper className={'gallery-item-paper'} sx={{ margin: 1 }}>
                <Box component={'button'} onClick={() => onImageClicked(storageFile)} className={'gallery-item-box'}>
                  <img
                    src={`${process.env.REACT_APP_HOST_BASE_URL}/api/storage/image?id=${storageFile.identifier}`}
                    alt={storageFile.identifier}
                    loading='lazy'
                  />
                  <Box
                    className={'label-box'}
                    style={{
                      display: 'flex',
                    }}
                  >
                    <Typography variant={'overline'}>
                      {storageFile.identifier.split('.')[0].slice(0, 10) + '.' + storageFile.identifier.split('.')[1]}
                    </Typography>
                  </Box>
                </Box>
              </Paper>
            )
          })}
        </Box>
      </CardContent>
      <CardActions
        sx={{
          backgroundColor: '#f1f1f1',
          display: 'flex',
          justifyContent: 'space-between',
          paddingLeft: 2,
        }}
      >
        <input
          onChange={(e) => {
            let fileList: FileList | null = e.target.files
            if (!fileList) {
              return
            }
            let firstFile = fileList.item(0)
            if (!firstFile) {
              return
            }

            uploadImage({
              identifier: firstFile.name,
              file: firstFile,
            })
          }}
          ref={fileUploadRef}
          type='file'
          accept='image/png, image/jpeg'
          style={{ display: 'none' }}
        />
        <Button
          onClick={() => {
            fileUploadRef.current?.click()
          }}
          startIcon={<Upload />}
          variant={'contained'}
        >
          Upload
        </Button>
        <TablePagination
          rowsPerPageOptions={[9, 18, 36, 72]}
          component='div'
          count={storage.data.totalItems}
          page={storage.page}
          onPageChange={(e, page) => {
            getStorageFiles({ page: page, size: storage.size })
          }}
          rowsPerPage={storage.size}
          onRowsPerPageChange={(e) => {
            getStorageFiles({ page: storage.page, size: Number(e.target.value) })
          }}
        />
      </CardActions>
    </Card>
  )
}

export default StorageGalleryComponent
