import { GetBrandNamesSuccessAction, GetBrandNamesRequestAction } from './../brandActionTypes';
import { Paged, PageRequest, Brand, BrandCreation } from '../../api/types'
import {
  DeactivateBrandAction,
  DeactivateBrandFailureAction,
  DeactivateBrandRequestAction,
  DeactivateBrandSuccessAction,
  GetBrandAction,
  GetBrandDataAction,
  GetBrandDataFailureAction,
  GetBrandDataRequestAction,
  GetBrandDataSuccessAction,
  GetBrandFailureAction,
  GetBrandRequestAction,
  GetBrandSuccessAction,
  SaveBrandAction,
  SaveBrandRequestAction,
  SaveBrandSuccessAction,
  SaveBrandFailureAction,
  UpdateBrandAction,
  UpdateBrandRequestAction,
  UpdateBrandSuccessAction,
  UpdateBrandFailureAction,
  GetBrandNamesAction,
  GetBrandNamesFailureAction,
} from '../brandActionTypes'
import { ActionTypes } from '../types'

export function getBrands(pageRequest: PageRequest): GetBrandAction {
  return {
    type: ActionTypes.GET_BRAND,
    payload: pageRequest,
  }
}

export function getBrandsRequest(): GetBrandRequestAction {
  return {
    type: ActionTypes.GET_BRAND_REQUEST,
  }
}

export function getBrandsSuccess(brands: Paged<Brand>): GetBrandSuccessAction {
  return {
    type: ActionTypes.GET_BRAND_SUCCESS,
    payload: brands,
  }
}

export function getBrandsFailure(error: Error | string): GetBrandFailureAction {
  return {
    type: ActionTypes.GET_BRAND_FAILURE,
    error,
  }
}

export function getBrandData(id: string): GetBrandDataAction {
  return {
    type: ActionTypes.GET_BRAND_DATA,
    payload: id,
  }
}

export function getBrandDataRequest(): GetBrandDataRequestAction {
  return {
    type: ActionTypes.GET_BRAND_DATA_REQUEST,
  }
}

export function getBrandDataSuccess(brand: Brand): GetBrandDataSuccessAction {
  return {
    type: ActionTypes.GET_BRAND_DATA_SUCCESS,
    payload: brand,
  }
}

export function getBrandDataFailure(error: Error | string): GetBrandDataFailureAction {
  return {
    type: ActionTypes.GET_BRAND_DATA_FAILURE,
    error,
  }
}

export function deactivateBrand(
  id: string,
  //  pageToRefresh?: PageRequest
): DeactivateBrandAction {
  return {
    type: ActionTypes.DEACTIVATE_BRAND,
    payload: id, //{ id, pageToRefresh },
  }
}

export function deactivateBrandRequest(): DeactivateBrandRequestAction {
  return {
    type: ActionTypes.DEACTIVATE_BRAND_REQUEST,
  }
}

export function deactivateBrandSuccess(): DeactivateBrandSuccessAction {
  return {
    type: ActionTypes.DEACTIVATE_BRAND_SUCCESS,
  }
}

export function deactivateBrandDataFailure(error: Error | string): DeactivateBrandFailureAction {
  return {
    type: ActionTypes.DEACTIVATE_BRAND_FAILURE,
    error,
  }
}

export function deactivateBrandDataRequest(): any {
  throw new Error('Function not implemented.')
}

export function deactivateBrandDataSuccess(data: any): any {
  throw new Error('Function not implemented.')
}

export function DeactivateBrandDataFailure(error: any): any {
  throw new Error('Function not implemented.')
}

export function saveBrand(brand: BrandCreation): SaveBrandAction {
  return {
    type: ActionTypes.SAVE_BRAND,
    payload: brand,
  }
}

export function saveBrandRequest(): SaveBrandRequestAction {
  return {
    type: ActionTypes.SAVE_BRAND_REQUEST,
  }
}

export function saveBrandSuccess(): SaveBrandSuccessAction {
  return {
    type: ActionTypes.SAVE_BRAND_SUCCESS,
  }
}

export function saveBrandFailure(error: Error | string): SaveBrandFailureAction {
  return {
    type: ActionTypes.SAVE_BRAND_FAILURE,
    error,
  }
}

export function updateBrand(brand: BrandCreation): UpdateBrandAction {
  return {
    type: ActionTypes.UPDATE_BRAND,
    payload: brand,
  }
}

export function updateBrandRequest(): UpdateBrandRequestAction {
  return {
    type: ActionTypes.UPDATE_BRAND_REQUEST,
  }
}

export function updateBrandSuccess(): UpdateBrandSuccessAction {
  return {
    type: ActionTypes.UPDATE_BRAND_SUCCESS,
  }
}

export function updateBrandFailure(error: Error | string): UpdateBrandFailureAction {
  return {
    type: ActionTypes.UPDATE_BRAND_FAILURE,
    error,
  }
}

export function getBrandNames() : GetBrandNamesAction {
  return {
    type: ActionTypes.GET_BRAND_NAMES
  }
}

export function getBrandNamesRequest() : GetBrandNamesRequestAction {
  return {
    type: ActionTypes.GET_BRAND_NAMES_REQUEST
  }
}

export function getBrandNamesSuccess(brandNames: string[]) : GetBrandNamesSuccessAction {
  return {
    type: ActionTypes.GET_BRAND_NAMES_SUCCESS,
    payload: brandNames,
  }
}

export function getBrandNamesFailure(error: Error | string): GetBrandNamesFailureAction {
  return {
    type: ActionTypes.GET_BRAND_NAMES_FAILURE,
    error,
  }
}