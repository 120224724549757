import { IdentityAction } from './../actions/identityActionTypes'
import { ActionTypes } from '../actions/types'
import 'react-toastify/dist/ReactToastify.css'

const reducer = (state: string[] = [], action: IdentityAction): string[] => {
  switch (action.type) {
    case ActionTypes.GET_IDENTITY_ROLES_SUCCESS:
      return action.payload
    default:
      return state
  }
}

export default reducer
