import { Add, CancelOutlined } from '@mui/icons-material'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { DatePicker, DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import { FC, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Roles } from '../constants/roles'
import { GetBrandAction } from '../core/actions/brandActionTypes'
import { SaveProgramAction } from '../core/actions/programActionTypes'
import { Brand, PagedTracker, PageRequest, Program, ProgramFilter } from '../core/api/types'
import { programValidationSchema } from '../core/validations'
import { types } from '../core/validations/consts-and-functions'
import { RoleAccessProvider } from '../providers'

interface Props {
  createProgram: (program: Program, filter?: ProgramFilter) => SaveProgramAction
  getBrands: (pageRequest: PageRequest) => GetBrandAction
  brands: PagedTracker<Brand>
}


const ProgramCreationPageComponent: FC<Props> = ({ createProgram, brands: pagedBrands, getBrands }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const page = 0
  const size = 50
  const brands = pagedBrands.data

  const { values, setFieldValue, errors, touched, handleSubmit, handleChange } = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: '',
      name: '',
      type: '',
      brand: '',
      startDate: null,
      endDate: null,
      active: null,
      discountQuantityBuyX: undefined,
      discountAmountGetY: undefined,
      maximumQuantity: undefined,
      discountAmount: undefined,
      minimumAmount: undefined,
      oneOffDiscountOnly: null,
      incremental: null,
      photoSubmissionInstructionPage: null,
      howToJoin: null,
      consentAgreementStatement: null,
      termsAndConditionsUrl: null,
      consentAgreementUrl: null,
      availableDosages: [],
      availableDosagesTextual: ''
    },
    validationSchema: programValidationSchema,
    onSubmit: (program: Program & { availableDosagesTextual: string }) => {
      console.log(program)
      program.availableDosages = program.availableDosagesTextual.split(',').map(s => s.trim());
      createProgram(program, location.state?.filter)
      navigate('/programs', { state: { from: location.pathname, filter: location.state?.filter } })
    },
  })

  useEffect(() => {
    getBrands({ page, size })
  }, [getBrands, page, size])

  return (
    <RoleAccessProvider required={[Roles.MANAGE_PROGRAMS]}>
      <Grid container sx={{ marginTop: 1, marginBottom: 2 }}>
        <Grid item xs={8}>
          <Card sx={{ flex: 1 }}>
            <form onSubmit={handleSubmit}>
              <CardContent>
                <Grid container>
                  <Grid item xs={6}>
                    <Grid padding={1} item>
                      <FormControl variant={'filled'} fullWidth>
                        <InputLabel id={'group-label'}>Brand</InputLabel>
                        <Select
                          labelId={'group-label'}
                          id='brand'
                          name='brand'
                          value={values.brand}
                          label='Brand'
                          error={touched.brand && Boolean(errors.brand)}
                          onChange={(val) => {
                            setFieldValue('brand', val.target.value)
                          }}
                        >
                          {brands.content
                            .filter(({ active }) => active)
                            .map(({ name }, index) => (
                              <MenuItem key={index} value={name}>
                                {name}
                              </MenuItem>
                            ))}
                        </Select>
                        {touched.brand && Boolean(errors.brand) && (
                          <FormHelperText style={{ color: '#D63F2F' }}>{touched.brand && errors.brand}</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid padding={1} item xs={12}>
                      <TextField
                        id='name'
                        label='Name'
                        type='text'
                        fullWidth
                        variant='filled'
                        value={values.name}
                        error={touched.name && Boolean(errors.name)}
                        onChange={handleChange}
                        helperText={touched.name && errors.name}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid padding={1} item xs={12}>
                      <FormControl variant={'filled'} fullWidth>
                        <InputLabel id={'group-label'}>Type</InputLabel>
                        <Select
                          id='type'
                          labelId='group-label'
                          name='type'
                          value={values.type}
                          label='Type'
                          error={touched.type && Boolean(errors.type)}
                          onChange={(val) => {
                            setFieldValue('type', val.target.value)
                          }}
                        >
                          {types.map((type, index) => (
                            <MenuItem key={index} value={type.transfer}>
                              {type.label}
                            </MenuItem>
                          ))}
                        </Select>
                        {touched.type && Boolean(errors.type) && (
                          <FormHelperText style={{ color: '#D63F2F' }}>{touched.type && errors.type}</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid container xs={12}>
                      <Grid padding={1} item xs={6}>
                        <FormControl variant={'filled'} fullWidth>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                              label='Start Date'
                              value={values.startDate}
                              minDate={Date.now()}
                              inputFormat='YYYY-MM-DD'
                              onChange={(val) => {
                                setFieldValue('startDate', val == null ? null : val)
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant={'filled'}
                                  inputProps={{
                                    ...params.inputProps,
                                    readOnly: true
                                  }}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </FormControl>
                      </Grid>
                      <Grid padding={1} item xs={6}>
                        <FormControl variant={'filled'} fullWidth>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                              label='End Date'
                              minDate={Date.now()}
                              inputFormat='YYYY-MM-DD'
                              value={values.endDate}
                              onChange={(val) => {
                                setFieldValue('endDate', val == null ? null : val)
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant={'filled'}
                                  inputProps={{
                                    ...params.inputProps,
                                    readOnly: true
                                  }}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container>
                    {values.type !== 'HA_SFI' && (
                      <Grid container item xs={12}>
                        {values.type === 'BUY_X_GET_Y' && (
                          <Grid padding={1} item xs={12}>
                            <TextField
                              id='discountQuantityBuyX'
                              label='Enter number of boxes for eligibility'
                              type='text'
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                              variant='filled'
                              value={values.discountQuantityBuyX}
                              error={touched.discountQuantityBuyX && Boolean(errors.discountQuantityBuyX)}
                              onChange={handleChange}
                              helperText={touched.discountQuantityBuyX && errors.discountQuantityBuyX}
                            />
                          </Grid>
                        )}
                        {values.type === 'BUY_X_GET_Y' && (
                          <Grid padding={1} item xs={12}>
                            <TextField
                              id='discountAmountGetY'
                              label='Enter number of free boxes'
                              InputLabelProps={{ shrink: true }}
                              type='text'
                              fullWidth
                              variant='filled'
                              value={values.discountAmountGetY}
                              error={touched.discountAmountGetY && Boolean(errors.discountAmountGetY)}
                              onChange={handleChange}
                              helperText={touched.discountAmountGetY && errors.discountAmountGetY}
                            />
                          </Grid>
                        )}
                        {values.type === 'BUY_X_GET_Y' && (
                          <Grid padding={1} item xs={12}>
                            <TextField
                              id='maximumQuantity'
                              label='Enter maximum quantity'
                              InputLabelProps={{ shrink: true }}
                              type='text'
                              fullWidth
                              variant='filled'
                              value={values.maximumQuantity}
                              error={touched.maximumQuantity && Boolean(errors.maximumQuantity)}
                              onChange={handleChange}
                              helperText={touched.maximumQuantity && errors.maximumQuantity}
                            />
                          </Grid>
                        )}
                        {values.type === 'BUY_X_GET_Y' && (
                          <Grid padding={1} item xs={12}>
                            <TextField
                              id='availableDosagesTextual'
                              label='Available dosage for redemption'
                              InputLabelProps={{ shrink: true }}
                              type='text'
                              fullWidth
                              variant='filled'
                              value={values.availableDosagesTextual}
                              error={touched.availableDosagesTextual && Boolean(errors.availableDosagesTextual)}
                              onChange={handleChange}
                              helperText={touched.availableDosagesTextual && errors.availableDosagesTextual}
                            />
                          </Grid>
                        )}

                        {values.type === 'INSTANT_DISCOUNT' && (
                          <Grid padding={1} item xs={12}>
                            <TextField
                              id='discountAmount'
                              label='Enter discounted amount'
                              InputLabelProps={{ shrink: true }}
                              type='text'
                              fullWidth
                              variant='filled'
                              value={values.discountAmount}
                              error={touched.discountAmount && Boolean(errors.discountAmount)}
                              onChange={handleChange}
                              helperText={touched.discountAmount && errors.discountAmount}
                            />
                          </Grid>
                        )}
                        {values.type === 'INSTANT_DISCOUNT' && (
                          <Grid padding={1} item xs={8}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  id='incremental'
                                  value={values.incremental}
                                  onChange={handleChange}
                                  checked={values.incremental === true}
                                />
                              }
                              label='Incremental (method for calculation discount amount)'
                            />
                          </Grid>
                        )}
                        {values.type === 'INSTANT_DISCOUNT' && (
                          <Grid padding={1} item xs={12}>
                            <TextField
                              id='minimumAmount'
                              label='Enter minimum number of boxes'
                              InputLabelProps={{ shrink: true }}
                              type='text'
                              fullWidth
                              variant='filled'
                              value={values.minimumAmount}
                              error={touched.minimumAmount && Boolean(errors.minimumAmount)}
                              onChange={handleChange}
                              helperText={touched.minimumAmount && errors.minimumAmount}
                            />
                          </Grid>
                        )}
                        {values.type === 'INSTANT_DISCOUNT' && (
                          <Grid padding={1} item xs={12}>
                            <TextField
                              id='maximumQuantity'
                              label='Enter maximum number of boxes'
                              type='text'
                              InputLabelProps={{ shrink: true }}
                              fullWidth
                              variant='filled'
                              value={values.maximumQuantity}
                              error={touched.maximumQuantity && Boolean(errors.maximumQuantity)}
                              onChange={handleChange}
                              helperText={touched.maximumQuantity && errors.maximumQuantity}
                            />
                          </Grid>
                        )}
                        {values.type !== '' && (
                          <Grid padding={1} item xs={8}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  id='oneOffDiscountOnly'
                                  value={values.oneOffDiscountOnly}
                                  onChange={handleChange}
                                  checked={values.oneOffDiscountOnly === true}
                                />
                              }
                              label='One-off Discount Only'
                            />
                          </Grid>
                        )}
                      </Grid>
                    )}
                    <Grid container item xs={12}>
                      {values.type === 'BUY_X_GET_Y' && (
                        <Grid padding={1} item xs={6}>
                          <TextField
                            id='photoSubmissionInstructionPage'
                            label='Enter instructions'
                            multiline
                            rows={6}
                            type='text'
                            fullWidth
                            inputProps={{ maxLength: 2000 }}
                            InputLabelProps={{ shrink: true }}
                            variant='filled'
                            value={values.photoSubmissionInstructionPage}
                            error={touched.photoSubmissionInstructionPage && Boolean(errors.photoSubmissionInstructionPage)}
                            onChange={handleChange}
                            helperText={touched.photoSubmissionInstructionPage && errors.photoSubmissionInstructionPage}
                          />
                        </Grid>
                      )}
                      {values.type === 'INSTANT_DISCOUNT' && (
                        <Grid padding={1} item xs={6}>
                          <TextField
                            id='howToJoin'
                            label='Enter instructions'
                            multiline
                            rows={6}
                            inputProps={{ maxLength: 2000 }}
                            InputLabelProps={{ shrink: true }}
                            type='text'
                            fullWidth
                            variant='filled'
                            value={values.howToJoin}
                            error={touched.howToJoin && Boolean(errors.howToJoin)}
                            onChange={handleChange}
                            helperText={touched.howToJoin && errors.howToJoin}
                          />
                        </Grid>
                      )}
                      {values.type === 'HA_SFI' && (
                        <Grid padding={1} item xs={6}>
                          <TextField
                            id='howToJoin'
                            label='Enter instructions'
                            multiline
                            rows={6}
                            type='text'
                            inputProps={{ maxLength: 2000 }}
                            fullWidth
                            variant='filled'
                            value={values.howToJoin}
                            InputLabelProps={{ shrink: true }}
                            error={touched.howToJoin && Boolean(errors.howToJoin)}
                            onChange={handleChange}
                            helperText={touched.howToJoin && errors.howToJoin}
                          />
                        </Grid>
                      )}
                      {values.type !== '' && (
                        <Grid padding={1} item xs={6}>
                          <TextField
                            id='consentAgreementStatement'
                            label='Enter consent agreement statement'
                            multiline
                            rows={6}
                            type='text'
                            inputProps={{ maxLength: 2000 }}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            variant='filled'
                            value={values.consentAgreementStatement}
                            error={touched.consentAgreementStatement && Boolean(errors.consentAgreementStatement)}
                            onChange={handleChange}
                            helperText={touched.consentAgreementStatement && errors.consentAgreementStatement}
                          />
                        </Grid>
                      )}
                      {values.type === 'BUY_X_GET_Y' && (
                        <Grid padding={1} item xs={12}>
                          <TextField
                            id='termsAndConditionsUrl'
                            label='T&C URL'
                            InputLabelProps={{ shrink: true }}
                            type='text'
                            fullWidth
                            variant='filled'
                            value={values.termsAndConditionsUrl}
                            error={touched.termsAndConditionsUrl && Boolean(errors.termsAndConditionsUrl)}
                            onChange={handleChange}
                            helperText={touched.termsAndConditionsUrl && errors.termsAndConditionsUrl}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions sx={{ backgroundColor: '#f1f1f1' }}>
                <Button type={'submit'} sx={{ margin: 1 }} startIcon={<Add />} variant={'contained'} color='primary'>
                  Create
                </Button>
                <Button
                  onClick={() => navigate('/programs', { state: { from: location.pathname, filter: location.state?.filter } })}
                  sx={{ margin: 1 }}
                  startIcon={<CancelOutlined />}
                  variant={'contained'}
                  color='primary'
                >
                  Cancel
                </Button>
              </CardActions>
            </form>
          </Card>
        </Grid>
      </Grid>
    </RoleAccessProvider>
  )
}

export default ProgramCreationPageComponent
