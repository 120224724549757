import {
  UpdatePatientDataAction,
  UpdatePatientDataRequest,
  UpdatePatientDataSuccessAction,
  UpdatePatientDataFailureAction,
  UpdateEnrollmentDataAction,
  UpdateEnrollmentDataRequestAction,
  UpdateEnrollmentDataSuccessAction,
  UpdateEnrollmentDataFailureAction,
  ResetRedemptionBalanceAction,
  ResetRedemptionBalanceRequestAction,
  ResetRedemptionBalanceSuccessAction,
  ResetRedemptionBalanceFailureAction,
} from './../enrollmentActionTypes'
import { PatientUpdate, OrderUpdate, PatientFilter, Patient, RegisteredUserFilter } from './../../api/types/index'
import { Paged, PageRequest, Enrollment, EnrollmentStatus } from '../../api/types'
import {
  GetEnrollmentAction,
  GetEnrollmentFailureAction,
  GetEnrollmentRequestAction,
  GetEnrollmentSuccessAction,
  UpdateEnrollmentStatusAction,
  UpdateEnrollmentStatusRequestAction,
  UpdateEnrollmentStatusSuccessAction,
  UpdateEnrollmentStatusFailureAction,
} from '../enrollmentActionTypes'
import { ActionTypes } from '../types'

export function getEnrollments(pageRequest: PageRequest, filter?: PatientFilter): GetEnrollmentAction {
  console.log('FILTER ACTION', filter)

  return {
    type: ActionTypes.GET_ENROLLMENT,
    payload: pageRequest,
    filter: filter ?? <PatientFilter>{},
  }
}

export function getEnrollmentsRequest(): GetEnrollmentRequestAction {
  return {
    type: ActionTypes.GET_ENROLLMENT_REQUEST,
  }
}

export function getEnrollmentsSuccess(enrollments: Paged<Enrollment>): GetEnrollmentSuccessAction {
  return {
    type: ActionTypes.GET_ENROLLMENT_SUCCESS,
    payload: enrollments,
  }
}

export function getEnrollmentsFailure(error: Error | string): GetEnrollmentFailureAction {
  return {
    type: ActionTypes.GET_ENROLLMENT_FAILURE,
    error,
  }
}

export function updateEnrollmentStatus(enrollment: EnrollmentStatus, filter?: PatientFilter): UpdateEnrollmentStatusAction {
  return {
    type: ActionTypes.UPDATE_ENROLLMENT_STATUS,
    payload: enrollment,
    filter,
  }
}

export function updateEnrollmentStatusRequest(): UpdateEnrollmentStatusRequestAction {
  return {
    type: ActionTypes.UPDATE_ENROLLMENT_STATUS_REQUEST,
  }
}

export function updateEnrollmentStatusSuccess(): UpdateEnrollmentStatusSuccessAction {
  return {
    type: ActionTypes.UPDATE_ENROLLMENT_STATUS_SUCCESS,
  }
}

export function updateEnrollmentStatusFailure(error: Error | string): UpdateEnrollmentStatusFailureAction {
  return {
    type: ActionTypes.UPDATE_ENROLLMENT_STATUS_FAILURE,
    error,
  }
}

export function updatePatientData(update: PatientUpdate, filter?: PatientFilter): UpdatePatientDataAction {
  return {
    type: ActionTypes.UPDATE_PATIENT_DATA,
    payload: update,
    filter,
  }
}

export function updatePatientDataRequest(): UpdatePatientDataRequest {
  return {
    type: ActionTypes.UPDATE_PATIENT_DATA_REQUEST,
  }
}

export function updatePatientDataSuccess(): UpdatePatientDataSuccessAction {
  return {
    type: ActionTypes.UPDATE_PATIENT_DATA_SUCCESS,
  }
}

export function updatePatientDataFailure(error: Error | string): UpdatePatientDataFailureAction {
  return {
    type: ActionTypes.UPDATE_PATIENT_DATA_FAILURE,
    error,
  }
}

export function resetRedemptionBalance(): ResetRedemptionBalanceAction {
  return {
    type: ActionTypes.RESET_REDEMPTION_BALANCE,
  }
}

export function resetRedemptionBalanceRequest(): ResetRedemptionBalanceRequestAction {
  return {
    type: ActionTypes.RESET_REDEMPTION_BALANCE_REQUEST,
  }
}

export function resetRedemptionBalanceSuccess(): ResetRedemptionBalanceSuccessAction {
  return {
    type: ActionTypes.RESET_REDEMPTION_BALANCE_SUCCESS,
  }
}

export function resetRedemptionBalanceFailure(error: Error | string): ResetRedemptionBalanceFailureAction {
  return {
    type: ActionTypes.RESET_REDEMPTION_BALANCE_FAILURE,
    error,
  }
}

export function updateEnrollmentData(orderUpdate: OrderUpdate): UpdateEnrollmentDataAction {
  return {
    type: ActionTypes.UPDATE_ENROLLMENT_DATA,
    payload: orderUpdate,
  }
}

export function updateEnrollmentDataRequest(): UpdateEnrollmentDataRequestAction {
  return {
    type: ActionTypes.UPDATE_ENROLLMENT_DATA_REQUEST,
  }
}

export function updateEnrollmentDataSuccess(): UpdateEnrollmentDataSuccessAction {
  return {
    type: ActionTypes.UPDATE_ENROLLMENT_DATA_SUCCESS,
  }
}

export function updateEnrollmentDataFailure(error: Error | string): UpdateEnrollmentDataFailureAction {
  return {
    type: ActionTypes.UPDATE_ENROLLMENT_DATA_FAILURE,
    error,
  }
}
