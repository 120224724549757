import { Dispatch } from '@reduxjs/toolkit'
import { Action } from '../core/actions'
import { State } from '../core/reducers'
import { connect } from 'react-redux'
import { RegisteredUsersPageComponent } from './RegisteredUsersPageComponent'
import { PageRequest, RegisteredUserFilter } from '../core/api/types'
import { getPatients } from '../core/actions/creators/patientActionCreators'

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getPatients: (pageRequest: PageRequest, filter?: RegisteredUserFilter) => dispatch(getPatients(pageRequest, filter)),
})

const mapStateToProps = (state: State) => ({
  patients: state.patients,
  registeredUsersTriggers: state.registereduserstriggers,
})

export default connect(mapStateToProps, mapDispatchToProps)(RegisteredUsersPageComponent)
