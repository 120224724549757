import { Paged, Submission } from '../api/types'
import { SubmissionAction } from '../actions/submissionActionTypes'
import { ActionTypes } from '../actions/types'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import POSITION = toast.POSITION

const initialState = {
  content: [],
  pages: 0,
  totalItems: 0,
}

const reducer = (state: Paged<Submission> = initialState, action: SubmissionAction): Paged<Submission> => {
  switch (action.type) {
    case ActionTypes.APPROVE_SUBMISSION_REQUEST:
      toast.dismiss()
      return state
    case ActionTypes.APPROVE_SUBMISSION_SUCCESS:
      toast.dismiss()
      toast.success('Submitted photo APPROVED successfully', {
        position: POSITION.BOTTOM_CENTER,
      })
      return state
    case ActionTypes.APPROVE_SUBMISSION_FAILURE:
      toast.dismiss()
      toast.error(action.error.toString(), {
        position: POSITION.BOTTOM_CENTER,
      })
      return state
    case ActionTypes.DECLINE_SUBMISSION_REQUEST:
      toast.dismiss()
      return state
    case ActionTypes.DECLINE_SUBMISSION_SUCCESS:
      toast.dismiss()
      toast.success('Submitted photo DECLINED successfully', {
        position: POSITION.BOTTOM_CENTER,
      })
      return state
    case ActionTypes.DECLINE_SUBMISSION_FAILURE:
      toast.dismiss()
      toast.error(action.error.toString(), {
        position: POSITION.BOTTOM_CENTER,
      })
      return state
    default:
      return state
  }
}

export default reducer
