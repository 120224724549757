import { Roles } from './../../constants/roles';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { ActionTypes } from '../actions/types'
import { LogInAction } from '../actions/authActionTypes'
import { authActionCreators, enrollmentActionCreators, pharmacyActionCreators, programActionCreators, storageActionCreators, usersActionCreators } from '../actions/creators'
import * as API from '../api/authentication'
import { AxiosError } from 'axios'
import { getIdentityRoles } from '../actions/creators/identityActionCreators'
import { getRegions } from '../actions/creators/pharmacyActionCreators'
import { store } from '../store'

function* onLogIn(logInAction: LogInAction) {
  yield put(authActionCreators.logInRequest())

  try {
    const { data } = yield call(API.login, logInAction.payload)
    console.log(data)
    yield put(authActionCreators.logInSuccess(data))

    yield put(getIdentityRoles());
    console.log("==========================================");
    console.log(store.getState().roles.includes(Roles.VIEW_PHARMACIES));
    console.log("==========================================");
    if (store.getState().roles.includes(Roles.VIEW_PHARMACIES)) {
      console.log("REGIONS");
      
      yield put(getRegions());
    }
  
    yield put(
      storageActionCreators.getStorageFiles({
        page: store.getState().storage.page,
        size: store.getState().storage.size,
      }),
    )
    yield put(usersActionCreators.getUsers({ page: store.getState().users.page, size: store.getState().users.size }))
    yield put(programActionCreators.getPrograms({ page: store.getState().programs.page, size: store.getState().programs.size }))
    yield put(enrollmentActionCreators.getEnrollments({ 
      page: store.getState().enrollments.page, 
      size: store.getState().enrollments.size, 
    }))
    
    yield put(programActionCreators.getProgramNames());
    if(store.getState().roles.includes(Roles.VIEW_PHARMACIES)) {
      yield put(pharmacyActionCreators.getAllPharmacies());
    }
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      console.log(error)
      // yield put(authActionCreators.logInFailure(error.response.data.errors[0]))
      yield put(authActionCreators.logInFailure("用户名或密码错误"))
    }
  }
}

function* watchAuthActions() {
  yield takeEvery(ActionTypes.LOG_IN, onLogIn)
}

export default function* saga() {
  yield put(authActionCreators.logInRestore())
  yield all([fork(watchAuthActions)])
  yield put(getIdentityRoles());
  
}
