import { InfoOutlined } from '@mui/icons-material'
import { IconButton, TableCell, TableRow } from '@mui/material'
import { FC } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Pharmacy, PharmacyFilter } from '../core/api/types'

interface Props {
  pharmacy: Pharmacy,
  filter?: PharmacyFilter
}

const PharmaciesTableRowComponent: FC<Props> = ({ pharmacy, filter }) => {
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} hover key={pharmacy.id}>
        <TableCell style={{ width: '5%' }}>
          <IconButton aria-label='expand row' size='small' onClick={() => navigate(`/pharmacies/${pharmacy.id}`, { state: { from: location.pathname, filter } })}>
            <InfoOutlined />
          </IconButton>
        </TableCell>
        <TableCell style={{ textAlign: 'center' }}>{pharmacy.identity.username}</TableCell>
        <TableCell style={{ textAlign: 'center' }}>{pharmacy.id}</TableCell>
        <TableCell style={{ textAlign: 'center' }}>{pharmacy.nameChinese}</TableCell>
        <TableCell style={{ textAlign: 'center' }}>{pharmacy.phoneNumber}</TableCell>
        <TableCell style={{ textAlign: 'center' }}>{pharmacy.email}</TableCell>
        <TableCell style={{ textAlign: 'center' }}>{pharmacy.pharmacyNet}</TableCell>
        <TableCell style={{ textAlign: 'center' }}>{pharmacy.addressChinese}</TableCell>
        <TableCell style={{ textAlign: 'center' }}>{pharmacy.region}</TableCell>
        <TableCell style={{ textAlign: 'center' }}>
          {pharmacy.brands.map((brand, index) => (
            <span key={index}>
              {brand}
              <br />
            </span>
          ))}
        </TableCell>
        <TableCell style={{ textAlign: 'center' }}>
          {pharmacy.stampCollectionPrograms.map((program, index) => (
            <span key={index}>
              {program.name}
              <br />
            </span>
          ))}
        </TableCell>
        <TableCell style={{ textAlign: 'center' }}>
          {pharmacy.instantDiscountPrograms.map((program, index) => (
            <span key={index}>
              {program.name}
              <br />
            </span>
          ))}
        </TableCell>
      </TableRow>
    </>
  )
}

export default PharmaciesTableRowComponent
