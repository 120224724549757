import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FilledInput,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from '@mui/material'
import { FC, useEffect, useState } from 'react'

import '../styles/login-page.scss'
import AzLogo from '../assets/az-logo.png'
import { Login, Visibility, VisibilityOff } from '@mui/icons-material'
import { useFormik } from 'formik'
import { loginValidationSchema } from '../core/validations'
import { LogInCredentials, TokenPair } from '../core/api/types'
import { LogInAction } from '../core/actions/authActionTypes'
import { useNavigate } from 'react-router-dom'
import { useLocalization } from '../hooks/useLocalization'

interface Props {
  logIn: (credentials: LogInCredentials) => LogInAction
  tokens: TokenPair | null
}

export const LoginPageComponent: FC<Props> = ({ logIn, tokens }) => {
  const navigate = useNavigate()

  const [showPassword, setShowPassword] = useState(false)

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  const handleMouseUpPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const [locale] = useLocalization()
  console.log(locale)

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: loginValidationSchema,
    onSubmit: (credentials: LogInCredentials) => {
      console.log(credentials)
      console.log(logIn)
      console.log(tokens)
      logIn(credentials)
    },
  })

  useEffect(() => {
    if (tokens) {
      window.location.href = '/'
    }
  }, [tokens, navigate])

  if (!locale) {
    return <div></div>
  }

  return (
    <div className='page-login-container'>
      <Dialog open={true}>
        <DialogTitle fontSize={28}>
          <Box mb={2}>
            <Avatar src={AzLogo} alt={'az-logo'} />
          </Box>
          {locale['login-page-title'] || 'login-page-title'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{locale['login-page-description']}</DialogContentText>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              id='username'
              label={locale['login-username-hint'] || 'login-username-hint'}
              margin='normal'
              type='text'
              fullWidth
              variant='filled'
              value={formik.values.username}
              error={formik.touched.username && Boolean(formik.errors.username)}
              onChange={formik.handleChange}
              helperText={formik.touched.username && formik.errors.username}
            />
            <FormControl margin='normal' variant='filled' fullWidth>
              <InputLabel htmlFor='filled-adornment-password'>{locale['login-password-hint'] || 'login-password-hint'}</InputLabel>
              <FilledInput
                id='password'
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      onMouseUp={handleMouseUpPassword}
                      edge='end'
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>

            <Box mt={2} mb={1}>
              <Button startIcon={<Login />} variant={'outlined'} type={'submit'}>
                {locale['sign-in'] || 'sign-in'}
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  )
}
