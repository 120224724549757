import { PharmacyFilter } from './types/index';
import * as request from "./helpers/request";
import {PageRequest, PharmacyCreation, PharmacyUpdate} from "./types";

export async function getPharmacies(pageRequest : PageRequest, filter?: PharmacyFilter) {
    return await request.call({
        url: `${process.env.REACT_APP_HOST_BASE_URL}/api/pharmacies/filter?page=${pageRequest.page}&size=${pageRequest.size}`,
        method: "POST",
        data: filter
    });
}

export async function getRegions() {
    return await request.call({
        url: `${process.env.REACT_APP_HOST_BASE_URL}/api/regions/all`,
        method: "GET"
    });
}


export async function getPharmacyData(id : string) {
    return await request.call({
        url: `${process.env.REACT_APP_HOST_BASE_URL}/api/pharmacies/${id}`,
        method: "GET"
    });
}

export async function createPharmacy(pharmacy: PharmacyCreation) {
    return await request.call({
      url: `${process.env.REACT_APP_HOST_BASE_URL}/api/pharmacies`,
      method: 'POST',
      data: pharmacy,
    })
  }
  
export async function updatePharmacy(pharmacy : PharmacyUpdate) {
return await request.call({
    url: `${process.env.REACT_APP_HOST_BASE_URL}/api/pharmacies/${pharmacy.id}`,
    method: "PUT",
    data: pharmacy
});
}

export async function getAllPharmacies() {
    return await request.call({
        url: `${process.env.REACT_APP_HOST_BASE_URL}/api/pharmacies/all`,
        method: "GET"
    });
}