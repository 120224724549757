import { Dispatch } from '@reduxjs/toolkit'
import { Action } from '../core/actions'
import { State } from '../core/reducers'
import { connect } from 'react-redux'
import { EnrollmentPageComponent } from './EnrollmentPageComponent'
import { PageRequest, PatientFilter } from '../core/api/types'
import { getEnrollments } from '../core/actions/creators/enrollmentActionCreators'

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getEnrollments: (pageRequest: PageRequest, filter?: PatientFilter) => dispatch(getEnrollments(pageRequest, filter)),
})

const mapStateToProps = (state: State) => ({
  enrollments: state.enrollments,
  programNames: state.programNames,
  roles: state.roles
})

export default connect(mapStateToProps, mapDispatchToProps)(EnrollmentPageComponent)
