import { InstantDiscountFilter, TransactionFilter } from './types/index';
import * as request from './helpers/request'
import { PageRequest } from './types'

export async function getDiscounts(pageRequest: PageRequest, filter : InstantDiscountFilter) {
  if (filter.patientName === '') {
    filter.patientName = null;
  }

  if (filter.pharmacyName === '') {
    filter.pharmacyName = null;
  }

  if (filter.phone === '') {
    filter.phone = null;
  }
  
  return await request.call({
    url: `${process.env.REACT_APP_HOST_BASE_URL}/api/discounts/transactions/filter?page=${pageRequest.page}&size=${pageRequest.size}`,
    method: 'POST',
    data: filter
  })
}

export async function getDiscountTransactionsByPharmacy(id: string, pageRequest: PageRequest, filter: TransactionFilter) {
  console.log("FIL2", filter);
  
  return await request.call({
    url: `${process.env.REACT_APP_HOST_BASE_URL}/api/discounts/transactions/discount/filter/${id}?page=${pageRequest.page}&size=${pageRequest.size}`,
    method: 'POST',
    data: filter
  })
}

export async function getHaSfiTransactionsByPharmacy(id: string, pageRequest: PageRequest, filter: TransactionFilter) {
  console.log(filter);
  
  return await request.call({
    url: `${process.env.REACT_APP_HOST_BASE_URL}/api/discounts/transactions/ha-sfi/filter/${id}?page=${pageRequest.page}&size=${pageRequest.size}`,
    method: 'POST',
    data: filter
  })
}
