import { Dispatch } from "@reduxjs/toolkit";
import { Action } from "../core/actions";
import { State } from "../core/reducers";
import { connect } from "react-redux";
import PharmacyCreationPageComponent from "./PharmacyCreationPageComponent";
import { PharmacyCreation, PharmacyFilter } from "../core/api/types";
import { getRegions, savePharmacy } from "../core/actions/creators/pharmacyActionCreators";
import { getProgramNames } from "../core/actions/creators/programActionCreators";

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  createPharmacy: (pharmacy: PharmacyCreation, filter?: PharmacyFilter) => dispatch(savePharmacy(pharmacy, filter)),
  getProgramNames: () => dispatch(getProgramNames()),
  getRegions: () => dispatch(getRegions()),
 // getBrands: (pageRequest: PageRequest) => dispatch(getBrands(pageRequest)),
});

const mapStateToProps = (state: State) => ({
 // brands: state.brands,
 programNames: state.programNames,
 regions: state.regions
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PharmacyCreationPageComponent);
