export const availableGroups = ['Administrator', 'Fingerbox', 'Zuellig']

export const nets = ['WATSONS', 'MANNINGS', 'OTHER']

export const types = [
  { label: 'Buy X Get Y', transfer: 'BUY_X_GET_Y' },
  { label: 'Instant Discount', transfer: 'INSTANT_DISCOUNT' },
  { label: 'HA SFI', transfer: 'HA_SFI' },
]

export const transferType = (label: string): string => {
  const TRANSFER_TYPE_MAPPING: Record<string, string> = {
    'Buy X Get Y': 'BUY_X_GET_Y',
    'Instant Discount': 'INSTANT_DISCOUNT',
    'HA SFI': 'HA_SFI',
  }
  return TRANSFER_TYPE_MAPPING[label.trim()] ?? ''
}


