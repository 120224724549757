import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { Button, IconButton, Switch, TableCell, TableRow, Tooltip } from '@mui/material'
import type { FC } from 'react'
import type { Patient, PatientFilter, PatientUpdate } from '../core/api/types'
import {
  ActivatePatientAction,
  DeactivatePatientAction,
  UpdatePatientDataAction,
  VerifyPatientAction,
} from '../core/actions/patientActionTypes'

type Props = {
  verifyPatient: (id: string) => VerifyPatientAction
  activatePatient: (id: string) => ActivatePatientAction
  deactivatePatient: (id: string) => DeactivatePatientAction
  updatePatientData: (patientUpdate: PatientUpdate, filter?: PatientFilter) => UpdatePatientDataAction
  patient: Patient
}

type UserStatus = 'VERIFIED' | 'DEACTIVATED'

export const RegisteredUsersTableRowComponent: FC<Props> = ({
  verifyPatient,
  activatePatient,
  deactivatePatient,
  updatePatientData,
  patient,
}) => {
  const [checked, setChecked] = useState<boolean>(patient.active)

  const onDeactivateStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.checked === true ? activatePatient(patient.id) : deactivatePatient(patient.id)
    setChecked(event.target.checked)
  }

  useEffect(() => {
    setChecked(patient.active)
  }, [patient.active])

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} hover key={patient.id}>
        <TableCell style={{ textAlign: 'center' }}>{patient.chineseName}</TableCell>
        <TableCell style={{ textAlign: 'center' }}>{patient.englishName}</TableCell>
        <TableCell style={{ textAlign: 'center' }}>{patient.identity.username.slice(3)}</TableCell>
        <TableCell style={{ textAlign: 'center' }}>{patient.hongKongId}</TableCell>
        <TableCell style={{ textAlign: 'center' }}>{patient.id}</TableCell>
        <TableCell style={{ textAlign: 'center' }}>{patient.address}</TableCell>
        <TableCell style={{ textAlign: 'center' }}>{patient.email}</TableCell>
        <TableCell style={{ textAlign: 'center', padding: 0 }}>{calculatePatientStatus(patient)}</TableCell>
        <TableCell style={{ textAlign: 'center' }}>
          <Button
            onClick={() => updatePatientData({ ...patient, blocked: !patient.blocked })}
            color={patient.blocked ? 'success' : 'primary'}
          >
            {patient.blocked ? 'Enable' : 'Disable'}
          </Button>
        </TableCell>
        <TableCell style={{ textAlign: 'center' }}>
          <Switch
            defaultChecked
            color='secondary'
            checked={checked}
            onChange={onDeactivateStatusChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </TableCell>
      </TableRow>
    </>
  )

    function calculatePatientStatus(patient: Patient): JSX.Element | UserStatus | string | undefined {
        if (patient.active) {
            return patient.verified ? 'VERIFIED' : <Tooltip title='Verify User'><IconButton aria-label='expand row' size='small' color='success' sx={{ fontSize: '15px', fontWeight: 'bold' }} onClick={() => {
                verifyPatient(patient.id);
            }}>
                Verify User
            </IconButton></Tooltip>
        } else {
            return `DELETED/${dayjs(patient.dateRegistered).tz('Asia/Hong_Kong').format('DD-MM-YYYY')}`
        }
    }
}
