import { Dispatch } from '@reduxjs/toolkit'
import { Action } from '../core/actions'
import { State } from '../core/reducers'
import { connect } from 'react-redux'
import { getProgramData, updateProgram } from '../core/actions/creators/programActionCreators'
import ProgramDataPageComponent from './ProgramDataPageComponent'
import { Program, ProgramFilter } from '../core/api/types'

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getProgramData: (id: string) => dispatch(getProgramData(id)),
  updateProgram: (program: Program, filter?: ProgramFilter) => dispatch(updateProgram(program, filter)),
})

const mapStateToProps = (state: State) => ({
  program: state.programData,
})

export default connect(mapStateToProps, mapDispatchToProps)(ProgramDataPageComponent)
