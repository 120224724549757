import type { FC } from "react"
import  { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Box, Button, Modal, Typography } from "@mui/material"
import { setOpenModal } from "../core/actions/creators/modalActionCreators"
import type { State } from "../core/reducers"
import { resetRedemptionBalance } from "../core/actions/creators/enrollmentActionCreators";


export const ResetBalancesModalComponent: FC = () => {
    const dispatch = useDispatch()

    const modal = useSelector((state: State) => state.modal)

    const resetBalances = () => {
        dispatch(resetRedemptionBalance());
        dispatch(setOpenModal({ open: false, patient: undefined }));
    }

    const validationInput = "Buy X Get Y Drop to Zero";
    const [inputField, setInputField] = useState('');
    return (
        <Modal
            open={modal.open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '500px', bgcolor: 'background.paper', border: '2px solid', borderColor: 'primary.main', boxShadow: 24, p: 4, display: 'flex', flexDirection: 'column' }}>
                <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                    Reset all balances for "Buy X get Y" programs.
                </Typography>
                <Typography id="modal-modal-title" component="p" sx={{ textAlign: 'left', fontWeight: 'semibold', marginTop: '15px', marginBottom: '20px' }}>
                    This action is related to programs "Buy X get Y". It will reset all redemption balances to 0. This action should be performed on 1st January.
                </Typography>

                <Typography id="modal-modal-title" component="p" sx={{ textAlign: 'left', fontWeight: 'semibold', marginTop: '15px', marginBottom: '20px' }}>
                    To reset balances, type "{validationInput}" in field below.
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <input value={inputField} onChange={(e) => setInputField(e.target.value)} style={{ height: '50px', border: '1px solid', borderRadius: '5px', width: '80%' }}/>
                    <Button sx={{ height: '50px', border: '1px solid', borderColor: 'primary.main' }} onClick={resetBalances} disabled={inputField !== validationInput}>Reset Balances</Button>
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Button color='primary' variant='contained' sx={{ width: '120px', height: '50px', m: '15px', marginBottom: '5px' }} onClick={() => dispatch(setOpenModal({ open: false, patient: undefined }))}>Cancel</Button>
                </Box>
            </Box>
        </Modal>
    )
}