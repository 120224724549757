import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { AxiosError } from 'axios'
import { ActionTypes } from '../actions/types'
import { patientActionCreators, registeredUsersTriggersActionCreators } from '../actions/creators'
import type {
  ActivatePatientAction,
  DeactivatePatientAction,
  GetPatientsDataAction,
  VerifyPatientAction,
} from './../actions/patientActionTypes'
import * as API from '../api/patients'
import 'react-toastify/dist/ReactToastify.css'

function* onGetPatients(getBrandAction: GetPatientsDataAction) {
  yield put(patientActionCreators.getPatientsRequest())

  try {
    const { data } = yield call(API.getPatientsData, getBrandAction.payload, getBrandAction.filter)
    yield put(patientActionCreators.getPatientsSuccess(data))
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      yield put(patientActionCreators.getPatientsFailure(error.response.data.error))
    }
  }
}

function* onActivatePatient(event: ActivatePatientAction) {
  yield put(patientActionCreators.activatePatientRequest())

  try {
    yield call(API.activatePatient, event.payload)
    yield put(patientActionCreators.activatePatientSuccess())
    yield put(registeredUsersTriggersActionCreators.setActivatedUserTrigger())
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      yield put(patientActionCreators.activatePatientFailure((error.response?.data?.errors ?? [])[0]))
    }
  }
}

function* onDeactivatePatient(event: DeactivatePatientAction) {
  yield put(patientActionCreators.deactivatePatientRequest())

  try {
    yield call(API.deactivatePatient, event.payload)
    yield put(patientActionCreators.deactivatePatientSuccess())
    yield put(registeredUsersTriggersActionCreators.setActivatedUserTrigger())
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      yield put(patientActionCreators.deactivatePatientFailure((error.response?.data?.errors ?? [])[0]))
    }
  }
}

function* onVerifyPatient(event: VerifyPatientAction) {
  yield put(patientActionCreators.verifyPatientRequest())
  try {
    yield call(API.verifyPatient, event.payload)
    yield put(patientActionCreators.verifyPatientSuccess())
    yield put(registeredUsersTriggersActionCreators.setVefifiedUserTrigger())
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      yield put(patientActionCreators.verifyPatientFailure((error.response?.data?.errors ?? [])[0]))
    }
  }
}

function* watchEnrollmentActions() {
  yield takeEvery(ActionTypes.GET_PATIENT_DATA, onGetPatients)
  yield takeEvery(ActionTypes.VERIFY_PATIENT, onVerifyPatient)
  yield takeEvery(ActionTypes.ACTIVATE_PATIENT, onActivatePatient)
  yield takeEvery(ActionTypes.DEACTIVATE_PATIENT, onDeactivatePatient)
}

export default function* saga() {
  yield all([fork(watchEnrollmentActions)])
}
