import { Dispatch } from "@reduxjs/toolkit";
import { Action } from "../core/actions";
import { State } from "../core/reducers";
import { connect } from "react-redux";
import SubmissonTableRowComponent from './SubmissionTableRowComponent';
import {getStorageFiles, uploadImage} from "../core/actions/creators/storageActionCreators";
import {PageRequest, PatientFilter, Submission, UploadImageData} from "../core/api/types";
import { approveSubmission, declineSubmission } from "../core/actions/creators/submissionActionCreator";

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
    getStorageFiles: ({ page, size } : PageRequest) => dispatch(getStorageFiles({ page, size })),
    uploadImage: (uploadImageData : UploadImageData) => dispatch(uploadImage(uploadImageData)),
    approveSubmission: (submission: Submission, filter?: PatientFilter) => dispatch(approveSubmission(submission, filter)),
    declineSubmission: (submission: Submission, filter?: PatientFilter) => dispatch(declineSubmission(submission, filter)),
});

const mapStateToProps = (state: State) => ({
    storage: state.storage
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SubmissonTableRowComponent);
